/* eslint-disable react/display-name */
import "./Switch.scss";
import cx from "classnames";
import { forwardRef, useCallback } from "react";

const Switch = forwardRef(({ value, onChange, status, ...props }, ref) => {
  const handleSwitch = useCallback(() => {
    if (onChange) onChange(!value);
  }, [value]);
  return (
    <div
      {...props}
      ref={ref}
      className={cx("Switch", { isSelectedSlider: value }, status)}
      onClick={handleSwitch}
    >
      <div className="slider"></div>
    </div>
  );
});

export default Switch;
