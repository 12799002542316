import axios from "axios";
import { API_HOST } from '../configs/main.js';

export const client = axios.create();

export const API_URL = `${API_HOST}`;

client.defaults.baseURL = API_URL;

client.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("admin_token");
    if (token !== null) {
      const headers = { Authorization: `Bearer ${token}` };

      config.headers = {
        ...config.headers,
        ...headers,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    // eslint-disable-next-line no-unused-vars
    const { response, config } = error;

    // console.log(response, config, 'Error during request. TODO: handle later')

    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const requestData = Object.getOwnPropertyDescriptor(error, "request");

    if (!requestData) throw new Error(error.message);

    if (typeof requestData.value.responseText === "string")
      throw new Error(requestData.value.responseText);

    const errData = JSON.parse(requestData.value.responseText);
    const finalError = errData.detail ?? errData.message;

    throw new Error(finalError);
  }
);
