import { PR_ACTIONS } from "./actionTypes";

const initialState = {
  preferences: [],
  loading: true,
  error: "",
  id: ""
};

export const getPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PR_ACTIONS.GET_PREFERENCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PR_ACTIONS.GET_PREFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        preferences: action.payload,
        error: "",
      };
    case PR_ACTIONS.GET_PREFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        preferences: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
