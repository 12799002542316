/* eslint-disable no-useless-escape */
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { Dropdown } from "../../../modules/Dropdown";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import {
  createNewCompany,
  updateCampaign,
} from "../../../redux/createNewCompany/actions";
import { isWebUri } from "valid-url";
import { client } from "../../../utils/network";
import { setInitialValue } from "../../../utils/helpers";
import { LoadingButton } from "@mui/lab";
import "./FirstSettings.scss";
import useCampaigns from "../../../data/useCampaigns";
import { AlignVerticalTop } from "@mui/icons-material";

const TARGET_TYPES = [
  { id: 1, optionValue: "TRAFFIC", optionLabel: "Трафик (Только баннеры)" },
  { id: 2, optionValue: "REACH", optionLabel: "Охват (Баннеры, Видео)" },
  //{ id: 3, optionValue: "WATCH_VIDEOS", optionLabel: "Просмотр видео" },
];

const CONDUCTION_TYPES = [
  { id: 1, optionValue: "advertiser", optionLabel: "Рекламодатель" },
  { id: 2, optionValue: "agency", optionLabel: "Агентство" },
];

const useStyles = makeStyles(() => ({
  input1: {
    maxHeight: "23px",
  },
}));

const theme = (window.outerWidth > 1000) ? createTheme({}) : createTheme({
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          "& fieldset": { border: 'none' },
          // Some CSS
          border: "0px solid white",
          marginTop: "-10px",
          marginBottom: "-15px",
          width: (window.outerWidth >= 768) && "400px",
        },
      },
      defaultProps: {
        inputProps: {
          variant: 'standard',
          style: {
            fontSize: "12px",
            height: "0px",
            background: "#f0f1f3",
            border: "0px solid white",
            paddingTop: "15px",
          },
        },
        InputLabelProps : {
          style: { 
            fontSize: "12px",
            paddingTop: "10px",
          },
        },
      },
    },
    MuiFormControlLabel: {
      label: {
          fontSize: '12px',
      }
  }
  },
});

const INITIAL_VALUES = {
  name: "",
  site: "",
  target_type: "TRAFFIC",
  daily_budget: "",
  full_budget: "",
  distribution_budget: "FASTEST",
  price_per_action: 0,
  autobidding_mode: "FIXED",
  enable_utm: null,
  use_programmatic_pixel: true,
  ya_metrika_counter_id: null,
  conduction:'',
  contract:''
};

const FirstSettings = ({ data, isNew, handleChange, campaignID, disable }) => {
  const dispatch = useDispatch();
  const {campaigns} = useCampaigns();
  const counts = campaigns?.map(el => el.basic_config.ya_metrika_counter_id);
  const dis = disable
  // console.log(data)
  // dispatch(getCounters());
  const classes = useStyles();
  const [counters, setCounters] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  // const { countersList } = useSelector(countersSelector.all_object);
  useEffect(() => {
    async function getCounters() {
      try {
        const { data } = await client("/api/v1/ya-metrika/counters");
        if (!data || !data.length) {
          enqueueSnackbar("Для добавления кампании необходимо загрузить счетчик", { variant: "error" });
          window.setTimeout(function(){
            // Move to a new location or you can do something else
            window.location.href = '../counters';
        }, 2000);
          // return setCounters([
          //   {
          //     optionValue: "",
          //     optionLabel: "У Вас нет созданных счетчиков",
          //     id: 1234,
          //   },
          // ]);
        }
        setCounters(
          data.map((counter) => ({
            optionValue: counter.id,
            optionLabel: `${counter.name} - ${counter.ya_metrika_id}`,
            ...counter,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    }
    getCounters();
  }, []);

  const dataEqual = (objA, objB) => {
    if (!objA || !objB) return false;
    const properties = [
      'name',
      'site',
      'target_type',
      'daily_budget',
      'full_budget',
      'ya_metrika_counter_id',
      'conduction'
    ]
    for (let key of properties) {
      if (objA[key] !== objB[key]) return false
    }
    return true
  }
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: setInitialValue(data, INITIAL_VALUES),

    validate: (values) => {
      let errors = {};
      if (!values.name) {
        errors.name = "Обязательно";
      }
      if (!values.site) {
        errors.site = "Обязательно";
      } else if (!isWebUri(values.site)) {
        errors.site = "Неверный формат";
      }
      if ((values.full_budget < 300)) {
        errors.full_budget = "Значение не должно быть меньше 300";
      }
      if ((values.daily_budget < 300)) {
        errors.daily_budget = "Значение не должно быть меньше 300";
      } else if (!/^(0|[1-9]\d*)(\.[0-9]{1,2})?$/.test(values.daily_budget)) {
        errors.daily_budget = "Значение округляется до двух знаков после запятой";
      }
      if (values.daily_budget > values.full_budget) {
        errors.daily_budget = "Значение дневного бюджета не должно быть меньше итогового";
      }
      if ((data && values.target_type !== data.target_type)) {
        errors.target_type = "Нельзя редактировать значение этого поля";
      }
      if (!values.ya_metrika_counter_id) {
        errors.ya_metrika_counter_id = "Обязательно";
      }
      if (!values.conduction) {
        errors.conduction = "Обязательно";
      }
      if (values.conduction === 'agency' && !values.contract) {
        errors.contract = "Обязательно";
      }else if(!/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(values.contract)){
        errors.contract = "Только цифры, буквы, спец символы"
      }
      return errors;
    },

    onSubmit: async (values) => {
      if (values.target_type == null) {
        alert("Укажите тип кампании");
      } else {
        const ya_metrika_counter_id = values.ya_metrika_counter_id;

        const dtoValues = {
          ...values,
          ya_metrika_counter_id,
        };
        const handleChangeTab = handleChange("event", "2");

        if (isNew && !data) {
          await dispatch(createNewCompany(dtoValues, handleChangeTab));
        } else {
          if (!dataEqual(data, values)) {
            await dispatch(
              updateCampaign(
                dtoValues,
                campaignID,
                "basic_config",
                handleChangeTab
              )
            );
          } else handleChangeTab()
        }
        return;
      }
    },
  });

  return (
    <div style={(window.outerWidth > 1000) ? { marginLeft: "20px" } : 
    (window.outerWidth < 375) ? {width: "288px", marginLeft: "20px", marginTop: "-10px"} : (window.outerWidth < 768)
    ? {width: "343px", marginLeft: "20px", marginTop: "-10px"} : {}}>
      <div className="title-text" style={{ marginBottom: "50px" }}>
        <h2>Основные настройки</h2>
        <p>3000+ Сегментов - Показывайте рекламу Только Тем, Кто Вам Нужен</p>
      </div>
      <form onSubmit={formik.handleSubmit}>
      <ThemeProvider theme={theme}>
        <Grid
          className="grid"
          container
          spacing={{ xs: 3, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          rowSpacing={5}
          alignItems="center"
          justify="center"
          sx={(window.outerWidth < 1000) && window.outerWidth > 767 && {display: "flex", flexDirection: "column", alignItems: "start", width: "400px"}}
        >
          <Grid item xs={4}>
            <p
              style={(window.outerWidth > 1000) ? {
                marginBottom: 14,
                height: 24,
                fontWeight: "bold",
                fontSize: 18,
              } : {
                marginBottom: 14,
                fontWeight: "bold",
                fontSize: 14,}}
            >
              Общие
            </p>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                disabled={dis}
                error={formik.touched.name && formik.errors.name ? true : false}
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={(window.outerWidth > 1000) ? "Введите название вашей кампании" : ""}
                placeholder={(window.outerWidth > 1000) ? "" : "Введите название вашей кампании"}
                id="outlined-multiline-flexible"
                // multiline
                helperText={formik.touched.name && formik.errors.name ? formik.errors.name : <br />}
                value={formik.values.name}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <p style={(window.outerWidth > 1000) ? { marginBottom: 14, height: 24 } : {marginBottom: -14}}></p>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                disabled={dis}
                error={formik.touched.site && formik.errors.site ? true : false}
                name="site"
                value={formik.values.site}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="outlined-multiline-flexible"
                // label="Введите ссылку на сайт"
                label={(window.outerWidth > 1000) ? "Введите ссылку на сайт" : ""}
                placeholder={(window.outerWidth > 1000) ? "" : "Введите ссылку на сайт"}
                // multiline
                helperText={formik.touched.site && formik.errors.site ? formik.errors.site : "Ссылка на вашу страницу для трафика"}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <p style={(window.outerWidth > 1000) ? { marginBottom: 14, height: 24 } : {marginBottom: -14}}></p>
            <Dropdown
              disabled={data ? true : false}
              error={formik.touched.target_type && formik.errors.target_type ? true : false}
              label="Выберите тип кампании"
              // label={(window.outerWidth > 1000) && "Выберите тип кампании"}
              placeholder={(window.outerWidth > 1000) ? "" : "Выберите тип кампании"}
              name="target_type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.target_type}
              options={TARGET_TYPES}
              helperText={formik.touched.target_type && formik.errors.target_type ? formik.errors.target_type : <br />}
              style={(window.outerWidth > 1000) ? {} : 
              (window.outerWidth < 768) ? 
                {fontSize: "12px",
                  height: "32px",
                  background: "#f0f1f3",
                  border: "0px solid white",
                } : {fontSize: "12px",
                height: "32px",
                width: "400px",
                background: "#f0f1f3",
                border: "0px solid white",}}
            />
            <br />
          </Grid>
          <Grid item xs={4}>
            <p
              style={(window.outerWidth > 1000) ? {
                marginBottom: 14,
                height: 24,
                fontWeight: "bold",
                fontSize: 18,
              } : {
                marginBottom: 14,
                fontWeight: "bold",
                fontSize: 14,}}
            >
              Управление бюджетом
            </p>
            <FormControl sx={{ width: "100%" }}>
              <TextField   
                disabled={false}
                error={formik.touched.daily_budget && formik.errors.daily_budget ? true : false}
                name="daily_budget"
                value={formik.values.daily_budget}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // label="Дневной бюджет"
                label={(window.outerWidth > 1000) ? "Дневной бюджет" : ""}
                placeholder={(window.outerWidth > 1000) ? "" : "Дневной бюджет"}
                type="number"
                InputLabelProps={{
                  style: { marginTop: "1px" },
                }}
                InputProps={{ classes: { input: classes.input1 } }}
                helperText={formik.touched.daily_budget && formik.errors.daily_budget ? formik.errors.daily_budget : <br />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <p style={(window.outerWidth > 1000) ? { marginBottom: 14, height: 24 } : {marginBottom: -14}}></p>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                disabled={false}
                error={formik.touched.full_budget && formik.errors.full_budget ? true : false}
                name="full_budget"
                value={formik.values.full_budget}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // label="Итоговый бюджет"
                label={(window.outerWidth > 1000) ? "Итоговый бюджет" : ""}
                placeholder={(window.outerWidth > 1000) ? "" : "Итоговый бюджет"}
                type="number"
                InputLabelProps={{
                  style: { marginTop: "1px" },
                }}
                InputProps={{ classes: { input: classes.input1 } }}
                helperText={formik.touched.full_budget && formik.errors.full_budget ? formik.errors.full_budget : <br />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                style={(window.outerWidth > 1000) ? {
                  marginLeft: "20px",
                  marginTop: "-10px",
                  fontWeight: "bold",
                  fontSize: 18,
                } : {
                  fontWeight: "bold",
                  fontSize: 14,
                  color: "black",
                  opacity: 0.7
                }}
                component="legend"
              >
                Распределение Бюджета
              </FormLabel>
              <RadioGroup
                aria-label="distribution_budget"
                name="distribution_budget"
                value={formik.values.distribution_budget}
                onChange={formik.handleChange}
                sx={(window.outerWidth > 1000) ? {
                  marginTop: "10px",
                } : {fontSize: "12px"}}
              >
                <div
                  style={(window.outerWidth > 1000) ? {
                    display: "flex",
                    color: "black",
                    marginLeft: "20px",
                  } : {display: "flex", fontSize: "12px", color: "black",}}
                >
                  <FormControlLabel
                    disabled={dis}
                    value="FASTEST"
                    control={(window.outerWidth > 1000) ? <Radio /> : <Radio size="small"/>}
                    label="Эффективное"
                    sx={(window.outerWidth > 1000) ?  {} : { '& .MuiFormControlLabel-label': { fontSize: '12px', paddingTop: "3px" } }}
                  />
                  <FormControlLabel
                    disabled={dis}
                    value="RECOMMENDED"
                    control={(window.outerWidth > 1000) ? <Radio /> : <Radio size="small"/>}
                    label="Равномерное"
                    sx={(window.outerWidth > 1000) ?  {} : { '& .MuiFormControlLabel-label': { fontSize: '12px', paddingTop: "3px" } }}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <p
              style={(window.outerWidth > 1000) ? {
                marginBottom: 14,
                height: 24,
                fontWeight: "bold",
                fontSize: 18,
              } : {
                marginBottom: 14,
                fontWeight: "bold",
                fontSize: 14,}}
            >
              Установка счётчика метрики
            </p>
            <Dropdown
              disabled={dis}
              options={counters}
              name="ya_metrika_counter_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={"Счётчик"}
              placeholder={(window.outerWidth < 1000) && "Счётчик"}
              value={formik.values.ya_metrika_counter_id ?? ""}
              style={(window.outerWidth > 1000) ? {} : 
                (window.outerWidth < 768) ? 
                {fontSize: "12px",
                  height: "32px",
                  background: "#f0f1f3",
                  border: "0px solid white",
                } : 
                {fontSize: "12px",
                height: "32px",
                width: "400px",
                background: "#f0f1f3",
                border: "0px solid white",}}
            />
          </Grid>
          <Grid item xs={4}>
            <p
              style={(window.outerWidth > 1000) ? {
                marginBottom: 14,
                height: 24,
                fontWeight: "bold",
                fontSize: 18,
              } : {
                marginBottom: 14,
                fontWeight: "bold",
                fontSize: 14,}}
            >
              Ведение кампании осуществляется
            </p>
            <Dropdown
              disabled={dis}
              options={CONDUCTION_TYPES}
              name="conduction"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Рекламодатель/Агенство"
              placeholder={(window.outerWidth < 1000) && "Рекламодатель/Агенство"}
              value={formik.values.conduction ?? ""}
              error={formik.touched.conduction && formik.errors.conduction ? true : false}
              helperText={formik.touched.conduction && formik.errors.conduction ? formik.errors.conduction : <br />}
              style={(window.outerWidth > 1000) ? {} : 
              (window.outerWidth < 768) ? 
              {fontSize: "12px",
                height: "32px",
                background: "#f0f1f3",
              } : {fontSize: "12px",
              height: "32px",
              width: "400px",
              background: "#f0f1f3",
              border: "0px solid white",}}
            />
          </Grid>
          {formik.values.conduction === 'agency' && <Grid item xs={4}>
            <p
              style={(window.outerWidth > 1000) ? {
                marginBottom: 14,
                height: 24,
                fontWeight: "bold",
                fontSize: 18,
              } : {
                marginBottom: 14,
                fontWeight: "bold",
                fontSize: 14,}}
            >
              Изначальный договор
            </p>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                style={{position:'relative', top:11}}
                disabled={dis}
                error={formik.touched.contract && formik.errors.contract ? true : false}
                name="contract"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={(window.outerWidth > 1000) ? "Введите номер договора" : ""}
                placeholder={(window.outerWidth > 1000) ? "" : "Введите номер договора"}
                id="outlined-multiline-flexible"
                // multiline
                helperText={formik.touched.contract && formik.errors.contract ? formik.errors.contract : <br />}
                value={formik.values.contract}
              />
            </FormControl>
          </Grid>}
          <Grid item xs={3}></Grid>
          <Grid
            container
            sx={(window.outerWidth > 1000) ? {
              marginTop: "50px",
              marginLeft: "25px",
            } : {marginLeft: "25px",marginTop: "30px",}}
          >
            <Grid item xs={4}>
              <Link to="/">
                <Button variant="outlined">Отмена</Button>
              </Link>
            </Grid>

            <Grid
              container
              item
              xs={8}
              style={{
                backgroundColor: "transparent",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={formik.isSubmitting}
                loadingPosition="end"
                endIcon={<SendIcon />}
                variant="contained"
                sx={(window.outerWidth > 1000) ? {
                  marginLeft: "32px",
                }: {marginTop: "-37px",}}
                onClick={() => formik.handleSubmit()}
              >
                {formik.isSubmitting ? "Сохранение" : "Далее"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        </ThemeProvider>
      </form>
    </div>
  );
};

export default FirstSettings;
