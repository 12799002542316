/* eslint-disable no-unused-vars */
import { useMemo, useState } from "react";

import { Table } from "../";
import { countersSelector } from "../../redux/counters/selectors";
import { useDispatch, useSelector } from "react-redux";
import { deleteCounterFromDb } from "../../redux/counters/actions";
import { useSnackbar } from "notistack";
import { Tooltip } from "@mui/material";

const MAX_NAME_LENGTH = 20;
const MAX_ID_LENGTH = 15;


function CountersTable({ openDialogWithComponent }) {
  const { countersList } = useSelector(countersSelector.all_object);
  const dispatch = useDispatch();
  console.log(countersList)
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingCounterId, setDeletingCounterId] = useState(null);
  const deleteCounter = async (id) => {
    setIsDeleting(true);
    setDeletingCounterId(id);
    await dispatch(deleteCounterFromDb(id));
    setIsDeleting(false);
    setDeletingCounterId(null);
  };
  const copyData = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      enqueueSnackbar("Скопировано", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("При копирования произошла ошибка", { variant: "error" });
    }
  };
  // const data = [
  //   {
  //     Company: "Ttcom-group",
  //     LookAlike: "Добавить",
  //     Goals: "Добавить",
  //     Status: "Данные не поступают",
  //     Coverage: "Неактивный",
  //     Segments: "-",
  //     Owner: "Cторонний владелец",
  //   },
  //   {
  //     Company: "Tt.comgroup",
  //     LookAlike: "Добавить",
  //     Goals: "Добавить",
  //     Status: "Данные не поступают",
  //     Coverage: "Неактивный",
  //     Segments: "-",
  //     Owner: "Cторонний владелец",
  //   },
  //   {
  //     Company: "Ttcomgroup.com",
  //     LookAlike: "Добавить",
  //     Goals: "Добавить",
  //     Status: "Данные не поступают",
  //     Coverage: "Неактивный",
  //     Segments: "-",
  //     Owner: "Cторонний владелец",
  //   },
  // ];

  const columns = useMemo(
    () => [
      {
        Header: (window.outerWidth > 767) ? "Название счетчика" : "",
        accessor: "name",
        Cell: ({ cell }) => {
          const isNameOverflow =
            cell.row.original.name.length > MAX_NAME_LENGTH;
          return (
            <div
              id={cell.row.id}
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                cursor: "pointer",
                height: "100%",
              }}
            >
              <Tooltip title={isNameOverflow ? cell.row.original.name : ""}>
                <button
                  style={{
                    maxWidth: "60%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  // onClick={() =>
                  //   openDialogWithComponent(
                  //     <CreateCounterForm data={cell.row.original} />
                  //   )
                  // }
                >
                  {isNameOverflow
                    ? `${cell.row.original.name.slice(0, MAX_NAME_LENGTH)}...`
                    : cell.row.original.name}
                </button>
              </Tooltip>
              <button
                style={{
                  color: " rgba(66, 69, 76, 0.4)",
                  fontSize: 12,
                  lineHeight: "17px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => copyData(cell.row.original.ya_metrika_id)}
              >
                Id - {cell.row.original.ya_metrika_id.toString().slice(0, MAX_ID_LENGTH)}
              </button>
            </div>
          );
        },
        disableGroupBy: true,
        disableSortBy: true,
        headerStyle: {
          width: 210,
        },
        cellStyle: {
          width: 210,
        },
      },
      {
        Header: (window.outerWidth > 767) ? "Сайт" : "",
        accessor: "site",
        Cell: ({ cell }) => 
          (window.outerWidth > 767) ? 
            <button
              style={{ color: "#3F73F9"}}
            >
              {cell.row.original.site}
            </button> 
            : <div style={{display:"flex", flexDirection: "row", gap:"4px"}}>
              Сайт
                <button
                  style={{ color: "#3F73F9"}}
                >
                  {cell.row.original.site}
                </button>
              </div>,
        disableGroupBy: true,
        disableSortBy: true,
        headerStyle: {
          width: (window.outerWidth > 1000) ? 820 : (window.outerWidth < 375) ? 280 : (window.outerWidth > 767) ? 704 : 333,
        },
        cellStyle: {
          width: (window.outerWidth > 1000) ? 820: (window.outerWidth < 375) ? 280 : (window.outerWidth > 767) ? 704 : 333,
          borderRight: "none",
          borderTop: (window.outerWidth < 767) && "1px solid #42454c22",
        },
      },
    //   {
    //     Header: "Look-alike",
    //     Cell: ({ row: { id } }) => (
    //       <button id={id} style={{ color: "#3F73F9" }}>
    //         Добавить
    //       </button>
    //     ),
    //     disableGroupBy: true,
    //     disableSortBy: true,
    //     headerStyle: {
    //       width: 70,
    //     },
    //     cellStyle: {
    //       width: 70,
    //     },
    //   },
    //   {
    //     Header: "Цели",
    //     Cell: ({ row: { id, original } }) => (
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         <span style={{ fontSize: "20px" }}>
    //           {original?.goals ? original.goals.length : 0}
    //         </span>
    //         <button
    //           id={id}
    //           style={{ color: "#3F73F9", marginLeft: "10px" }}
    //           onClick={() =>
    //             openDialogWithComponent(
    //               <GoalsList counterId={original.id} />,
    //               "Добавление цели"
    //             )
    //           }
    //         >
    //           Добавить
    //         </button>
    //       </div>
    //     ),
    //     disableGroupBy: true,
    //     disableSortBy: true,
    //     headerStyle: {
    //       width: 70,
    //     },
    //     cellStyle: {
    //       width: 70,
    //     },
    //   },
    //   {
    //     Header: "Статус",
    //     accessor: "Status",
    //     disableGroupBy: true,
    //     disableSortBy: true,
    //     Cell: ({ cell }) => (
    //       <span id={cell.row.id}>
    //         {cell.row.original.status ?? "Данные не поступают"}
    //       </span>
    //     ),
    //     headerStyle: {
    //       width: 145,
    //     },
    //     cellStyle: {
    //       width: 145,
    //       color: "rgba(66, 69, 76, 0.6)",
    //     },
    //   },
    //   {
    //     Header: "Охват",
    //     accessor: "Coverage",
    //     disableGroupBy: true,
    //     disableSortBy: true,
    //     Cell: ({ row: { id, original } }) => (
    //       <span id={id}>{original.coverage ?? "Неактивный"}</span>
    //     ),
    //     headerStyle: {
    //       width: 80,
    //     },
    //     cellStyle: {
    //       width: 80,
    //       color: "rgba(66, 69, 76, 0.6)",
    //     },
    //   },
    //   {
    //     Header: "Сегменты",
    //     accessor: "Segments",
    //     disableGroupBy: true,
    //     disableSortBy: true,
    //     Cell: ({ row: { id, original } }) => (
    //       <span id={id}>{original.segments ?? "-"}</span>
    //     ),
    //     headerStyle: {
    //       width: 60,
    //     },
    //     cellStyle: {
    //       width: 60,
    //       color: "rgba(66, 69, 76, 0.6)",
    //     },
    //   },
    //   {
    //     Header: "Владелец",
    //     accessor: "Owner",
    //     Cell: ({ row: { id, original } }) => (
    //       <span id={id}>{original.own ?? "Свой"}</span>
    //     ),
    //     disableGroupBy: true,
    //     disableSortBy: true,
    //     headerStyle: {
    //       width: 165,
    //     },
    //     cellStyle: {
    //       width: 165,
    //       color: "rgba(66, 69, 76, 0.6)",
    //     },
    //   },
    // {
    //   Header: () => null,
    //   accessor: "Delete",
    //   disableGroupBy: true,
    //   disableSortBy: true,
    //   Cell: ({ cell }) => (
    //     <button
    //       id={cell.row.id}
    //       onClick={() => deleteCounter(cell.row.original.id)}
    //       disabled={isDeleting}
    //     >
    //       {isDeleting && deletingCounterId === cell.row.original.id ? (
    //         <CircularProgress size={20} />
    //       ) : (
    //         <DeleteIcon />
    //       )}
    //     </button>
    //   ),
    //   headerStyle: {
    //     maxWidth: 50,
    //   },
    //   cellStyle: {
    //     justifyContent: "center",
    //     borderRight: "none",
    //     maxWidth: 50,
    //   },
    // },
    ],
    [isDeleting]
  );
  return (
    <div className="CountersTable">
      <Table columns={columns} data={countersList} />
    </div>
  );
}

export default CountersTable;
