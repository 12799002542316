import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as z from "zod"
import { BannerComponent } from "./BannerComponent.js";
import { Button, Typography } from "../../../../../ui";
import { LoadingButton } from "@mui/lab";
import { SelectOptions } from "./../SelectOptions";
import { SELECT_OPTIONS } from "./../configs";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import * as Yup from "yup";
import "./../myBanners.scss"
import { API_HOST } from "../../../../../configs/main"
import { useSnackbar } from "notistack";

import {
  FormControl,
  FormHelperText,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { BuildTwoTone } from '@mui/icons-material';


export const StyledHelperText = styled(FormHelperText)(({}) => ({
  background: "orange",
  width: "fit-content",
  position: "absolute",
  right: 0,
  top: 0,
  margin: 0,
  padding: "2px 5px",
  borderTopRightRadius: "5px",
}));


const theme = (window.outerWidth > 1000) ? createTheme({}) : createTheme({
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          "& fieldset": { border: 'none' },
          // Some CSS
          // border: "0px solid white",
          marginTop: "-10px",
          marginBottom: "-15px",
        },
      },
      defaultProps: {
        inputProps: {
          // variant: 'standard',
          style: {
            fontSize: "12px",
            height: "0px",
            background: "#f0f1f3",
            border: "0px solid white",
            paddingTop: "15px",
          },
        },
        InputLabelProps : {
          style: { 
            fontSize: "12px",
            paddingTop: "10px",
          },
        },
      },
    },
    MuiFormControlLabel: {
      label: {
          fontSize: '12px',
      }
  }
  },
});

const InitialValues = {
  head: "",
  text: "",
  landing_page: "",
  call_to_action: "",
};

const validationSchema = Yup.object().shape({
  landing_page: Yup.string().required("Введите ссылку").url("Пример корректной ссылки: https://example.com"),
  head: Yup.string().required("Введите заголовок").max(56, "Заголовок не может быть больше 56 символов"),
  text: Yup.string().required("Введите описание").max(81, "Описание не может быть длиннее 81 символов"),
  text_utm: Yup.string(),
  call_to_action: Yup.string().required(),
});

export const Banners = ({ campaignId, currentAdId, changeTab, disable, upd, setUpd, setHasUnsavedChanges, saveFormRef }) => {
  const [isSubmitting , setIsSubmitting ] = useState(false);
  const [banners, setBanners] = useState([]);
  const [data, setData] = useState(InitialValues);
  const { enqueueSnackbar } = useSnackbar();
  

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      )
      const response = await fetch(`${API_HOST}/api/v1/advertisements/get?_id=${currentAdId}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (response.ok) {
        const result = await response.json();
        values.setValues(result);
        setData(result)
        setBanners(result.banners)
        
        console.log("banners", result)
      }
    }
    if (!currentAdId) return;
    fetchData()
  }, [currentAdId, upd])

  const deleteAd = async (adId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    )
    const response = await fetch(`${API_HOST}/api/v1/advertisements/delete?_id=${adId}`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: myHeaders,
    });
    if (response.ok) {
      const data = await response.json();
      return data
    }
  }
  
  const values = useFormik({
      initialValues: data,
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: async (values, { resetForm }) => {
        if (JSON.stringify(values) === JSON.stringify(data)) return;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        )
        try {
          const response = await fetch(`${API_HOST}/api/v1/advertisements/update?_id=${currentAdId}`, {
            method: "POST",
            body: JSON.stringify({
              head: values.head,
              text: values.text,
              call_to_action: Object.entries(SELECT_OPTIONS).find(([_, v]) => v === values.call_to_action)?.[0],
              landing_page: values.landing_page
            }),
            headers: myHeaders,
          });
          if (response.ok) {
            setUpd((value) => !value);
            enqueueSnackbar("Объявление успешно сохранено", { variant: "success" });

            resetForm({ values: values });
          } else {
            enqueueSnackbar("Ошибка при сохранении объявления", { variant: "error" });

            resetForm({ values: values });
          }
        } catch (error) {
          enqueueSnackbar("Ошибка сети при сохранении", { variant: "error" });
        }

      return;
      },
    });

  useEffect(() => {
    saveFormRef.current = (adId) => values.submitForm().then(() => adId);
  }, [values.submitForm]);

  useEffect(() => {
    const isChanged = JSON.stringify(values.values) !== JSON.stringify(data);
    setHasUnsavedChanges(isChanged);
  }, [values.values, data, setHasUnsavedChanges]);

  return (
    <div style={(window.outerWidth > 1000) ? { marginLeft: "20px" } : 
    (window.outerWidth < 375) ? {width: "288px", marginLeft: "20px", marginTop: "40px"}
    : (window.outerWidth < 768) ? {width: "343px", marginLeft: "20px", marginTop: "40px"} : {}}>
      {!currentAdId && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            backdropFilter: "blur(5px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
          }}
        >
          <div
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#333",
              textAlign: "center",
            }}
          >
            Выберите или создайте объявление в левом блоке
          </div>
        </div>
      )}
      <div className="title-text" style={{ marginBottom: "50px" }}>
        <h2>Объявления</h2>
        <p>3000+ Сегментов - Показывайте рекламу Только Тем, Кто Вам Нужен</p>
      </div> 

      <Stack marginBottom="36px">
        <div className="banner_load_wrap">
          <BannerComponent campaign_id={campaignId} upd={upd} setUpd={setUpd} currentAdId={currentAdId} banners={banners} setBanners={setBanners} disabled={disable || values.values.status === "ACTIVE"}  />
        </div>
      </Stack>
      
      <form onSubmit={values.handleSubmit}>
        <div style={{ display: "flex", gap: "30px", justifyContent: "space-between" }}>
          <div style={{ width: "100%" }}>
            <Stack style={{ width: "100%" }} marginTop={(window.outerWidth > 1000) ? "0px" : "-30px"}>
            <Typography variant="h1" className="mb_2">
              Текст объявления
            </Typography>
            </Stack>
            <Stack
              direction={(window.outerWidth > 767) ? "row" : "column"}
              justifyContent={(window.outerWidth > 767) ? "space-between" : "flex-start"}
              marginBottom={(window.outerWidth > 1000) ? "34px" : "0px"}
              gap={(window.outerWidth > 767) ? "40px" : "10px"}
            >
              <Stack style={{ width: "100%" }}> 
                <Stack spacing={(window.outerWidth > 767) ? "16px" : "2px"} className="mb_3">
                  <Stack spacing={(window.outerWidth > 1000) ? "16px" : "5px"} className="mb_3">
                  <ThemeProvider theme={theme}>
                  
                    <div style={{ position: "relative" }}>
                      {values.values.status === "ACTIVE" && (
                        <div
                          onClick={() => enqueueSnackbar("Можно изменять данные только у выключенного объявления", { variant: "info" })}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "transparent",
                            cursor: "not-allowed",
                            zIndex: 10,
                          }}
                        />
                      )}
                      <FormControl fullWidth>
                        <TextField
                          disabled={disable || values.values.status === "ACTIVE"}
                          label={window.outerWidth > 1000 ? "Заголовок" : ""}
                          placeholder={window.outerWidth > 1000 ? "" : "Заголовок"}
                          name="head"
                          onChange={values.handleChange}
                          value={values.values.head}
                          error={values.values.head.length > 56}
                          helperText={
                            values.values.head.length > 56
                              ? "Заголовок не может быть больше 56 символов"
                              : <br />
                          }
                        />
                        {Boolean(values.touched.head && values.errors.head) && (
                          <StyledHelperText>{values.errors.head}</StyledHelperText>
                        )}
                      </FormControl>
                    </div>
                  </ThemeProvider>
                  <div style={{ position: "relative" }}>
                    {values.values.status === "ACTIVE" && (
                      <div
                        onClick={() => enqueueSnackbar("Можно изменять данные только у выключенного объявления", { variant: "info" })}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          background: "transparent",
                          cursor: "not-allowed",
                          zIndex: 10,
                        }}
                      />
                    )}
                    <FormControl fullWidth>
                      <TextField
                        disabled={disable || values.values.status === "ACTIVE"}
                        label={(window.outerWidth > 1000) ? "Описание" : ""}
                        placeholder={(window.outerWidth > 1000) ? "" : "Описание"}
                        name="text"
                        value={values.values.text}
                        multiline={true}
                        rows={5}
                        inputProps={(window.outerWidth > 1000) ? {} : {style: {fontSize: "12px"}}} // font size of input text
                        InputLabelProps={(window.outerWidth > 1000) ? {} : {style: {fontSize: "12px"}}}
                        sx = {(window.outerWidth > 1000) ? {} : {background: "#f0f1f3", "& fieldset": { border: 'none' }, borderRadius: "5px"}}
                        onChange={values.handleChange}
                        error={(values.values.text.length > 81) ? true : false}
                        helperText={(values.values.text.length > 81) ? "Описание не может быть длиннее 81 символов" : <br />}
                      />
                      {Boolean(values.touched.text && values.errors.text) && (
                        <StyledHelperText>{values.errors.text}</StyledHelperText>
                      )}
                    </FormControl>
                  </div>

                  { values.values.call_to_action && (
                    <SelectOptions
                      disable={disable || values.values.status === "ACTIVE"}
                      name="call_to_action"
                      options={SELECT_OPTIONS}
                      value={SELECT_OPTIONS[values.values.call_to_action]}
                      title="Выберите текст кнопки:"
                      onChange={(e) => { console.log(values.values.call_to_action); values.setFieldValue("call_to_action", e); console.log(values.values.call_to_action) }}
                    />
                  )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </div>

          <Stack style={{ width: "100%" }} className="mb_3">
            <Typography variant="h1" className="mb_2">
              Посадочная страница / UTM метки
            </Typography>

            <Stack
              direction={"column"}
              justifyContent={(window.outerWidth > 767) ? "space-between" : "flex-start"}
              marginBottom={(window.outerWidth > 1000) ? "34px" : "0px"}
              gap={(window.outerWidth > 767) ? "40px" : "10px"}
            >
              <div style={{ position: "relative" }}>
                {values.values.status === "ACTIVE" && (
                  <div
                    onClick={() => enqueueSnackbar("Можно изменять данные только у выключенного объявления", { variant: "info" })}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "transparent",
                      cursor: "not-allowed",
                      zIndex: 10,
                    }}
                  />
                )}
                <FormControl fullWidth>
                  <TextField
                    disabled={disable || values.values.status === "ACTIVE"}
                    label="Посадочная страница"
                    placeholder="https://example.com"
                    name="landing_page"
                    onChange={values.handleChange}
                    value={values.values.landing_page}
                    onBlur={() => {
                      values.setFieldValue(
                        "landing_page",
                        values.values.landing_page.replace(/\?.*$/, "")
                      );
                    }}
                  />
                  {Boolean(values.touched.landing_page && values.errors.landing_page) && (
                    <StyledHelperText>{values.errors.landing_page}</StyledHelperText>
                  )}
                </FormControl>
              </div>
              <FormControl fullWidth>
                <TextField
                  disabled={true}
                  label={(window.outerWidth > 1000) ? "UTM метки" : ""}
                  placeholder={(window.outerWidth > 1000) ? "" : "UTM метки"}
                  value={ values.values.landing_page + `?utm_source=Programmatic&utm_medium=cpm&utm_campaign=PRGх&utm_content=${currentAdId}`}
                />
              </FormControl>
            </Stack>
          </Stack>
        </div>
        
        <Stack>
          <div className="button_controls">
            <LoadingButton
              variant="contained"
              sx={{
                marginLeft: "32px",
                background: "#b82a2a",
              }}
              onClick={async () => {
                const confirm = window.confirm("Вы действительно хотите удалить объявление?")
                if (!confirm) return
                await deleteAd(currentAdId)
                setUpd((value) => !value);
                enqueueSnackbar(`Объявление успешно удалено`, { variant: "success" });
              }}
            >
              {"Удалить"}
            </LoadingButton>
            <LoadingButton
              loading={isSubmitting}
              loadingPosition="end"
              variant="contained"
              onClick={() => values.handleSubmit()}
            >
              {isSubmitting ? "Сохранение" : "Сохранить"}
            </LoadingButton>
            <div>
              <LoadingButton
                loadingPosition="end"
                variant="contained"
                sx={{
                  marginLeft: "32px",
                }}
                onClick={() => {
                  values.handleSubmit();
                  if (values.isValid) {
                    changeTab("event", "4")
                  }
                }}
                endIcon={<SendIcon />}
              >
                Далее
              </LoadingButton>
            </div>
          </div>
        </Stack>
      </form>
    </div>
  );
}