import "./PasswordResetConfirm.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { client } from "../../utils/network";
import useUser from "../../data/useUser";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { StylesProvider } from "@material-ui/core/styles";
import { useState, useEffect } from "react";

import { Button, Typography } from "../../ui";

import { ReactComponent as LogoWhiteIcon } from "../../assets/images/logo-white.svg";
import * as Yup from "yup";
import FormWithText from "../../widgets/FormWithText/FormWithText";

function PasswordResetConfirm() {
  const navigate = useNavigate();
  const { mutate: userMutate } = useUser();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    client.post("/api/v1/auth/password/reset/token/mark", searchParams.get("token")).catch(error => 
      (error.message === '{"detail":"Invalid token"}') ?
        navigate("/password-recovery")
      : "" )
  }, [])

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleMouseDownRepeatPassword = (event) => {
    event.preventDefault();
  };

  const { values, handleChange, handleSubmit, errors, touched, setErrors, handleBlur } = useFormik({
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Это обязательное поле")
      // eslint-disable-next-line no-useless-escape
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=\[{\]};:<>|./?,-]).{8,}$/, 'Пароль должен содержать не менее 8 символов, латинские буквы верхнего и нижнего регистра, хотябы одну цифру и специальный символ'),
      password_again: Yup.string().required(
        "Это обязательное поле"
      ),
    }),
    initialValues: {
      password: "",
      password_again: "",
    },
    validate: (values) => {
      if (values.password !== values.password_again) {
        return { ...errors, password_again: "Пароли не совпадают" };
      }
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        await client.post(
          "/api/v1/auth/password/reset/confirm",
          {
            new_password: values.password,
            token: searchParams.get("token"),
          }
        );
        // localStorage.setItem("token", data.access_token);
        await userMutate();
        navigate("/");
      } catch (error) {
        if (error.message === '{"detail":"Пароль уже использовался ранее. Введите новый пароль"}') {
          setErrors({ ...errors, password: 'Пароль уже использовался ранее. Введите новый пароль' });
        }else{
          setErrors({ ...errors, password: error.message });
        }
      }
    },
  });
  const handleInput = (e) => {
    handleChange(e);
    setErrors({});
  };
  return (
    <div className="PasswordResetConfirm">
      <div className="images">
        <Link to="/">
          <LogoWhiteIcon className="logo" />
        </Link>

        <div className="greetings">
          <Typography className="greetings-title" variant="h1" color="white">
            Приветствуем Вас!
          </Typography>
          <Typography variant="h1" color="white">
            Крупнейшая programmatic-экосистема в Рунете
          </Typography>
        </div>
      </div>
      <form className="wrapp" onSubmit={handleSubmit}>
        <div className="form">
          <Typography className="form-title" variant="h1" color="dark-gray">
            Изменить пароль
          </Typography>
          <Typography className="sub-title" variant="h2" color="dark-gray">
            Ваш новый пароль должен отличаться от предыдущего
          </Typography>
          <FormWithText>
          <StylesProvider injectFirst>
            <div className="validate_input">
            <Input
                disableUnderline={true}
                id="password"
                name="password"
                className={errors.password && touched.password ? "email-field err" : "email-field"}
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                placeholder="Пароль"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleInput}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {touched.password && errors.password ? (
                  <div className="error_text">{errors.password}</div>
                ) : null}
              </div>
              <div className="validate_input">
              <Input
                disableUnderline={true}
                id="password_again"
                name="password_again"
                className={errors.password_again && touched.password_again ? "password-field err" : "password-field"}
                type={showRepeatPassword ? "text" : "password"}
                value={values.password_again}
                onChange={handleInput}
                onBlur={handleBlur}
                placeholder="Подтвердите пароль"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowRepeatPassword}
                      onMouseDown={handleMouseDownRepeatPassword}
                    >
                      {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {touched.password_again && errors.password_again ? (
                  <div className="error_text">{errors.password_again}</div>
                ) : null}
              </div>
            </StylesProvider>
          </FormWithText>

          <div className="btn-actions">
            <Button type="submit" variant="primary" className="primary">
              Изменить
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PasswordResetConfirm;
