import { useEffect, useState } from "react"
import { API_HOST } from "../../../../../configs/main"
import { useSnackbar } from "notistack";
import { Switch } from "@mui/material";

export const Ads = ({ campaignId, openAd, currentAdId, upd, setUpd }) => {
  const [ads, setAds] = useState([])
  const [isFirst, setIsFirst] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      )
      const response = await fetch(`${API_HOST}/api/v1/advertisements/fetch?campaign_id=${campaignId}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (response.ok) {
        const data = await response.json();

        const sortedAds = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
  
        setAds(sortedAds);
        
        if (sortedAds.length > 0) {
          if (isFirst || currentAdId) return;
          openAd(sortedAds[0].id);
          setIsFirst((value) => !value)
        }
      }
    }
    fetchData()
  }, [upd])

  const createNewAd = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    )
    const response = await fetch(`${API_HOST}/api/v1/advertisements/create?campaign_id=${campaignId}`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: myHeaders,
    });
    if (response.ok) {
      const data = await response.json();
      return data
    }
  }

  const updateStatus = async (id, status) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    )
    try {
      const response = await fetch(`${API_HOST}/api/v1/advertisements/update?_id=${id}`, {
        method: "POST",
        body: JSON.stringify({
          status: status ? 'ACTIVE' : 'STOPPED'
        }),
        headers: myHeaders,
      });
      if (response.ok) {
        setUpd((value) => !value);
        enqueueSnackbar(`Объявление успешно ${status ? 'включено' : 'выключено'}`, { variant: "success" });
      } else {
        enqueueSnackbar("Ошибка при измении статуса", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Ошибка сети при изменении статуса", { variant: "error" });
    }
  }


  return (
    <div style={{ position: "absolute", border: "2px solid #e5e5ea", borderRadius: 15, background: "white", height: 900, width: 300, left: -350, overflowY: "auto"  }}>

      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 10 }}>
        <button
          onClick={async () => {
            const confirm = window.confirm("Вы действительно хотите создать новое объявление?")
            if (!confirm) return
            const ad = await createNewAd()
            setUpd((value) => !value)
            openAd(ad.id)
          }}
          style={{ borderRadius: 5, height: "40px", gap: 5, marginTop: 10, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#4e68f9", color: "white", border: "none" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-plus"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
          Создать новое объявление
        </button>
        <div style={{ width: "100%", height: 2, background: "#e5e5ea80", margin: "15px 0px"}}></div>
        <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%", overflowY: "auto" }}>
          {[...ads]
            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            .map((ad) => (
              <div 
                key={ad.id}
                onClick={() => openAd(ad.id)}
                style={{ display: "flex", position: "relative", flexDirection: "column", gap: 5, padding: 10, borderRadius: 5, background: "#fafafa", marginBottom: 10, border: ad.status === "ACTIVE" && ad.moderate_status === "MODERATING" ? "2px solid orange" : ad.status === "ACTIVE" && ad.moderate_status === "ACCEPTED" ? "2px solid blue" : ad.status === "ACTIVE" && ad.moderate_status === "REJECTED" ? "2px solid red" : "2px solid #e5e5ea", cursor: "pointer" }}>
                <p style={{ width: "75%", fontWeight: "bold", fontSize: 16, wordBreak: "break-all" }}>{ad.head || "Новое объявление"}</p>
                <p style={{ width: "75%", fontSize: 14, wordBreak: "break-all"}}>{ad.text || ""}</p>
                <div style={{ position: "absolute", top: 5, right: 5 }} onClick={(e) => e.stopPropagation()}>
                  <Switch checked={ad.status === "ACTIVE"} onChange={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const confirm = window.confirm(`Вы действительно хотите ${e.target.checked ? 'включить' : 'выключить'} объявление?`);
                    if (!confirm) return;
                    await updateStatus(ad.id, e.target.checked);
                  }} />
                </div>
                {ad.banners.length > 0 && (
                  <div style={{ marginTop: 10, display: "flex", gap: 10, overflowX: "auto", overflowY: "hidden", width: "100%", height: 100 }}>
                    {[...ad.banners]
                    .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                    .map((el, index) =>
                      el.extension === "mp4" ? (
                        <div key={index} style={{ minWidth: 100, height: "100%", borderRadius: 5 }}>
                          <video style={{ width: "100px", height: "100%", objectFit: "cover" }} src={el.url} muted/>
                        </div>
                      ) : (
                        <div key={index} style={{ minWidth: 100, height: "100%", borderRadius: 5 }}>
                          <img style={{ width: "100px", height: "100%", objectFit: "cover" }} src={el.url} alt="banner" />
                        </div>
                      )
                    )}
                  </div>
                )}
                <div style={{ display: "flex", flexDirection: "column", gap: 5, marginTop: 5 }}>
                  <span style={{ fontSize: 12 }}>Статус: {ad.status === "ACTIVE" && ad.moderate_status === "MODERATING" ? "На модерации" : ad.status === "ACTIVE" && ad.moderate_status === "ACCEPTED" ? "Активно" : ad.status === "ACTIVE" && ad.moderate_status === "REJECTED" ? "Отклонено" : "Выключено"}</span>
                  {ad.moderate_status === "REJECTED" && (
                    <div style={{
                      fontSize: "12px",
                      marginBottom: "10px"
                    }}>
                      Детали: Модерация отклонена, по причине свяжитесь со службой поддержки
                    </div>
                  )}
                  <span style={{ fontSize: 12 }}>Создано: {new Date(ad.created_at).toLocaleString()}</span>
                </div>
              </div>
          ))}
        </div>
      </div>

    </div>
  )
}










export const AdsForManagers = ({ campaignId, isErid }) => {
  const [ads, setAds] = useState([])

  const { enqueueSnackbar } = useSnackbar();

  const [erid, setErid] = useState("");
  const [canApprove, setCanApprove] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      )
      const response = await fetch(`${API_HOST}/api/v1/advertisements/fetch?campaign_id=${campaignId}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (response.ok) {
        const data = await response.json();

        const sortedAds = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
  
        setAds(sortedAds);
      }
    }
    fetchData()
  }, [])

  const rejectCampaign = async (advert_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/advertisements/moderation/reject?advert_id=${advert_id}`, {
      method: "POST",
      headers: myHeaders,
    });
    if (res.ok) {
      enqueueSnackbar("Объявление отклонено", {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  }

  const aproveCampaign = async (advert_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/advertisements/moderation/approve?advert_id=${advert_id}`, {
      method: "POST",
      headers: myHeaders,
    });
    if (res.ok) {
      enqueueSnackbar("Объявление одобрено.", {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  }


  return (
    <div style={{ border: "2px solid #e5e5ea", borderRadius: 15, background: "white", width: 460, overflowY: "auto"  }}>

      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 10 }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%", overflowY: "auto" }}>
          {ads.length === 0 && <span style={{ fontSize: 14, margin: 10 }}>Нет созданных объявлений</span>}
          {[...ads]
            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            .map((ad) => (
              <div 
                key={ad.id}
                style={{ display: "flex", position: "relative", flexDirection: "column", gap: 5, padding: 10, borderRadius: 5, background: "#fafafa", marginBottom: 10, border: ad.status === "ACTIVE" && ad.moderate_status === "MODERATING" ? "2px solid orange" : ad.status === "ACTIVE" && ad.moderate_status === "ACCEPTED" ? "2px solid blue" : ad.status === "ACTIVE" && ad.moderate_status === "REJECTED" ? "2px solid red" : "2px solid #e5e5ea" }}>
                <p style={{ width: "75%", fontWeight: "bold", fontSize: 16, wordBreak: "break-all" }}>{ad.head || "Новое объявление"}</p>
                <p style={{ width: "75%", fontSize: 14, wordBreak: "break-all"}}>{ad.text || ""}</p>
                {ad.banners.length > 0 && (
                  <div style={{ marginTop: 10, display: "flex", gap: 10, overflowX: "auto", overflowY: "hidden", width: "100%", height: 200 }}>
                    {[...ad.banners]
                      .sort(
                        (a, b) =>
                          new Date(a.created_at).getTime() -
                          new Date(b.created_at).getTime()
                      )
                      .map((el, index) => (
                        <div
                          key={index}
                          style={{
                            minWidth: 200,
                            height: "100%",
                            borderRadius: 5,
                            background: "#f0f1f3",
                            position: "relative",
                          }}
                        >
                          {el.extension === "mp4" ? (
                            <video
                              style={{
                                width: "200px",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              src={el.url}
                              muted
                              controls
                            />
                          ) : (
                            <img
                              style={{
                                width: "200px",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              src={el.url}
                              alt="banner"
                            />
                          )}
                          <a
                            href={el.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              width: 20,
                              height: 20,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "white",
                              opacity: 0.7,
                              borderRadius: "50%",
                              textDecoration: "none",
                              fontSize: 14,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#111" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-arrow-out-up-right"><path d="M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6"/><path d="m21 3-9 9"/><path d="M15 3h6v6"/></svg>
                          </a>
                        </div>
                      ))}
                  </div>
                )}
                <div style={{ display: "flex", flexDirection: "column", gap: 5, marginTop: 5 }}>
                  <span style={{ fontSize: 12 }}>Статус: {ad.status === "ACTIVE" && ad.moderate_status === "MODERATING" ? "На модерации" : ad.status === "ACTIVE" && ad.moderate_status === "ACCEPTED" ? "Активно" : ad.status === "ACTIVE" && ad.moderate_status === "REJECTED" ? "Отклонено" : "Выключено"}</span>
                  <span style={{ fontSize: 12 }}>Создано: {new Date(ad.created_at).toLocaleString()}</span>
                </div>
                { isErid && (
                  <div>
                    <div style={{ display: "flex", gap: 10, marginTop: 18, alignItems: "flex-end", marginBottom: 20 }}>
                      <div style={{ width: "100%"}}>
                        <p style={{ fontSize: 12, fontWeight: 500, marginBottom: 5 }}>Укажите erid из ОРД</p>
                        <input placeholder="Идентификатор креатива" style={{ width: "100%", border: "1px solid #e4e4e7", borderRadius: 5 }} type="text" onChange={(e) => setErid(e.target.value)} defaultValue={ad.erid} />
                      </div>
                      <button
                        onMouseEnter={(e) => { e.target.style.backgroundColor = "#f3f3f4"; }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = "transparent"; }}
                        style={{ fontSize: 14, padding: "8px 16px", borderRadius: 5, border: "1px solid #e4e4e7", transition: "background-color 0.3s ease", height: 33}}
                        onClick={() => {
                          const fetchApi = async () => {
                            const myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");
                            myHeaders.append(
                              "Authorization",
                              "Bearer " + localStorage.getItem("admin_token")
                            );
                            const res = await fetch(`${API_HOST}/api/v1/advertisements/moderation/erid?advert_id=${ad.id}&erid=${erid}`, {
                              method: "POST",
                              headers: myHeaders
                            });
                            if (res.ok) {
                              setCanApprove(true)
                              enqueueSnackbar("Креатив с erid - " + erid + " успешно найден", {
                                variant: "success",
                                autoHideDuration: 3000,
                                anchorOrigin: { vertical: "top", horizontal: "right" },
                              });
                            } else {
                              setCanApprove(false)
                              enqueueSnackbar("Произошла проблема, проверьте корректность erid или инн клиента.", {
                                variant: "error",
                                autoHideDuration: 3000,
                                anchorOrigin: { vertical: "top", horizontal: "right" },
                              });
                            }
                          };
                          fetchApi();
                        }}>
                        Проверить
                      </button>
                    </div>
                    <div style={{ display: "flex", gap: 10, marginTop: 18, justifyContent: "flex-end" }}>
                      <button
                        onMouseEnter={(e) => { e.target.style.backgroundColor = "#f3f3f4"; }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = "transparent"; }}
                        style={{ fontSize: 14, padding: "8px 16px", borderRadius: 5, border: "1px solid #e4e4e7", transition: "background-color 0.3s ease"}}
                        onClick={() => {
                          rejectCampaign(ad.id)
                        }}>
                        Отклонить
                      </button>
                      <button
                        onMouseEnter={(e) => { e.target.style.backgroundColor = "#22cd8d"; }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = "#1dad77"; }}
                        style={{ color: "#fff", fontSize: 14, backgroundColor: "#1dad77", padding: "8px 20px", borderRadius: 5, transition: "background-color 0.3s ease"}}
                        onClick={() => {
                          if (!canApprove) {
                            enqueueSnackbar("Обязательно укажите erid и проверьте его", {
                              variant: "info",
                              autoHideDuration: 3000,
                              anchorOrigin: { vertical: "top", horizontal: "right" },
                            });
                            return;
                          }
                          aproveCampaign(ad.id)
                        }}>
                        Одобрить
                      </button>
                    </div>
                  </div>
                )}
              </div>
          ))}
        </div>
      </div>
    </div>
  )
}