import useSWR  from "swr"

export default function useUser() {
    const token = localStorage.getItem("token");
    const { data, error, mutate } = useSWR(token ? "api/v1/me" : null)
    const isLoading = !error && !data
    const loggedOut = Boolean(!isLoading && error)
    const role = data?.role
    
    return {
        token,
        user: data,
        isLoading,
        loggedOut,
        role,
        mutate
    }
}
