import { Modal, Typography, MenuItem } from "@material-ui/core";
import { Box, FormControl, InputLabel, Select, TextField } from "@mui/material";
// import * as Yup from "yup";
// import { useFormik } from "formik";
import { format as dateFormat } from "date-fns";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: 600,
  overflowY: "scroll",
};

// const FORMIK_INITIAL_VALUES = {
//   staff: "",
// };

// const CreateSegmentSchema = Yup.object().shape({
//   staff: Yup.string().required(),
// });

function EditModal({
  modal,
  handleCloseModal,
  confirm,
  setConfirm,
  campaing,
  handleEditManager,
}) {
  // const formik = useFormik({
  //   initialValues: FORMIK_INITIAL_VALUES,
  //   validationSchema: CreateSegmentSchema,
  //   onSubmit: (values) => {
  //     values;
  //     formik.setValues(FORMIK_INITIAL_VALUES);
  //   },
  // });
  const handleCreatedAtChange = (value) => {
    console.log(value)
  };
  return (
    <Modal
      open={modal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Изменить кампанию
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{
            marginBottom: 20,
            marginTop: 20,
            color: "#42454C",
            opacity: "70%",
          }}
        >
          Кампания: {campaing?.name}
        </Typography>
        <Box
          style={{ gap: 20, marginBottom: 30 }}
          sx={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <TextField
            id="outlined-basic"
            fullWidth
            label="ID"
            value={campaing?.id}
            variant="outlined"
            disabled
          />
          <TextField
            id="outlined-basic"
            fullWidth
            label="Название кампании"
            value={campaing?.name}
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            fullWidth
            label="Сайт"
            value={campaing?.site}
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            fullWidth
            label="Дата создания"
            value={
              campaing?.created_at
                ? dateFormat(Date.parse(campaing.created_at), "dd/MM/yyyy")
                : ""
            }
            variant="outlined"
            disabled
          />
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">Охват/Трафик</InputLabel>
            <Select
              style={{ height: 56 }}
              label="Охват/Трафик"
              value={campaing?.target_type}
              id="demo-select-small-label"
            >
              <MenuItem value={"REACH"}>Охват</MenuItem>
              <MenuItem value={"TRAFFIC"}>Трафик</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            fullWidth
            label="Дневной бюджет"
            value={campaing?.daily_budget}
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            fullWidth
            label="Общий бюджет"
            value={campaing?.full_budget}
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            fullWidth
            label="Ставка"
            value={campaing?.price_per_action}
            variant="outlined"
          />
          <div className="inputRange">
            <DateFromTo
              from={new Date(campaing?.validity_period.date_start)}
              to={new Date(campaing?.validity_period.date_end)}
              handleChange={handleCreatedAtChange}
            />
          </div>
          <TextField
            id="outlined-basic"
            fullWidth
            label="Сумма спис"
            value={campaing?.charges_sum}
            variant="outlined"
            disabled
          />
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">Статус</InputLabel>
            <Select
              value={campaing?.status}
              style={{ height: 56 }}
              id="demo-select-small-label"
              label="Статус"
            >
              <MenuItem value={"INACTIVE"}>Неактивна</MenuItem>
              <MenuItem value={"ACTIVE"}>Работает</MenuItem>
              <MenuItem value={"MODERATION"}>На модерации</MenuItem>
              <MenuItem value={"BLOCKED"}>Ошибка</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {!confirm && (
          <div onClick={() => setConfirm(true)} className="editBtn">
            <span>Изменить</span>
          </div>
        )}
        {confirm && (
          <div className="btnGroup">
            <div
              className="resetBtn"
              onClick={() => {
                handleCloseModal();
                // formik.setValues(FORMIK_INITIAL_VALUES);
              }}
            >
              <span>Отменить</span>
            </div>
            <div
              className="confirmBtn"
              onClick={() =>
                handleEditManager(campaing.id)
              }
            >
              <span>Подтвердить</span>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default EditModal;
