/* eslint-disable react/no-unknown-property */
import cx from "classnames";
import "./CompanyBubble.scss";

import setting from "../../assets/images/settingsWhite.svg";
import { Button, Line, Switch, Typography } from "../../ui";
import { BudgetManagement } from "../../widgets";
import { memo, useEffect, useState } from "react";
import { Catalogs } from "../Catalogs";
import clsx from "clsx";
import { useDispatch, useSelector  } from "react-redux";
import { setEditCampaign } from "../../redux/createNewCompany/actions";
import { useNavigate } from "react-router-dom";
import { getIsEmptyObject } from "./common/helperFunctions";
import { Tooltip } from "@mui/material";
import useAnalyzeIdSum from '../../data/useAnalyzeIdSum';
import useUser from "../../data/useUser";
import React from "react";
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';  
import { client } from "../../utils/network";
import { useSnackbar } from "notistack";

const CompanyBubble = (campaign) => {
  const {
    basic_config,
    id,
    status,
    media_plan,
    broadcast_status,
    optimization,
    new_segment_id,
    issues,
  } = campaign;

  const userData = useUser()

  const { enqueueSnackbar } = useSnackbar();
  const [new_status,setNewStatus] = useState(status);
  // const [visibleGraph, setVisibleGraph] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const toggleVisibleGraph = () => {
  //   setVisibleGraph(!visibleGraph);
  // };
  // console.log(id,issues)
  const [isCardShown, setIsShown] = useState(true);

  const handleMouseOver = () => {
    setIsShown(false);
  };

  const handleMouseOut = () => {
    setIsShown(true);
  };
  // const buttonVariant = useMemo(() => {
  //   switch (new_status) {
  //     case "BLOCKED": {
  //       return "error";
  //     }
  //     case "NOTCOMPLETE": {
  //       return "dark";
  //     }
  //     default:
  //       return "gradient";
  //   }
  // }, [new_status]);
  // console.log(broadcast_status, status)
  // eslint-disable-next-line no-unused-vars
  const [isBroadcast, setIsBroadcast] = useState(
    broadcast_status === "DELIVERING"
  );
  // const [isActive, setIsActive] = useState(status === "ACTIVE");
  const { name } = basic_config;
  const notComplete =
    getIsEmptyObject(basic_config) ||
    getIsEmptyObject(new_segment_id) ||
    getIsEmptyObject(media_plan) ||
    getIsEmptyObject(optimization);
  let statusCompany = !["a31f137c-94b7-4f2c-a686-83d3fa7b59e4", "797a4b73-5698-4aa4-bd85-d29956e1d157"].includes(userData.user.id) ? broadcast_status : "DELIVERING"
  if (status===new_status) {if (status==="BLOCKED" || status==="DELETED") statusCompany="NO_ACTIVE"}
  else if (new_status==="BLOCKED" || new_status==="DELETED") statusCompany="NO_ACTIVE"
    // isActive && isBroadcast && !notComplete
    //   ? "ACTIVE"
    //   : !isActive || notComplete
    //   ? "NOTCOMPLETE"
    //   : "MODERATING";
  
  //const Auditory = segmentNormalizer(auditory);
  const segments = useSelector((state) => state.segments.segments);
  function SegmentSearch(new_segment_id) {
    const selected = segments.filter((segment) => new_segment_id === segment.id);
    //console.log(new_segment_id)
    //console.log(selected[0])
    return (selected[0])
  } 
  // const {StatIdDay} = useAnalyzeIdDay(id);
  // const { avg, full_budget, daily_budget } = campaign_stat.budget;
  const {StatIdSum} = useAnalyzeIdSum(id);
  const [checked, setChecked] = useState((status==='ACTIVE')? true : false);
  const handleChangeStatus = () => {
    if (userData.user.user_balance.score <= 0) {
      enqueueSnackbar("Недостаточно средств для запуска кампании", { variant: "error" })
    }
    else if (new_status==='ACTIVE') {
      client.patch(`/api/v1/campaigns/${id}`,{
        "status": "BLOCKED"
      });
      setNewStatus("BLOCKED")
      setChecked(false)
    } else if (new_status==='BLOCKED') {
      client.patch(`/api/v1/campaigns/${id}`,{
        "status": "ACTIVE"
      });
      setNewStatus("ACTIVE")
      setChecked(true)
    }
  };
  function masissue(issues) {
    let Issues = []
    for (let i = 0; i < issues.length; i++){
      if(issues?.includes("Свяжитесь с поддержкой.")) return <p>Кампания проходит модерацию.</p>
      Issues.push(<p>{`${issues[i]}`}</p>)
    }
    return Issues
  }
  function masissue2(issues) {
    let Issues = []
    for (let i = 0; i < issues.length; i++){
      if(issues?.includes("Свяжитесь с поддержкой.")) return <p>Кампания проходит модерацию.</p>
      Issues.push(<p>{`${issues[i].substr(0,28) }`}...</p>)
      break;
    }
    return Issues
  }
  const handleEdit = () => {
    dispatch(setEditCampaign(id));
    navigate("/add-company");
  };
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleDelete = () => {
  //   client.delete(`/api/v1/campaigns/${id}`);
  //   handleClose();
  //   window.location.reload(5);
  // }

  const handleArchive = () => {
    client
    .patch(`/api/v1/campaigns/archive/${id}`)
    .then(() => {
      setNewStatus("BLOCKED");
      window.location.reload();
    })
    .catch((error) => {
      console.error("Ошибка при архивации:", error);
    });
    handleClose();
  }

  const handleUnArchive = () => {
    client
    .patch(`/api/v1/campaigns/unarchive/${id}`)
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      console.error("Ошибка при разархивации:", error);
    });
    handleClose();
  }

  const handleDuplicate = () => {
    client.post(`/api/v1/campaigns/duplicate/${id}`).then((res) => {
      if (res?.status === 200) {
        enqueueSnackbar("Кампания продублирована", { variant: "success" })
        window.location.reload(5);
      }
    });
  }

  const handleSelect = () => {
    navigate('/statistics', {
      state: {
        campaign_id: id,
        counter_id: basic_config?.ya_metrika_counter_id
      }
    });
  };

  function numberWithSpaces(number) {
    if(number < 10000) return number
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <div className="CompanyBubble">
      <div className={clsx("Card", issues?.includes("Свяжитесь с поддержкой.") && checked ? "PENDING" : statusCompany)} style={{ }}>
        <div className="content">
          <button
            type="button"
            className={cx("settingsButton", issues?.includes("Свяжитесь с поддержкой.") && checked ? "PENDING" : statusCompany)}
            onClick={()=>setAnchorEl(!anchorEl)}
          >
            <div className="settingsButton_icon">
              <img src={setting} alt="setting" />
            </div>
            {anchorEl ? 
                <Card sx={(window.outerWidth > 1000) ? { width: "140px", padding:"7px", marginLeft: "40px"}
                 : (window.outerWidth < 375) ? { position:"absolute", top: "0px", left: "0px", width: "140px", padding:"7px", marginTop: "30px", marginLeft: "137px"}
                : (window.outerWidth < 768) ? { position:"absolute", top: "0px", left: "0px", width: "140px", padding:"7px", marginTop: "30px", marginLeft: "177px"}
                : { position:"absolute", top: "0px", left: "0px", width: "140px", padding:"7px", marginTop: "30px", marginLeft: "537px"}}> 
                {/* , height: "90px" borderRadius: "15px" display: 'flex', flexDirection: 'column', 
                 style={{ marginTop: "10px"}}*/}
                  {(status !== "DELETED") ? <Button variant="text" style={{ marginTop: "5px" }} onClick={handleEdit} >
                    <Typography variant="h5" color="dark-gray">
                      Редактировать
                    </Typography>
                  </Button> : ""}
                  <Button variant="text" style={{ marginTop: "5px" }} onClick={handleDuplicate} >
                    <Typography variant="h5" color="dark-gray">
                      Дублировать
                    </Typography>
                  </Button>
                  {(status !== "DELETED") ? <Button variant="text" style={{ marginTop: "5px" }} onClick={handleClickOpen} >
                    <Typography variant="h5" color="dark-gray">
                      Архивировать
                    </Typography>
                  </Button> :
                  <Button variant="text" style={{ marginTop: "5px", marginLeft: "-10px" }} onClick={handleUnArchive} >
                    <Typography variant="h5" color="dark-gray">
                      Разархивировать
                    </Typography>
                  </Button>}
                  {/* <Link style={{ marginTop: "20px"}} to="/delete" href="#common-examples" 
                  //component="button" onClick={console.log("delete")}
                   // ...process something
                  //}}
                  > */}
                  {/* <div>
                  <Button variant="text" style={{ marginTop: "5px" }} onClick={handleClickOpen} >
                    <Typography variant="h5" color="dark-gray">
                      Удалить
                    </Typography>
                  </Button>
                  </div> */}
                </Card> : ""}
                {/* <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Удаление кампании"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Удалить кампанию?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Нет</Button>
                      <Button onClick={handleDelete} autoFocus>
                        Да
                      </Button>
                    </DialogActions>
                  </Dialog> */}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Архивация кампании"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Архивировать кампанию {name}?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{gap: 10}}>
                      <Button onClick={handleClose}>Нет</Button>
                      <Button onClick={handleArchive} autoFocus>
                        Да
                      </Button>
                    </DialogActions>
                  </Dialog>
          </button>
          {(window.outerWidth < 1000) && (new_status !== "BLOCKED") && <div className="padding"></div>}
          <div className="column">
            <div className="client">
              <Typography variant="h2" color="dark-gray">
                {(name.length>34) ? name.substr(0,33)+"..." : name}
              </Typography>
              <Typography variant="span" color="dark-gray">
                id {id.slice(0, 5)}
              </Typography>
              
              { !["a31f137c-94b7-4f2c-a686-83d3fa7b59e4", "797a4b73-5698-4aa4-bd85-d29956e1d157"].includes(userData.user.id) ? (
                (new_status !== "BLOCKED" && new_status !== "DELETED") ? <Tooltip
                  title={!isBroadcast ? 
                    (notComplete
                      ? issues 
                      : !isBroadcast
                      ? ((issues?.length > 1) ? masissue(issues) : issues?.includes("Свяжитесь с поддержкой.") ? ['Кампания проходит модерацию.'] : issues?.length ? issues : ['Кампания проходит модерацию.'])
                      : "") : ''
                  }
                  onClose={handleMouseOut}
                  onOpen={handleMouseOver}
                  open={!isCardShown}
                  //placement="right"
                >
                <div sx={{align:"center"}} style={{position: "relative"}}>
                
                  {!isBroadcast ? 
                  <Card sx={{height:"15px", position: "absolute", marginLeft: "-60px", marginBottom: "5px", marginTop: "-25px", backgroundColor:"#6d6d6d",
                    whiteSpace: "nowrap", fontSize:"10px",padding:"2px", color:"#ffffff"}} style={!isCardShown ? {opacity: "0"} : {}}>
                    { // className="bg-neutral-500 w-40 h-15"  , marginLeft: "-50%"
                      notComplete
                        ? (<div>{issues}</div>)
                        : !isBroadcast
                        ? ((issues?.length > 1) ? <div>{masissue2(issues)}</div> : 
                          <div>{String(issues?.includes("Свяжитесь с поддержкой.") ? ['Кампания проходит модерацию.'] : issues?.length ? issues : ['Кампания проходит модерацию.']).slice(0,28)}</div>)
                        : ""
                    }
                  </Card> : ""}
                  <Switch
                    checked={checked}
                    onChange={handleChangeStatus}
                    inputprops={{ 'aria-label': 'controlled' }}
                    value={new_status === 'ACTIVE'} // isActive && !notComplete && isBroadcast
                  />
                  </div>
                </Tooltip> :
                <Switch
                    checked={checked}
                    onChange={handleChangeStatus}
                    inputprops={{ 'aria-label': 'controlled' }}
                    value={new_status === 'ACTIVE'} // isActive && !notComplete && isBroadcast
                  />
              ) : (
                <Switch
                  checked={checked}
                  onChange={handleChangeStatus}
                  inputprops={{ 'aria-label': 'controlled' }}
                  value={new_status === 'ACTIVE'} // isActive && !notComplete && isBroadcast
                />
              )}
            </div>
            <div className="category">
              <Catalogs segment={SegmentSearch(new_segment_id)} />
            </div>
          </div>
          <Line />
          <div className="metrics">
          <div className="column">
            <Typography className="title" variant="h2" color="dark-gray">
              БЮДЖЕТ И УПРАВЛЕНИЕ
            </Typography>
            <div className="items">
              {/* <BudgetManagement
                management="Avg. CPC"
                budget={avg || 0 + " ₽"}
              /> */}
              <BudgetManagement
                management="Дневной Бюджет"
                budget={numberWithSpaces(basic_config.daily_budget) || 0 + " ₽"}
              />
              <BudgetManagement
                management="Общий Бюджет"
                budget={numberWithSpaces(basic_config.full_budget) || 0 + " ₽"}
              />
            </div>
          </div>
          <Line />
          
          { !["a31f137c-94b7-4f2c-a686-83d3fa7b59e4", "797a4b73-5698-4aa4-bd85-d29956e1d157"].includes(userData.user.id) ? (
          <div className="column">
            <Typography className="title" variant="h2" color="dark-gray">
              СТАТИСТИКА ЗА СЕГОДНЯ
            </Typography>
            <div className="items2">
              <BudgetManagement
                className="statistics"
                management="Показы"
                budget={StatIdSum?.campaigns_data[id] ? StatIdSum?.campaigns_data[id][0] : "0"}
              />
              <BudgetManagement
                className="statistics"
                management="Клики"
                budget={StatIdSum?.campaigns_data[id] ? StatIdSum?.campaigns_data[id][1] : "0"}
              />
              <BudgetManagement
                className="statistics"
                management="CTR , %"
                budget={StatIdSum?.campaigns_data[id] ? StatIdSum?.campaigns_data[id][2].toFixed(2) : "0.00"}
              />
              <BudgetManagement
                  className="statistics"
                  management="CPC , ₽"
                  budget={StatIdSum?.campaigns_data[id] ? StatIdSum?.campaigns_data[id][3].toFixed(2) : "0.00"}
              />
            </div>
            <div className="button">
              <Button variant="gradient" onClick={handleSelect}>Подробная статистика</Button>
            </div>
          </div>
          ) : (
            <div className="column" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
              <div className="button">
                <Button variant="gradient" onClick={handleSelect}>Подробная статистика</Button>
              </div>
            </div>
          )}
          </div>
        </div>

        {/* <Button
          variant={buttonVariant}
          className="toggle"
          onClick={toggleVisibleGraph}
        >
          <ArrowWhiteIcon className={visibleGraph ? "rotated" : ""} />
        </Button> */}

        {/* {visibleGraph && <CardDashedGraph chartData={StatIdDay} budget={basic_config.full_budget || 0 + " ₽"}/>} chartData=StatIdDay */}
      </div>
    </div>
  );
};

export default memo(CompanyBubble);
