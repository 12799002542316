import "./Balance.scss";
import * as React from "react";
import { Typography, Button } from "../../ui";
import { CardWhite } from "../../widgets";
import useUser from "../../data/useUser";
import { useSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import { API_HOST } from "../../configs/main"
import HelpCenterSharpIcon from '@mui/icons-material/HelpCenterSharp';
import CloseIcon from '@mui/icons-material/Close';

import { Dialog, Stack, IconButton } from "@mui/material";
// use in future?
// function getname(xhr) {
// 	var filename = "";
// 	var disposition = xhr.getResponseHeader('Content-Disposition');
// 	if (disposition && disposition.indexOf('attachment') !== -1) {
// 		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
// 		var matches = filenameRegex.exec(disposition);
// 		if (matches != null && matches[1]) {
// 			filename = matches[1].replace(/['"]/g, '');
// 		}
// 	}
// 	return filename
// }


const NotifyModal = ({ isOpen, onClose }) => {
    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth={true}>
        <Stack padding={(window.outerWidth > 1000) ? "40px" : "10px"} overflow="hidden">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="headText" variant="h3" color="dark-gray" style={{fontSize: "15px"}}>
                В данный момент функция пополнения баланса работает в ручном режиме. Пожалуйста, свяжитесь с менеджером по адресу {' '}
                <a style={{color: "#6451ff", fontSize: "16px", fontWeight: "700"}} href="mailto:info@programmatic.ru?subject=Пополнение баланса">info@programmatic.ru</a>. В теме письма укажите "Пополнение баланса".
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              style={{position: "absolute", top: "10px", right: "20px"}}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Dialog>
    );
  };



function Balance() {
    const { user } = useUser()
    const [money, setMoney] = React.useState("5000");
    const [opentext, setOpentext] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [isDialogActive, setIsDialogActive] = React.useState(false);
    //console.log(user)
    const handleUpdateMoney = (event) => {
        setMoney(event.target.value)
    }
    const openModal = () => {
        setIsDialogActive(true);
    };

    const closeModal = () => {
        setIsDialogActive(false);
    };
    const handleChange = async () => {
        if (money >= 5000) {
            const data = {
                "money": money,
            };
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem("token"));
            if (user.is_entity) {
                //const result = await client.post("api/v1/payment/replenish/entity", data); //past method
                //console.log(result)//attachment; filename="Check.xlsx");
                //console.log(getname(result))
                const link = document.createElement('a');
                let name = "Check.pdf";
                fetch(`${API_HOST}/api/v1/payment/replenish/entity`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                }).then(async response => {
                    //console.log(response);
                    if (response.ok) {
                        let filename = response.headers?.get("content-disposition").split('filename*=')[1];
                        let filename2 = decodeURIComponent(filename.replace("utf-8''", ''));
                        filename = filename2;
                        const blob = await response.blob();
                        // console.log(blob)
                        if (filename === null) filename = name;
                        link.download = filename;//'Check.bin';
                        link.href = window.URL.createObjectURL(blob);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    else {
                        window.location.href = '../profile/bank-audits';
                        // alert("Something wrong")
                    }
                })
            }
            else {
                fetch(`${API_HOST}/api/v1/payment/replenish/individual`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                }).then(async response => {
                    if (response.ok) {
                        response.json().then((data) => {
                            console.log(data);
                            window.location.replace(data['url'])
                        });
                    }
                })
            }
        } else { enqueueSnackbar("Минимальная сумма пополнения 5000 рублей", { variant: "error" }) }
    }

    return (
        <div className="Balance">
            <Typography className="title" variant="h1" color="dark-gray">
                Баланс
            </Typography>
            <CardWhite>
                {(user?.is_entity) ?
                    <div className="head">
                        <Typography className="headText" variant="h1" color="dark-gray">
                            Пополнение
                        </Typography>
                        <div className="ya_account">
                            <HelpCenterSharpIcon className="helpButton" onClick={() => setOpentext(!opentext)} style={{ opacity: '50%' }} />
                            {opentext && <div className="helpText">
                                <div className="title">
                                    <span>После нажатия на кнопку Пополнить будет сгенерирован PDF счет на оплату</span>
                                    <CloseIcon onClick={() => setOpentext(false)} style={{ position: 'relative', left: 15, bottom: 25 }} />
                                </div>
                            </div>}
                        </div>
                    </div> :
                    <div className="head">
                        <Typography className="headText" variant="h1" color="dark-gray">
                            Онлайн пополнение
                        </Typography>
                        <div className="ya_account">
                            <HelpCenterSharpIcon className="helpButton" onClick={() => setOpentext(!opentext)} style={{ opacity: '50%' }} />
                            {opentext && <div className="helpText">
                                <div className="title">
                                    <span>После нажатия на кнопку Пополнить вы перейдете на страницу онлайн оплаты платежного провайдера ЮKassa</span>
                                    <CloseIcon onClick={() => setOpentext(false)} style={{ position: 'relative', left: 5, bottom: 25 }} />
                                </div>
                            </div>}
                        </div>
                    </div>}
                <div className="cardBody">
                    <Typography className="headText" variant="h2" color="dark-gray">
                        Сумма
                    </Typography>
                    <TextField
                        error={(money < 5000) ? true : false}
                        name="money"
                        onChange={handleUpdateMoney}
                        //label="Сумма пополнения"
                        id="outlined-multiline-flexible"
                        // multiline
                        helperText={(money < 5000) ?
                            "Минимальная сумма пополнения 5000 рублей" : <br />}
                        value={money}
                        //InputLabelProps={{ shrink: true }}
                        size="small"
                        style={{
                            marginTop: "20px",
                            width: "415px",
                        }}
                    />
                    {money >= 5000 ? <Typography className="text"  variant="span2"> {`${Math.round((money * 1.2)).toLocaleString('ru-RU')} ₽ включая НДС`}</Typography> : ""}

                    <div className="actions">
                        <Button variant="primary" onClick={user.is_entity ? handleChange : openModal}>Пополнить</Button>
                    </div>
                    <NotifyModal isOpen={isDialogActive} onClose={closeModal} />
                </div>
            </CardWhite>
        </div>
    );
}

export default Balance;
