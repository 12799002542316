

export default function useLogout() {

    const logout = async () => {
        localStorage.removeItem("token")
        localStorage.removeItem("admin_token")
        window.location.reload(); //await userMutate(null)
    }

    return logout
}