/* eslint-disable no-restricted-globals */
import { useState, useRef } from "react";
import DataTable from "../Table/Datatable.js";
import { format as dateFormat, set } from "date-fns";
import { useEffect } from "react";
import { API_HOST } from "../../../configs/main.js";
import "./dsp.scss";
import { INIT_FILTER_VALUE, filterDate, filterEmploye, filterStatsDate, filterStatsPartner } from "./filters.js";
import { Modal, Box, Typography, MenuItem } from "@material-ui/core";
import { FormControl, InputLabel, Select, ListSubheader, InputAdornment, TextField, Checkbox, Button } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo.js";
import { Switch } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { regionsData } from "./geolist.js";
import Chip from "@mui/material/Chip";
import * as z from "zod";


const styles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const postviewStyles = {
  maxHeight: "800px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  maxWidth: "80%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const decisionStyles = {
  maxHeight: "550px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};


const defaultStyles = {
  maxHeight: "1000px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  padding: "32px",
};


const campaignStyles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};




const getMoscowMidnight = (date) => {
  const targetDate = new Date(date);
  
  const year = targetDate.getFullYear();
  const month = String(targetDate.getMonth() + 1).padStart(2, '0');
  const day = String(targetDate.getDate()).padStart(2, '0');

  const moscowMidnight = new Date(`${year}-${month}-${day}T00:00:00+03:00`);

  return Math.floor(moscowMidnight.getTime() / 1000);
};


const DateRangePicker = ({ defaultValue, onChange }) => {
  const [startDate, setStartDate] = useState(defaultValue?.start || "");
  const [endDate, setEndDate] = useState(defaultValue?.end || "");

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    if (onChange) onChange({ start: newStartDate, end: endDate });
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    if (onChange) onChange({ start: startDate, end: newEndDate });
  };

  return (
    <div style={stylesDatePicker.datepickerContainer}>
      <input
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
        style={stylesDatePicker.datepickerInput}
      />
      <span style={{ margin: "0 10px" }}>to</span>
      <input
        type="date"
        value={endDate}
        onChange={handleEndDateChange}
        style={stylesDatePicker.datepickerInput}
      />
    </div>
  );
};



const DatePickerTest = ({ defaultValue, value, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue || '');

  useEffect(() => {
    const isTimestamp = (input) => 
      typeof input === 'number' || /^\d{10,}$/.test(String(input));
  
    if (value !== undefined && value !== null) {
      setSelectedDate(isTimestamp(value) ? formatDatePicker(value) : value);
    } else if (defaultValue) {
      setSelectedDate(isTimestamp(defaultValue) ? formatDatePicker(defaultValue) : defaultValue);
    }
  }, [value, defaultValue]);
  
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    
    if (value === undefined || value === null) {
      setSelectedDate(newDate);
    }
    
    if (onChange) {
      onChange(newDate);
    }
  };

  return (
    <div style={stylesDatePicker.datepickerContainer}>
      <input 
        type="date" 
        value={selectedDate} 
        onChange={handleDateChange} 
        style={stylesDatePicker.datepickerInput}
      />
    </div>
  );
};



const formatDatePicker = (date, istext = false) => {
  if (date === 0 || !date) return;
  const d = new Date(date * 1000);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  if (istext) return `${day}.${month}.${year}`
  return `${year}-${month}-${day}`;
};

const stylesDatePicker = {
  datepickerContainer: {
    padding: "5px 0 0 0",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  datepickerInput: {
    padding: '8px',
    fontSize: '14px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    outline: 'none',
    transition: 'border-color 0.3s ease',
    ':hover': {
      borderColor: '#888',
    },
    ':focus': {
      borderColor: '#007bff',
    },
  },
};


const addOneDay = (date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  return newDate;
};


const BannersStatsData = ({ partnerData }) => {
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [filterArr, setFilter] = useState([]);
  const [upd, setUpd] = useState(false);
  const [tableType, setTableType] = useState("geo");

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );

      const _startDate = typeof filtersValue.date.date_start === "number" ? new Date(filtersValue.date.date_start) : filtersValue.date.date_start
      const _endDate = typeof filtersValue.date.date_end === "number" ? new Date(filtersValue.date.date_end) : filtersValue.date.date_end

      const date_start = new Date(`${_startDate.getFullYear()}.${_startDate.getMonth()+1}.${_startDate.getDate()} 00:00:00+03:00`).getTime() / 1000
      const date_end = addOneDay(new Date(`${_endDate.getFullYear()}.${_endDate.getMonth()+1}.${_endDate.getDate()} 00:00:00+03:00`)).getTime() / 1000

      switch (tableType) {
        case "details": {
          const detailResponse = await fetch(
            `${API_HOST}/api/v1/ssp-stats/details?ssppartner_id=${partnerData.id}&target_type=REACH&date_from=${date_start}&date_to=${date_end}`,
            {
              method: "GET",
              headers: myHeaders,
            }
          );
          if (detailResponse.ok) {
            const data = await detailResponse.json();
            setFilter(data);
          }
          break;
        }
        case "domain": {
          const domainResponse = await fetch(
            `${API_HOST}/api/v1/ssp-stats/details_by_domain?ssppartner_id=${partnerData.id}&date_from=${date_start}&date_to=${date_end}`,
            {
              method: "GET",
              headers: myHeaders,
            }
          );
          if (domainResponse.ok) {
            const data = await domainResponse.json();
            setFilter(data);
          }
          break;
        }
        case "finance": {
          const response = await fetch(
            `${API_HOST}/api/v1/ssp-stats/details_by_finance?ssppartner_id=${partnerData.id}&date_from=${date_start}&date_to=${date_end}`,
            {
              method: "GET",
              headers: myHeaders,
            }
          );
          if (response.ok) {
            const data = await response.json();
            setFilter(data);
          }
          break;
        }
        case "geo": {
          const geoResponse = await fetch(
            `${API_HOST}/api/v1/ssp-stats/details_by_geo?ssppartner_id=${partnerData.id}&date_from=${date_start}&date_to=${date_end}`,
            {
              method: "GET",
              headers: myHeaders,
            }
          );
          if (geoResponse.ok) {
            const data = await geoResponse.json();
            setFilter(data);
          }
          break;
        }
        default:
          break;
      }
    }

    if (filtersValue.date.date_start && filtersValue.date.date_end) {
      fetchData();
    }
    console.log(tableType)
  }, [filtersValue.date.date_start, filtersValue.date.date_end, upd, tableType]);

  const detailColumns = [
    {
      Header: "Дата и время",
      accessor: "created_at",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "IP",
      accessor: "remote_address",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Сайт",
      accessor: "domain",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Гео",
      accessor: "geo",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "CPM ставка",
      accessor: "cost",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  function detailGetTableData() {
    const sortedData = filterArr.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB - dateA;
    });
  
    return sortedData.map((el) => {
      return {
        created_at: <div className="td">{el.created_at ? dateFormat(new Date(el.created_at), "dd.MM.yyyy HH:mm:ss") : "-"}</div>,
        remote_address: <div className="td">{el.remote_address ? el.remote_address : "-"}</div>,
        domain: <div className="td">{el.domain ? el.domain : "Нет"}</div>,
        geo: <div className="td">{regionsData[el.geo] || el.geo || "Нет"}</div>,
        cost: <div className="td">{el.cost ? el.cost + " руб." : "-"}</div>,
      };
    });
  }

  const geoColumns = [
    {
      Header: "Гео",
      accessor: "geo",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Показы",
      accessor: "impressions",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Просмотры",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "CTR",
      accessor: "ctr",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  function geoGetTableData() {
    const sortedData = filterArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  
    return sortedData.map((el) => {
      return {
        geo: <div className="td">{regionsData[el.geo] || el.geo || "Нет"}</div>,
        impressions: <div className="td">{el.impressions ? el.impressions : "0"}</div>,
        clicks: <div className="td">{el.clicks ? el.clicks : "0"}</div>,
        ctr: <div className="td">{el.ctr ? Number(el.ctr).toFixed(2) + "%" : "0%"}</div>,
      };
    });
  }

  const domainColumns = [
    {
      Header: "Сайт",
      accessor: "domain",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "ID сайта",
      accessor: "site_id",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Показы",
      accessor: "impressions",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Просмотры",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "CTR",
      accessor: "ctr",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "eCPM",
      accessor: "ecpm",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Затраты",
      accessor: "total_cost",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  function domainGetTableData() {
    const sortedData = filterArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  
    return sortedData.map((el) => {
      return {
        domain: <div className="td">{el.domain ? el.domain : "Нет"}</div>,
        site_id: <div className="td">{el.site_id ? el.site_id : "Нет"}</div>,
        impressions: <div className="td">{el.impressions ? el.impressions : "0"}</div>,
        clicks: <div className="td">{el.clicks ? el.clicks : "0"}</div>,
        ctr: <div className="td">{el.ctr ? Number(el.ctr).toFixed(2) + "%" : "0%"}</div>,
        ecpm: <div className="td">{el.average_cpm ? el.average_cpm : "0"}</div>,
        total_cost: <div className="td">{el.total_cost ? el.total_cost : "0"}</div>,
      };
    });
  }


  const financeColumns = [
    {
      Header: "Дата",
      accessor: "date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Показы",
      accessor: "impressions",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Клики",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "CTR",
      accessor: "ctr",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "eCPM",
      accessor: "ecpm",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Затраты",
      accessor: "total_cost",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  function financeGetTableData() {
    const sortedData = filterArr.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  
    return sortedData.map((el) => {
      return {
        date: <div className="td">{el.date ? dateFormat(new Date(el.date), "dd.MM.yyyy") : "-"}</div>,
        impressions: <div className="td">{el.impressions ? el.impressions : "0"}</div>,
        clicks: <div className="td">{el.clicks ? el.clicks : "0"}</div>,
        ctr: <div className="td">{el.ctr ? Number(el.ctr).toFixed(2) + "%" : "0%"}</div>,
        ecpm: <div className="td">{el.average_cpm ? el.average_cpm : "0"}</div>,
        total_cost: <div className="td">{el.total_cost ? el.total_cost : "0"}</div>,
      };
    });
  }

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };



  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 20 }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "400px" }}>
          <DateFromTo
            from={new Date(new Date().setHours(0, 0, 0, 0) - 1 * 24 * 60 * 60 * 1000).getTime()}
            to={new Date(new Date().setHours(23, 59, 59, 999)).getTime()}
            handleChange={handleDateChange}
          />
        </div>
        <Box sx={{ width: 175 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Тип отчёта
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Партнёр"
              id="demo-select-small-label"
              defaultValue={"geo"}
              onChange={(e) => {
                console.log(e.target.value);
                setTableType(e.target.value);
                setUpd(!upd);
              }}
            >
              <MenuItem value={"details"}>Детальный</MenuItem>
              <MenuItem value={"geo"}>По гео</MenuItem>
              <MenuItem value={"domain"}>По доменам</MenuItem>
              <MenuItem value={"finance"}>По финансам</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <button
          style={{ marginLeft: 30, width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 20px", fontSize: 14, borderRadius: 5 }}
          onClick={async () => {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("admin_token")
            );
      
              const _startDate = typeof filtersValue.date.date_start === "number" ? new Date(filtersValue.date.date_start) : filtersValue.date.date_start
              const _endDate = typeof filtersValue.date.date_end === "number" ? new Date(filtersValue.date.date_end) : filtersValue.date.date_end
        
              const date_start = new Date(`${_startDate.getFullYear()}.${_startDate.getMonth()+1}.${_startDate.getDate()} 00:00:00+03:00`).getTime() / 1000
              const date_end = addOneDay(new Date(`${_endDate.getFullYear()}.${_endDate.getMonth()+1}.${_endDate.getDate()} 00:00:00+03:00`)).getTime() / 1000
      
              const response = await fetch(
                `${API_HOST}/api/v1/ssp-stats/export?ssppartner_id=${partnerData.id}&date_from=${date_start}&date_to=${date_end}&breakdown_type=${tableType}`,
                {
                  method: "GET",
                  headers: myHeaders,
                }
              );
          
              if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
          
                link.download = `${tableType}_statistics_${partnerData.name}.xlsx`;
                document.body.appendChild(link);
                link.click();
          
                link.remove();
                window.URL.revokeObjectURL(url);
              } else {
                console.error("Ошибка при скачивании файла:", response.statusText);
              }
            }}
          >
            Скачать
        </button>
      </div>

      {
        tableType === "details" && (
          detailGetTableData().length > 0 ? (
            <DataTable data={detailGetTableData()} columns={detailColumns} />
          ) : (
            <div>Загрузка данных..</div>
          )
        )
      }

      {
        tableType === "geo" && (
          geoGetTableData().length > 0 ? (
            <DataTable data={geoGetTableData()} columns={geoColumns} />
          ) : (
            <div>Загрузка данных..</div>
          )
        )
      }

      {
        tableType === "domain" && (
          domainGetTableData().length > 0 ? (
            <DataTable data={domainGetTableData()} columns={domainColumns} />
          ) : (
            <div>Загрузка данных..</div>
          )
        )
      }

      {
        tableType === "finance" && (
          financeGetTableData().length > 0 ? (
            <DataTable data={financeGetTableData()} columns={financeColumns} />
          ) : (
            <div>Загрузка данных..</div>
          )
        )
      }
    </div>
  );
};



const BlackList = ({ partnerData }) => {
  const [blacklist, setBlacklist] = useState([]);
  const [error, setError] = useState(null);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "Bearer " + localStorage.getItem("admin_token")
  );


  const listsSchema = z.array(
    z
      .string()
      .min(3, "Минимум 3 символа.")
      .max(255, "Максимальная длина домена 255 символов.")
      .regex(
        /^(https?:\/\/)?(?!.*\.\.)(?=[^\s]{4,253}$)(?=.{2,}\.[^.]{2,}$)([a-zA-Z0-9-]{1,63}\.?){2,3}[^.\s]{2,}$/,
        "Некорректная ссылка на сайт."
      )
      .refine((value) => !blacklist.includes(value), "Такой сайт уже добавлен.")
  );

  const addToBlacklist = async (input) => {
    const items = input
      .split(" ")
      .map((item) => item.trim())
      .filter(Boolean);
    try {
      listsSchema.parse(items);
      setBlacklist((prev) => [...new Set([...prev, ...items])]);
      setError(null);
      await fetch(`${API_HOST}/api/v1/admin/ssp-partner-net/update?_id=${partnerData.id}`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ blacklist_domains: [...new Set([...blacklist, ...items])] }),
      });
    } catch (error) {
      setError(error.errors[0].message);
    }
  };

  
  const removeFromBlacklist = async (item) => {
    setBlacklist((prev) => prev.filter((i) => i !== item));
    await fetch(`${API_HOST}/api/v1/admin/ssp-partner-net/update?_id=${partnerData.id}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ blacklist_domains: blacklist.filter((i) => i !== item) }),
    });
  };

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${API_HOST}/api/v1/admin/ssp-partner-net/fetch_blacklists?_type=domains&_id=${partnerData.id}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (res.ok) {
        const data = await res.json();
        setBlacklist(data);
      }
    }
    fetchData();
  }, []);

  return (
    <div style={{ border: "1px solid #e0e0e0", padding: "20px", borderRadius: "10px" }}>
      <h3>Полное совпадение</h3>
      <div>
        <input
          onInput={() => setError(null)}
          style={{ marginTop: "10px", width: "200px", border: "1px solid grey" }}
          placeholder="Ссылки через пробел"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addToBlacklist(e.target.value);
              e.target.value = "";
            }
          }}
          type="text"
        />
        {error && <p style={{ color: "red", marginTop: "5px" }}>{error}</p>}
      </div>

      <h3 style={{ marginTop: "20px" }}>Список сайтов:</h3>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "20px", width: "100%" }}>
        {blacklist.map((item, index) => (
          <div key={index} style={{ display: "flex", gap: "8px", alignItems: "center", justifyContent: "center", background: "#e0e0e063", width: "max-content", padding: "10px 20px", borderRadius: "20px" }}>
            {item}
            <svg
              onClick={() => removeFromBlacklist(item)}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16" height="16"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};



const PartialList = ({ partnerData }) => {
  const [blacklist, setBlacklist] = useState([]);
  const [error, setError] = useState(null);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "Bearer " + localStorage.getItem("admin_token")
  );

  const listsSchema = z.array(
    z
      .string()
      .min(3, "Минимум 3 символа.")
      .max(255, "Максимальная длина текста 255 символов.")
      .refine((value) => !blacklist.includes(value), "Такой текст уже добавлен.")
  );

  const addToBlacklist = async (input) => {
    const items = input
      .split(" ")
      .map((item) => item.trim())
      .filter(Boolean);
    try {
      listsSchema.parse(items);
      setBlacklist((prev) => [...new Set([...prev, ...items])]);
      setError(null);
      await fetch(`${API_HOST}/api/v1/admin/ssp-partner-net/update?_id=${partnerData.id}`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ blacklist_partial: [...new Set([...blacklist, ...items])] }),
      });
    } catch (error) {
      setError(error.errors[0].message);
    }
  };

  const removeFromBlacklist = async (item) => {
    setBlacklist((prev) => prev.filter((i) => i !== item));
    await fetch(`${API_HOST}/api/v1/admin/ssp-partner-net/update?_id=${partnerData.id}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ blacklist_partial: blacklist.filter((i) => i !== item) }),
    });
  };

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${API_HOST}/api/v1/admin/ssp-partner-net/fetch_blacklists?_type=partial&_id=${partnerData.id}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (res.ok) {
        const data = await res.json();
        setBlacklist(data);
      }
    }
    fetchData();
  }, []);

  return (
    <div style={{ border: "1px solid #e0e0e0", padding: "20px", borderRadius: "10px" }}>
      <h3>Частичное совпадение</h3>
      <div>
        <input
          onInput={() => setError(null)}
          style={{ marginTop: "10px", width: "200px", border: "1px solid grey" }}
          placeholder="Введите текст через пробел"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addToBlacklist(e.target.value);
              e.target.value = "";
            }
          }}
          type="text"
        />
        {error && <p style={{ color: "red", marginTop: "5px" }}>{error}</p>}
      </div>

      <h3 style={{ marginTop: "20px" }}>Список текстов:</h3>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "20px", width: "100%" }}>
        {blacklist.map((item, index) => (
          <div key={index} style={{ display: "flex", gap: "8px", alignItems: "center", justifyContent: "center", background: "#e0e0e063", width: "max-content", padding: "10px 20px", borderRadius: "20px" }}>
            {item}
            <svg
              onClick={() => removeFromBlacklist(item)}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16" height="16"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};





const OpenModal = ({ openModal, setOpenModal, type, partnerData }) => {
  let modalContent;

  const ModalStats = ({ openModal, setOpenModal, children }) => {
    const closeModal = () => setOpenModal(false);
  
    const modalStyles = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: openModal ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    };
  
    const modalContentStyles = {
      backgroundColor: '#fff',
      width: '80%',
      maxWidth: 400,
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };
  
    return (
      <div style={modalStyles} onClick={closeModal}>
        <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    );
  };

  switch (type) {
    case "banners_stats":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={defaultStyles}>
            <h3 style={{ fontSize: 18 }}>
              Статистика баннеров по партнёру - {partnerData.name}
            </h3>
            {Object.keys(partnerData).length !== 0 && <BannersStatsData partnerData={partnerData}/>}
          </Box>
          
        </ModalStats>
      );
      break;

    case "blacklist":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={defaultStyles}>
            <h3 style={{ fontSize: 18, marginBottom: 20 }}>
              Черный список по партнёру - {partnerData.name}
            </h3>
            {Object.keys(partnerData).length !== 0 && (
              <div style={{ display: "flex", flexDirection: "column", width: "900px", gap: 20 }}>
                <BlackList partnerData={partnerData}/>
                <PartialList partnerData={partnerData}/>
              </div>
            )}
          </Box>
          
        </ModalStats>
      );
      break;


    default:
      modalContent = null;
  }

  return modalContent;
};


function Dsp() {
  const [filterArr, setFilter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const debounceTimers = useRef({});
  const updateQueues = useRef({});
  const isUpdating = useRef({});
  const [partnerData, setPartnerData] = useState({});


  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/ssp-partner-net/fetch`, {
        method: "GET",
        headers: myHeaders,
      });
      if (res.ok) {
        const data = await res.json();
        setFilter(data);
      }
    }
    fetchData();
  }, []);
  
  const FormatNumberInput = ({ el, handleChange, fieldName }) => {
    const [formattedValue, setFormattedValue] = useState('');

    useEffect(() => {
      setFormattedValue(el[fieldName].toLocaleString('ru-RU'));
    }, [el, fieldName]);

    const handleInputChange = (e) => {
      const rawValue = e.target.value.replace(/\D/g, '');
      const numberValue = Number(rawValue);
      
      const formatted = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      setFormattedValue(formatted);
      handleChange(fieldName, numberValue, el.id);
    };

    return (
      <div className="td">
        <input
          onChange={handleInputChange}
          defaultValue={el[fieldName]}
          value={formattedValue}
          type="text"
          style={{
            height: 25,
            border: "1px solid #d5d5d5",
            width: "100%",
            padding: "10px"
          }}
        />
      </div>
    );
  };


  const debounceUpdate = (field, partner_id, callback, delay = 500) => {
    const uniqueKey = `${field}-${partner_id}`;
    clearTimeout(debounceTimers.current[uniqueKey]);

    debounceTimers.current[uniqueKey] = setTimeout(() => {
        if (!updateQueues.current[uniqueKey]) updateQueues.current[uniqueKey] = [];
        updateQueues.current[uniqueKey].push(callback);
        processUpdateQueue(uniqueKey);
    }, delay);
  };

  const processUpdateQueue = async (uniqueKey) => {
      if (isUpdating.current[uniqueKey] || !updateQueues.current[uniqueKey] || updateQueues.current[uniqueKey].length === 0) return;

      isUpdating.current[uniqueKey] = true;

      while (updateQueues.current[uniqueKey].length > 0) {
          const callback = updateQueues.current[uniqueKey].shift();
          await callback();
      }

      isUpdating.current[uniqueKey] = false;
  };

  const handleChange = (field, value, partner_id) => {
      debounceUpdate(field, partner_id, async () => {
          console.log(field, value, partner_id);
          await updateFields({ [field]: value }, partner_id);
      });
  };

  const updateFields = async (fields, partner_id) => {
      if (partner_id === null) return;
      console.debug(fields, partner_id);
      await fetch(`${API_HOST}/api/v1/admin/ssp-partner-net/update?_id=${partner_id}`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
          },
          body: JSON.stringify(fields),
      });
  };

  const columns = [
    {
      Header: "SSP",
      accessor: "name",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Наименование партнёрской сети",
      accessor: "display_name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
      isSticky: true,
    },
    {
      Header: "CPM",
      accessor: "cpm",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "Запущено РК",
      accessor: "campaigns_count",
      maxWidth: 400,
      minWidth: 80,
      width: 120,
    },
    {
      Header: "Расход за период",
      accessor: "spend",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Дата начала",
      accessor: "start_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата окончания",
      accessor: "end_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Статистика по проектам",
      accessor: "banners_stats",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Черный список сайтов",
      accessor: "blacklist",
      maxWidth: 400,
      minWidth: 80,
      width: 135,
    },
    {
      Header: "Статус активности",
      accessor: "active_status",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    }
  ];


  function getTableData() {
    return filterArr.map((el) => {
      return {
        display_name: <div className="td">{el.display_name}</div>,
        name: <div className="td">{el.name}</div>,
        cpm: (
          <FormatNumberInput
            el={el}
            fieldName="cpm"
            handleChange={handleChange}
          />
        ),
        campaigns_count: <div className="td">{el.enabled_campaigns}</div>,
        spend: (
          <div className="td" style={{ display: "flex", gap: 10, alignItems: "center" }}>
            {el.consumption.toLocaleString('ru-RU') + " ₽"}
          </div>
        ),
        start_cycle_date: (
          <div className="td" style={{ height: 40 }}>
            <DatePickerTest
              defaultValue={el.start_of_period}
              onChange={(date) => {
                const formattedDate = new Date(date).toISOString().split('T')[0];
                handleChange('start_of_period', formattedDate, el.id);
              }}
            />
          </div>
        ),
        end_cycle_date: (
          <div className="td" style={{ height: 40 }}>
            <DatePickerTest
              defaultValue={el.end_of_period}
              onChange={(date) => {
                const formattedDate = new Date(date).toISOString().split('T')[0];
                handleChange('end_of_period', formattedDate, el.id);
              }}
            />
          </div>
        ),
        banners_stats: (
          <button
            onClick={() => {
              setPartnerData(el)
              setOpenModal(true)
              setModalType("banners_stats")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        blacklist: (
          <button
            onClick={() => {
              setPartnerData(el)
              setOpenModal(true)
              setModalType("blacklist")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        active_status: (
          <div className="td">
            <Switch defaultChecked={el.enabled} onChange={(e) => handleChange('enabled', e.target.checked, el.id)} />
          </div>
        ),
      };
    });
  }
  return (
    <div className="logs" style={{ width: "100%", height: "97%" }}>
      <div className="filters">
        <div className="searchDate">
          <DateFromTo withoutStartDate={true} handleChange={() => {}} />
        </div>
      </div>
      <div style={{ overflowX: "auto", width: "100%", height: "94%", overflowY: "auto" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>
      <OpenModal openModal={openModal} setOpenModal={setOpenModal} type={modalType} partnerData={partnerData}/>
    </div>
  );
}

export default Dsp;
