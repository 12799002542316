import { useState, useEffect } from "react";
import { FormControl, Stack } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { Button } from "../../ui";
import { FormControlLabel } from '@mui/material';
import { getCounters } from "../../redux/counters/actions";
import { useDispatch } from "react-redux";
import { client } from "../../utils/network";
import useYaCount from "../../data/useYaCount";
import { API_HOST } from "../../configs/main"

const CreateCounterForm = ({ setOpen }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const {counters} = useYaCount();
  const options = counters
  const [selected, setSelected] = useState([]);

  const [countersExtList, setCountersExtList] = useState([]);
  const [dataExtLoaded, setDataExtLoaded] = useState(false);

  useEffect(() => {
    const fetchCounters = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem("token"));
        const response = await fetch(`${API_HOST}/api/v1/ya-metrika/external/counters`, {
          method: 'GET',
          headers: myHeaders
        });
        if (response.ok) {
          let data = await response.json();
          setCountersExtList(data);
        } else {
          console.error('Ошибка при получении счётчиков:', response.statusText);
        }
        setDataExtLoaded(true)
      } catch (error) {
        console.error('Произошла ошибка:', error);
      }
    };
    fetchCounters();
  }, []);
  console.log(dataExtLoaded ? {countersExtList, options} : "ждём")
  

  function getOnChange(checked, name, site) {
    let array = checked
      ? [...selected, {name:name, site:site}]
      : selected.filter((value) => (name !== value.name)||(site !== value.site));
    setSelected(array);
    console.log(array)
  }

  const handleSubmit = () => {
    let arr = []
    selected.forEach((el) => {
      countersExtList.map((item) => ((el.name === item.name) && (el.site === item.site) && (!item.imported)) ? 
      (arr.push({name:item.name, site:item.site}))
      : "")
    })
    if (arr.length > 0) {
      client.put("/api/v1/ya-metrika/external/counters",arr).then(
        () => dispatch(getCounters())
      )
    }
    setOpen(false)
  }

  const onChanges = (event) => {
    setValue(event.target.value);
  };
  return (
    <FormControl>
      <Stack marginBottom={(window.outerWidth > 1000) ? "40px" : "10px"} 
      spacing={(window.outerWidth > 1000) ? "10px" : "5px"}>
      <div>
        <div className="App">
        <div className="search-container">
            <div className="search-inner">
              <input placeholder="Поиск" type="text" value={value} onChange={onChanges}/>
            </div>
            <Stack style={(window.outerWidth > 1000) ? {maxHeight: 130, overflow: 'auto', marginTop:5}
            : {maxHeight: 130, maxWidth:"225", overflowY: 'auto', marginTop:5}} padding="5px">
            {countersExtList.length ? countersExtList
                .filter((item) => {
                    if (value === "") return (item)
                    const searchTerm = value.toLowerCase();
                    const fullName = item.name.toLowerCase();
                    const site = item.site.toLowerCase();
                    return searchTerm && (fullName.includes(searchTerm) || site.includes(searchTerm));
                    })
                .map((item) => (
                    <FormControlLabel
                      sx={(window.outerWidth > 1000) ?  {} : { '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
                      key={`${item.name} - ${item.site}`}
                      control={
                        <Checkbox
                          size={(window.outerWidth > 1000) ? "medium" : "small"} 
                          checked={item.imported || 
                            selected.some((items) => (items.name === item.name) && (items.site === item.site))}
                          onChange={(event) =>
                            getOnChange(event.currentTarget.checked, item.name, item.site)
                          }
                          name={item.name} />
                      }
                      label={`${item.name} - ${item.site}`} 
                    />
                ))
                : dataExtLoaded ? <span style={{opacity:'50%', textAlign:'center'}}>Нет доступных счетчиков</span> : <span style={{opacity:'50%', textAlign:'center'}}>Загружаем счётчики..</span>}
            </Stack>
        </div>
        </div>
    </div>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={handleSubmit} type="submit" className="dialog__btn-submit">
            Применить
          </Button>
      </Stack>
    </FormControl>
  );
}
export default CreateCounterForm;
