import * as yup from 'yup';

const phoneRegExpYup = /\d{11}$/g

export const stringRegExp = /[.<>*+?^${}()|[\]\\/)]/g
export const textHandlerChange = (formik, e) => {
  e.target.value = e.target.value.replace(stringRegExp, '')
  formik.handleChange(e)
}

export const phoneRegExp = /[^\d]/g
export const phoneHandlerChange = (formik, e) => {
  e.currentTarget.value = e.currentTarget.value.replace(phoneRegExp, '')
  formik.handleChange(e)
}

export const stringEmailRegExp = /[=,<>*+?^${}()|[\]\\/)]/g
export const emailHandlerChange = (formik, e) => {
  e.currentTarget.value = e.currentTarget.value.replace(stringEmailRegExp, '')
  formik.handleChange(e)
}

export const numberRegExp = /[\D]/g
export const numberHandlerChange = (formik, e) => {
  e.currentTarget.value = e.currentTarget.value.replace(numberRegExp, '')
  formik.handleChange(e)
}

const rules = {
  email: yup
    .string()
    .email('Введите корректный email'),
  phoneNumber: yup
    .string()
    .matches(phoneRegExpYup, 'Введите корректный номер'),
  number: yup
    .number('Введите число')
    .positive('Введите число без знаков')
    .integer('Введите целое число')
}

export const validators = {
  updateUser: yup.object().shape({
    email: rules.email,
    phoneNumber: rules.phoneNumber,
  }),
  updateUserRequisites: yup.object().shape({
    email: rules.email,
    phoneNumber: rules.phoneNumber,
  }),
}