class Helper {
  hoursUnion(value) {
    if (!value.length) return [];
    const hoursRange = value.reduce((acc, cur) => {
      let [first, second] = cur.split("-").map(Number);
      if (!first && !second) return acc;
      if (second == null) {
        acc.push(first);
        return acc;
      }
      if (first < second) {
        while (first <= second) {
          acc.push(first);
          first++;
        }
      } else {
        while (first <= 23) {
          acc.push(first);
          first++;
        }
        first = 0;
        while (first <= second) {
          acc.push(first);
          first++;
        }
      }
      return acc;
    }, []);
    return [...new Set(hoursRange)].sort((a, b) => a - b);
  }

  rangeUnion(value) {
    if (!value.length) return [];
    const daysRange = value.reduce((acc, cur) => {
      let [first, second] = cur.split("-").map(Number);
      if (second == null) {
        acc.push(first);
        return acc;
      }
      if (first < second) {
        while (first <= second) {
          acc.push(first);
          first++;
        }
      } else {
        while (first < 7) {
          acc.push(first);
          first++;
        }
        first = 0;
        while (first <= second) {
          acc.push(first);
          first++;
        }
      }
      return acc;
    }, []);
    return [...new Set(daysRange)].sort((a, b) => a - b);
  }

  transformRangeToString(range) {
    if (!range.length) return [];
    if (range.length === 1) return [`${range[0]}`];
    const result = [];
    let i = 0;
    let j = 0;
    while (i < range.length) {
      const current = range[i];
      const prev = range[j];
      if (current - prev === i - j) {
        i++;
        continue;
      }
      if (current - prev !== i - j) {
        if (i - j === 1) {
          result.push(`${prev}`);
          j = i;
          i++;
          continue;
        }
        result.push(`${prev}-${range[i - 1]}`);
        j = i;
        i++;
      }
    }
    if (i - j > 1) {
      result.push(`${range[j]}-${range[i - 1]}`);
    } else {
      result.push(`${range[j]}`);
    }
    return result;
  }

  plusHours(date, quantity) {
    return new Date(date.getTime() + 1000 * 60 * 60 * quantity);
  }

  minusHours(date, quantity) {
    return new Date(date.getTime() + 1000 * 60 * 60 * quantity);
  }

  resetTime(date, h = 0, m = 0, s = 0, ms = 0) {
    const temp = new Date(date).setHours(h, m, s, ms);
    return new Date(temp);
  }

  plusDays(date, quantity) {
    return new Date(date.getTime() + 1000 * 60 * 60 * 24 * quantity);
  }

  minusDays(date, quantity) {
    return new Date(date.getTime() - 1000 * 60 * 60 * 24 * quantity);
  }

  getToday() {
    return new Date();
  }

  getDayNextWeek(date = new Date()) {
    return this.plusDays(date, 7);
  }

  getMondayDate(data) {
    if (data instanceof Date) {
      const currentDay = data.getDay();
      return this.minusDays(data, currentDay - 1);
    }
    if (data instanceof Object) {
      const date = new Date(data.year, data.month, data.day);
      const currentDay = date.getDay();
      return this.minusDays(date, currentDay - 1);
    }
    const date = new Date(data);
    if (date) {
      const currentDay = date.getDay();
      return this.minusDays(date, currentDay - 1);
    }
    return null;
  }
}

const NAME_DAYS = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

export class DateModel {
  helper = new Helper();
  #fromTime = null;
  #toTime = null;
  #fromDateRange = null;
  #toDateRange = null;
  #fromDate = null;
  #toDate = null;
  #days = [];
  #hours = [];
  #selectedRange = [];
  #rangeDates = [];
  #unionDates = [];
  clickedDates = {};
  #listSelectedRanges = [];


  setInitData(values, start, end) {
    this.fromDate = (start) ? new Date(start) : this.helper.resetTime(
      this.helper.getToday()
    );
    this.toDate = (end) ? new Date(end) : this.helper.resetTime(
      this.helper.plusDays(this.fromDate, 6),
      23,
      59,
      59
    );
    this.fromDateRange = (start) ? new Date(start) : this.helper.resetTime(
      this.helper.getMondayDate(new Date())
    );
    this.toDateRange = (end) ? new Date(end) : this.helper.resetTime(
      this.helper.plusDays(this.fromDateRange, 6),
      23,
      59,
      59
    );
    this.rangeDates = this.createRangeDates();
    this.clickedDates = this.initClickedDates();
    this.initClickedDates(values);
    return this;
  }
  setInitFromto(start, end) {
    this.fromDate = (start) ? new Date(start) : this.helper.resetTime(
      this.helper.getToday()
    );
    this.toDate = (end) ? new Date(end) : this.helper.resetTime(
      this.helper.plusDays(this.fromDate, 6),
      23,
      59,
      59
    );
  }

  initClickedDates(values) {
    if (values) {
      return this.setInitClickedDatesWithData(values);
    }
    return {
      cells: {
        active: [],
        dates: [],
      },
      hours: {
        active: [],
        dates: [],
      },
      days: {
        active: [],
        dates: [],
      },
    };
  }

  transformToDTO() {
    const WEEK_DAY = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    return this.unionDates.reduce((acc, cur) => {
      const nameOfDay = WEEK_DAY[cur.getDay()];
      acc[nameOfDay] = acc[nameOfDay]
        ? [...new Set([...acc[nameOfDay], cur.getHours()])].sort(
            (a, b) => a - b
          )
        : [cur.getHours()];
      return acc;
    }, {});
  }

  setInitClickedDatesWithData(values) {
    return Object.entries(values).forEach(([nameDay, hours]) => {
      const numberDay = NAME_DAYS.indexOf(nameDay);
      if (numberDay === -1) return [];
      return hours.forEach((hour) => {
        const plusedDays = numberDay === 0 ? 6 : numberDay - 1;
        const dateDay = this.helper.plusDays(this.fromDateRange, plusedDays);
        const dateHour = new Date(dateDay.setHours(hour));
        this.changeStatusDay(dateDay, dateHour);
      });
    });
  }

  checkIsSameDate = (date1, date2) => {
    return date1.getTime() === date2.getTime();
  };

  changeAllStatus(status = false) {
    const result = [];
    for (const [key, value] of this.rangeDates) {
      const data = value.map((item) => ({ ...item, active: status }));
      result.push([key, data]);
    }
    return new Map(result);
  }

  updateRangeDates() {
    if (!this.unionDates.length) {
      return this.changeAllStatus();
    }
    this.rangeDates = this.changeAllStatus();
    for (const [day, hours] of this.rangeDates) {
      const sameDay = this.unionDates.filter((date) =>
        this.checkIsSameDate(
          this.helper.resetTime(date, 0),
          this.helper.resetTime(day, 0)
        )
      );
      if (!sameDay.length) continue;

      sameDay.forEach((item) => {
        const hour = item.getHours();
        hours[hour].active = true;
      });
    }
    return this.rangeDates;
  }



  createRangeDates(from = this.fromDateRange, to = this.toDateRange) {
    const result = new Map();
    from = this.helper.resetTime(
      this.helper.getMondayDate(new Date())
    )
    to = this.helper.resetTime(
      this.helper.plusDays(from, 6),
      23,
      59,
      59
    )
    let temp = this.helper.resetTime(from);
    let max = this.helper.resetTime(to, 23, 59, 59);
    while (temp <= max) {
      result.set(temp, this.createHoursRange(temp));
      temp = this.helper.plusDays(temp, 1);
      
      if (temp > this.helper.plusDays(from, 6)) {
        break;
      }
    }
    return result;
  }

  intersectionDaysRange(numArr, dateArr) {
    return dateArr.filter((item) => numArr.includes(item.getDay()));
  }

  doIntersectionCellAndDay(dateCell) {
    const clickedDays = this.clickedDates.days;
    const clickedCells = this.clickedDates.cells;
    const cellDay = dateCell.getDay();
    const cellHour = dateCell.getHours();

    const toReplaceItems = this.clickedDates.days.dates.filter(
      (item) => item.getDay() === cellDay && item.getHours() !== cellHour
    );
    this.clickedDates.cells = {
      active: [...clickedCells.active, ...toReplaceItems],
      dates: [...clickedCells.dates, ...toReplaceItems],
    };
    this.clickedDates.days = {
      active: clickedDays.active.filter((item) => item !== cellDay),
      dates: clickedDays.dates.filter((item) => item.getDay() !== cellDay),
    };
  }

  doIntersectionCellAndHour(dateCell) {
    const clickedCells = this.clickedDates.cells;
    const clickedHours = this.clickedDates.hours;
    const cellDay = dateCell.getDay();
    const cellHour = dateCell.getHours();
    const toReplaceItems = this.clickedDates.hours.dates.filter(
      (item) => item.getHours() === cellHour && item.getDay() !== cellDay
    );
    this.clickedDates.cells = {
      active: [...clickedCells.active, ...toReplaceItems],
      dates: [...clickedCells.dates, ...toReplaceItems],
    };

    this.clickedDates.hours = {
      active: clickedHours.active.filter((item) => item !== cellHour),
      dates: clickedHours.dates.filter((item) => item.getHours() !== cellHour),
    };
  }

  setIntersectionCells(dateCell) {
    const clickedDays = this.clickedDates.days;
    const clickedCells = this.clickedDates.cells;
    const clickedHours = this.clickedDates.hours;
    const cellDay = dateCell.getDay();
    const cellHour = dateCell.getHours();
    if (clickedDays.active.includes(cellDay)) {
      this.doIntersectionCellAndDay(dateCell);
    }
    if (clickedHours.active.includes(cellHour)) {
      this.doIntersectionCellAndHour(dateCell);
    }
    if (
      !clickedDays.active.includes(cellDay) &&
      !clickedHours.active.includes(cellHour)
    ) {
      this.clickedDates.cells = {
        active: [...clickedCells.active, dateCell],
        dates: [...clickedCells.dates, dateCell],
      };

      if (this.checkFullHour(dateCell)) {
        const hour = dateCell.getHours();
        const toAddItems = this.clickedDates.cells.active.filter(
          (item) => item.getHours() === hour
        );

        this.clickedDates.cells = {
          active: clickedCells.active.filter(
            (item) => item.getHours() !== hour
          ),
          dates: clickedCells.active.filter((item) => item.getHours() !== hour),
        };

        this.clickedDates.hours = {
          active: [...clickedHours.active, hour],
          dates: [...clickedHours.dates, ...toAddItems],
        };
      }
      if (this.checkFullDay(dateCell)) {
        const day = dateCell.getDay();
        const toAddItems = this.clickedDates.cells.active.filter(
          (item) => item.getDay() === day
        );

        this.clickedDates.cells = {
          active: clickedCells.active.filter((item) => item.getDay() !== day),
          dates: clickedCells.active.filter((item) => item.getDay() !== day),
        };

        this.clickedDates.days = {
          active: [...clickedDays.active, day],
          dates: [...clickedDays.dates, ...toAddItems],
        };
      }
    }
    return this.clickedDates;
  }

  checkFullDay(dayDate, hourDate) {
    const date = dayDate ?? hourDate;
    const clickedCells = this.clickedDates.cells.active;
    const day = date.getDay();

    const activeHoursWithCurrentDay = clickedCells
      .filter((item) => item.getDay() === day)
      .map((item) => item.getHours());
    if (!activeHoursWithCurrentDay.includes(dayDate.getHours()))
      activeHoursWithCurrentDay.push(dayDate.getHours());
    const hoursRange = !this.setUnionHours().length
      ? new Array(24).fill(null).map((_, index) => index)
      : this.setUnionHours();

    return this.checkEqualArray(activeHoursWithCurrentDay, hoursRange);
  }

  checkEqualArray(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    const temp = [...arr2];
    for (let i = 0; i < arr1.length; i++) {
      const indexOf = temp.indexOf(arr1[i]);
      if (indexOf < 0) return false;
      temp.splice(indexOf, 1);
    }
    return temp.length === 0;
  }

  checkFullHour(dayDate, hourDate) {
    const date = dayDate ?? hourDate;
    //const day = date.getDay();
    const hour = date.getHours();
    const allCells = this.clickedDates.cells.active;
    const choicedCertainDays = this.helper.rangeUnion(this.days);

    const activeDaysWithCurrentHour = allCells
      .filter((item) => item.getHours() === hour)
      .map((item) => item.getDay());
    if (!activeDaysWithCurrentHour.includes(dayDate.getDay()))
      activeDaysWithCurrentHour.push(dayDate.getDay());

    const daysRange = choicedCertainDays.length
      ? choicedCertainDays
      : new Array(7).fill(null).map((_, i) => i);

    return this.checkEqualArray(activeDaysWithCurrentHour, daysRange);
  }

  addSomeActiveHour(dayDate, hourDate) {
    if (dayDate && hourDate) {
      const value = this.helper.resetTime(dayDate, hourDate.getHours());
      const cells = { ...this.clickedDates.cells };
      const activeDays = cells.active ?? [];
      const activeDates = cells.dates ?? [];
      const isAlreadyExist = activeDays.find((item) =>
        this.checkIsSameDate(item, value)
      );

      if (isAlreadyExist) {
        cells.active = activeDays.filter(
          (item) => !this.checkIsSameDate(item, value)
        );
        cells.dates = activeDates.filter(
          (item) => !this.checkIsSameDate(item, value)
        );

        return { ...this.clickedDates, cells };
      }
      return this.setIntersectionCells(hourDate);
    }

    if (!dayDate) {
      const hours = { ...this.clickedDates.hours };
      const activeHours = hours.active;
      const activeDates = hours.dates;
      const clickedHour = hourDate.getHours();
      if (activeHours.includes(clickedHour)) {
        hours.active = activeHours.filter((item) => item !== clickedHour);
        hours.dates = activeDates.filter(
          (item) => item.getHours() !== clickedHour
        );
      } else {
        const choicedCertainDays = this.helper.rangeUnion(this.days);

        const unionRange = !choicedCertainDays.length
          ? [...this.rangeDates].map(([key]) => key)
          : this.intersectionDaysRange(
              choicedCertainDays,
              [...this.rangeDates].map(([key]) => key)
            );
        const allDaysWithOneHours = this.setHoursToDays(unionRange, [
          hourDate.getHours(),
        ]);
        hours.active = [...activeHours, clickedHour];
        hours.dates = [...activeDates, ...allDaysWithOneHours];
      }

      return { ...this.clickedDates, hours };
    }

    if (!hourDate) {
      const days = { ...this.clickedDates.days };
      const activeDays = days.active;
      const activeDates = days.dates;
      const clickedDay = dayDate.getDay();
      if (activeDays.includes(clickedDay)) {
        days.active = activeDays.filter((item) => item !== clickedDay);
        days.dates = activeDates.filter((item) => item.getDay() !== clickedDay);
      } else {
        const hours =
          !this.hours.length && !(this.fromTime && this.toTime)
            ? this.generateFullDayHours()
            : this.setUnionHours();
        const hourInAllDays = this.setHoursToDays(
          [this.helper.resetTime(dayDate)],
          hours
        );
        days.active = [...activeDays, clickedDay];
        days.dates = [...activeDates, ...hourInAllDays];
      }

      return { ...this.clickedDates, days };
    }
  }

  generateFullDayHours() {
    return Array(24)
      .fill(null)
      .map((_, index) => index);
  }

  updateUnionDates() {
    const filterRangeHours = this.setUnionHours();
    const filterRangeDays = this.setUnionDays();
    const filterActiveDates = this.setHoursToDays(
      filterRangeDays,
      filterRangeHours
    );
    const selectedRanges = this.listSelectedRanges
      .map(({ value }) => value)
      .flat(1);
    const clickedCells = this.clickedDates.cells?.dates ?? [];
    const clickedHours = this.clickedDates.hours?.dates ?? [];
    const clickedDays = this.clickedDates.days?.dates ?? [];
    return [
      ...new Set([
        ...filterActiveDates,
        ...clickedCells,
        ...clickedHours,
        ...clickedDays,
        ...selectedRanges,
        ...this.selectedRange,
      ]),
    ];
  }

  changeStatusDay(dayDate, hourDate) {
    this.clickedDates = this.addSomeActiveHour(dayDate, hourDate);
    this.unionDates = this.updateUnionDates();
    this.rangeDates = this.updateRangeDates();
    return this;
  }

  createHoursRange(date) {
    const result = [];
    let temp = date;

    while (
      this.checkIsSameDate(
        this.helper.resetTime(date),
        this.helper.resetTime(temp)
      )
    ) {
      const isActive = this.checkActive(temp);
      result.push({ date: temp, active: isActive });
      temp = this.helper.plusHours(temp, 1);
    }
    return result;
  }

  checkActive(date) {
    return !!this.unionDates.find((item) => this.checkIsSameDate(item, date));
  }

  findUniqueDay(range) {
    if (!range.length) return [];
    return range.reduce((acc, cur) => {
      const findValue = acc.find((item) =>
        this.checkIsSameDate(
          this.helper.resetTime(item),
          this.helper.resetTime(cur)
        )
      );
      if (!findValue) {
        acc.push(cur);
      }
      return acc;
    }, []);
  }

  setUnionDays() {
    if (!this.days.length) return [];

    const result = [];
    const rangeUnion = this.helper.rangeUnion(this.days);

    for (const [day] of this.rangeDates) {
      const numDay = day.getDay();
      if (rangeUnion.includes(numDay)) {
        result.push(day);
      }
    }

    return result;
  }

  setUnionHours() {
    let start = this.fromTime
      ? this.fromTime
      : this.helper.resetTime(new Date());
    let topPivot = this.toTime
      ? this.toTime
      : this.helper.resetTime(new Date());
    const value = [...this.hours, `${start.getHours()}-${topPivot.getHours()}`];
    return this.helper.hoursUnion(value);
  }

  getSelectedHours() {
    if (!this.selectedRange.length) return [];
    return this.selectedRange.reduce((acc, cur) => {
      let hour = new Date(cur).getHours();
      if (!acc.includes(hour)) {
        acc.push(hour);
      }
      return acc;
    }, []);
  }

  updateSelectedRange(arr) {
    this.selectedRange = arr.map((item) => new Date(item));
    this.unionDates = this.updateUnionDates();
    this.rangeDates = this.updateRangeDates();
    return this;
  }

  transformDate = (date) => {
    if (!date) return "";
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("ru", options);
  };

  transformDateToWeekDay = (date) => {
    if (!date) return "";
    const options = { weekday: 'short'};
    const weekday = new Intl.DateTimeFormat('ru-RU', options).format(date);
    return weekday.charAt(0).toUpperCase() + weekday.slice(1);
  };

  createTitleForSelectedRange() {
    const minDate = new Date(Math.min(...this.selectedRange));
    const maxDate = new Date(Math.max(...this.selectedRange));
    return `${this.transformDateToWeekDay(minDate)} - ${this.transformDateToWeekDay(
      maxDate
    )} с ${minDate.getHours()} до ${maxDate.getHours()}`;
  }

  updateListSelectedRanges() {
    if (!this.selectedRange.length) return this.listSelectedRanges;
    const selectedInfo = {
      title: this.createTitleForSelectedRange(),
      value: this.selectedRange,
    };
    return [...this.listSelectedRanges, selectedInfo];
  }

  deleteSelectedRange(index) {
    this.listSelectedRanges = this.listSelectedRanges.filter(
      (_, i) => i !== index
    );
    this.unionDates = this.updateUnionDates();
    this.rangeDates = this.updateRangeDates();
    return this;
  }

  saveSelectedZone() {
    this.listSelectedRanges = this.updateListSelectedRanges();
    this.selectedRange = [];
    return this;
  }

  get listSelectedRanges() {
    return this.#listSelectedRanges;
  }

  set listSelectedRanges(value) {
    this.#listSelectedRanges = value;
  }

  updateData(key, value) {
    try {
      this[key] = value;
      return this;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  get selectedRange() {
    return this.#selectedRange;
  }

  set selectedRange(value) {
    this.#selectedRange = value;
  }

  get rangeDates() {
    return this.#rangeDates;
  }

  set rangeDates(value) {
    this.#rangeDates = value;
  }

  set days(value) {
    this.#days = value;
    this.unionDates = this.updateUnionDates();
    this.rangeDates = this.updateRangeDates();
  }

  get days() {
    return this.#days;
  }

  set hours(value) {
    this.#hours = value;
    this.unionDates = this.updateUnionDates();
    this.rangeDates = this.updateRangeDates();
  }

  setHoursToDays(days, hours) {
    if (!days.length || !hours.length) return [];

    return days.reduce((acc, cur) => {
      const dates = hours.map((hour) => this.helper.resetTime(cur, hour));
      return [...acc, ...dates];
    }, []);
  }

  get unionDates() {
    return this.#unionDates;
  }

  set unionDates(value) {
    this.#unionDates = value;
  }

  get hours() {
    return this.#hours;
  }

  get fromTime() {
    return this.#fromTime;
  }

  set fromTime(value) {
    if (this.toTime) {
      if (value > this.toTime) {
        throw new Error('Значение не может быть больше "Время по" ');
      }
      //TODO
    }

    this.#fromTime = value;
    this.unionDates = this.updateUnionDates();
    this.rangeDates = this.updateRangeDates();
  }

  get toTime() {
    return this.#toTime;
  }

  set toTime(value) {
    if (this.fromTime) {
      if (value < this.fromTime) {
        throw new Error('Значение не может быть меньше "Время с" ');
      }
    }
    this.#toTime = value;
    this.unionDates = this.updateUnionDates();
    this.rangeDates = this.updateRangeDates();
  }

  get fromDate() {
    return this.#fromDate;
  }

  set fromDate(value) {
    if (this.toDate && value > this.toDate) {
      throw new Error('Значение не может быть больше "Период по" ');
    }
    this.#fromDate = value;
  }

  get toDate() {
    return this.#toDate;
  }

  set toDate(value) {
    if (this.fromDate && value < this.fromDate) {
      throw new Error('Значение не может быть меньше "Период с" ');
    }
    this.#toDate = value;
  }

  get fromDateRange() {
    return this.#fromDateRange;
  }

  set fromDateRange(value) {
    if (this.toDateRange && value > this.toDateRange) {
      throw new Error('Значение не может быть больше "Период по" ');
    }
    this.#fromDateRange = value;
  }

  get toDateRange() {
    return this.#toDateRange;
  }

  set toDateRange(value) {
    if (this.fromDateRange && value < this.fromDateRange) {
      throw new Error('Значение не может быть меньше "Период с" ');
    }
    this.#toDateRange = value;
  } 
}
