import { useState, useRef, useEffect } from 'react';
import { useSnackbar } from "notistack";
import { API_HOST } from "../../../../../configs/main";


export const BannerCarousel = ({ banners, onBannerClick, onDelete }) => {
  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    const loadDimensions = async () => {
      const newDimensions = {};
      for (const banner of banners) {
        const img = new Image();
        img.src = banner.url;
        await new Promise((resolve) => {
          img.onload = () => {
            newDimensions[banner.id] = `${img.width}×${img.height}`;
            resolve();
          };
          img.onerror = resolve;
        });
      }
      setDimensions(newDimensions);
    };
    loadDimensions();
  }, [banners]);

  return (
    <div>
      <div style={{ display: banners.length > 0 ? "block" : "none" }}>
        Список добавленных ресайзов
      </div>
      <div
        style={{
          marginTop: 10,
          display: "flex",
          gap: 10,
          overflowX: "auto",
          width: 750,
        }}
      >
        {banners.map((banner, index) => (
          <div
            key={index}
            style={{
              minWidth: 250,
              height: 250,
              borderRadius: 5,
              overflow: "hidden",
              cursor: "pointer",
              background: "#f0f1f3",
              position: "relative",
            }}
            onClick={() => onBannerClick(index)}
          >
            {banner.extension !== "mp4" ? (
              <img
                alt="Banner"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                src={banner.url}
              />
            ) : (
              <video
                src={banner.url}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                muted
                autoPlay
              />
            )}
            <div
              onClick={(e) => {
                e.stopPropagation();
                onDelete(banner.id);
              }}
              style={{
                position: "absolute",
                right: 5,
                top: 5,
                opacity: "0.7",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                width: 30,
                height: 30,
                color: "rgba(255, 255, 255, .7)",
                borderRadius: 5,
                fontSize: 5,
                cursor: "pointer",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-x"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </div>
            <div
              style={{
                position: "absolute",
                right: 5,
                bottom: 5,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "white",
                padding: "2px 5px",
                borderRadius: 3,
                fontSize: 12,
                pointerEvents: "none",
              }}
            >
              {dimensions[banner.id] || "Загрузка..."}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


const BannerUpload = ({ onUpload }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = async (files) => {
    const newBanners = await Promise.all(
      Array.from(files).map(async (file) => {
        const maxSize = file.type.startsWith('image/') ? 20 * 1024 * 1024 : 50 * 1024 * 1024;
        if (file.size > maxSize) {
          enqueueSnackbar(`Размер файла ${file.name} превышает лимит!`, { variant: 'error' });
          return null;
        }
  
        const url = URL.createObjectURL(file);
        let dimensions = null;
  
        if (file.type.startsWith('image/')) {
          dimensions = await new Promise(resolve => {
            const img = new Image();
            img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
            img.src = url;
          });
        }
  
        if (file.type.startsWith('video/')) {
          dimensions = await new Promise(resolve => {
            const video = document.createElement('video');
            video.onloadedmetadata = () => {
              if (video.duration > 30) {
                enqueueSnackbar(`Длительность видео ${file.name} превышает лимит!`, { variant: 'error' });
                return null;
              }
              resolve({ width: video.videoWidth, height: video.videoHeight });
            };
            video.src = url;
          });
        }

        if (dimensions) {
          return { url, type: file.type, file, dimensions };
        }
        return null;
      })
    );
  
    const validBanners = newBanners.filter(banner => banner !== null);
  
    onUpload(validBanners);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileChange(e.dataTransfer.files);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <div>Загрузить баннер</div>
      <label 
        htmlFor="banner-upload" 
        style={{
          cursor: "pointer", 
          background: isDragging ? "#c8d4ff" : "#dee7ff",
          width: "250px",
          height: "250px",
          color: "#3f73f9",
          border: `1px dashed ${isDragging ? "#1a237e" : "#3f51b5"}`,
          borderRadius: 5,
          padding: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
          flexDirection: "column",
          transition: 'background 0.3s ease'
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input 
          ref={fileInputRef}
          type="file" 
          style={{ display: "none" }} 
          id="banner-upload"
          multiple
          accept="image/*, video/*"
          onChange={(e) => handleFileChange(e.target.files)}
        />
        <p 
          style={{ 
            textAlign: "center", 
            fontSize: 12 
          }}
        >
          Перетащите баннер из медиатеки или кликните для загрузки с вашего компьютера
        </p>
      </label>
      <button 
        onClick={handleUploadButtonClick}
        style={{  
          background: "#3f73f9", 
          color: "white", 
          padding: "10px 20px", 
          borderRadius: 5, 
          border: "none", 
          cursor: "pointer" 
        }}
      >
        Загрузить
      </button>
    </div>
  );
};

export const Modal = ({ banner, onClose }) => {
  if (!banner) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}
    onClick={onClose}
    >
      <div style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        borderRadius: 8,
        maxWidth: '90%',
        maxHeight: '90%',
        textAlign: 'center'
      }}
      // onClick={(e) => e.stopPropagation()}
      >
        <div
          onMouseEnter={(e) => (e.currentTarget.style.color = "rgba(255, 255, 255, 1)")}
          onMouseLeave={(e) => (e.currentTarget.style.color = "rgba(255, 255, 255, .7)")}
          style={{
            position: 'absolute',
            right: -30,
            top: -20,
            opacity: '0.7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 30,
            height: 30,
            color: 'rgba(255, 255, 255, .7)',
            borderRadius: 5,
            fontSize: 5,
            cursor: 'pointer'
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
        </div>
        {banner.extension != 'mp4' ? (
          <img
            src={banner.url}
            alt="Full banner"
            style={{ maxWidth: '800px', maxHeight: '600px' }}
          />
        ) : (
          <video 
            src={banner.url}
            controls 
            autoPlay
            style={{ maxWidth: '800px', maxHeight: '600px' }}
          >
          </video>
        )}
      </div>
    </div>
  );
};

export const BannerComponent = ({ campaignID, upd, setUpd, currentAdId, banners, setBanners, disabled }) => {
  const [selectedBannerIndex, setSelectedBannerIndex] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = async (newBanners) => {
    if ( disabled ) return enqueueSnackbar("Можно изменять данные только у выключенного объявления", { variant: "info" });
    for (const fileObj of newBanners) {
      const data = new FormData();
      data.append("upload_file", fileObj.file);

      try {
        const response = await fetch(`${API_HOST}/api/v1/advertisements/upload-banner?advert_id=${currentAdId}`, {
          method: "POST",
          body: data,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
        });

        if (response.ok) {
          const result = await response.json();
          setBanners((prev) => [...prev, result]);
          setUpd((value) => !value);
        } else {
          enqueueSnackbar("Ошибка при загрузке баннера", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("Ошибка сети при загрузке баннера", { variant: "error" });
      }
    }
  };


  const handleDelete = async (bannerId) => {
    if ( disabled ) return enqueueSnackbar("Можно изменять данные только у выключенного объявления", { variant: "info" });
    const result = window.confirm("Вы уверены, что хотите удалить баннер?");
    if (!result) return;
    try {
      const response = await fetch(`${API_HOST}/api/v1/advertisements/delete-banner?_id=${bannerId}`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
      });

      if (response.ok) {
        setUpd((value) => !value);
        setBanners((prev) => prev.filter((banner) => banner.id !== bannerId));
      } else if (response.status == "404") {
        enqueueSnackbar("Ошибка, баннер не найден", { variant: "error" });
      } else {
        enqueueSnackbar("Ошибка при удалении баннера", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Ошибка сети при загрузке баннера", { variant: "error" });
    }
  };

  const handleClickCreative = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    const res = await fetch(`${API_HOST}/api/v1/utils/help/banners/?campaign_id=${campaignID}`,{
      method:'POST',
      headers:myHeaders,
      body:JSON.stringify({})
    });
    if(res.ok){
      enqueueSnackbar("Запрос принят! Скоро с вами свяжется наш менеджер", {variant:"success"})
    }
  }

  return (
    <div style={{ display: "flex", gap: 30, position: "relative"}}>
      <BannerUpload onUpload={handleUpload} />
      <BannerCarousel 
        banners={banners} 
        onBannerClick={setSelectedBannerIndex} 
        onDelete={handleDelete}
      />

      <div style={{ position: "absolute", top: -120, right: -20 }}>
        <button style={{ padding: "5px 10px", fontSize: "16px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}} onClick={() => {handleClickCreative()}}>Помочь с креативами</button>
      </div>
      
      <Modal
        banner={banners[selectedBannerIndex]}
        onClose={() => setSelectedBannerIndex(null)}
      />
    </div>
  );
};