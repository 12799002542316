import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ background }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10000,
  background,
}));
const LoadingBox = ({ children, background = "rgba(0,0,0,0.2)" }) => {
  return <StyledBox background={background}>{children}</StyledBox>;
};

export default LoadingBox;
