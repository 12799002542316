import { useNavigate, useSearchParams } from "react-router-dom";
// import { client } from "../../utils/network";
import LoadingBox from "../../ui/LoadingBox/LoadingBox";
import { CircularProgress } from "@mui/material";
import { API_HOST } from "../../configs/main";

function SignUpConfirm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  fetch(`${API_HOST}/api/v1/auth/email/confirm`, {
    method: "post",
    headers: myHeaders,
    body: JSON.stringify({
      token: searchParams.get("confirmation_token"),
    }),
  })
    .then((res) => {
      if (res.ok) {
        navigate("/sign-in?success=true");
      } else {
        return res.json();
      }
    })
    .then((data) => {
      if(data.detail === 'Invalid token'){
        const email = searchParams.get('email')
        navigate(`/sign-in?err=token&email=${email}`);
      }else{
        const email = searchParams.get('email')
        navigate(`/sign-in?err=unknown&email=${email}`);
      }
    });
  return (
    <LoadingBox>
      <CircularProgress />
    </LoadingBox>
  );
}

export default SignUpConfirm;
