import "./Company.scss";
import { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "../../ui";
import {  CompanyBubble, SortPopup } from "../../widgets";
import { enhance } from "./controller";
import { useDispatch, useSelector } from "react-redux";
import { campaignsSelector } from "../../redux/campaignsInfo/selectors";
import SortCompany from "./SortCompany";
import {
  changeFilteredCampaigns,
  changeFilterValue,
  initCampaigns,
} from "../../redux/campaignsInfo/actions";
// import { getCampaigns } from "../../redux/createNewCompany/actions";
// import { getSegments } from "../../redux/segments/actions";
import { client } from "../../utils/network";
import { useSnackbar } from "notistack";

// const CAMPAIGNS = ["2b2817c7-3dd2-4a3c-b811-aba5a0e8d1f0"];

function Company({ campaigns, isArchive }) {
 // const [visibleModalSettings, setVisibleModalSettings] = useState(false);
  const dispatch = useDispatch();
  const { filteredCampaigns } = useSelector(campaignsSelector.all_object);
  const { enqueueSnackbar } = useSnackbar();
  // const toggleVisibleModalSettings = () =>
  //   setVisibleModalSettings(!visibleModalSettings);
  // useLayoutEffect(() => {
  //   dispatch(getCampaigns());
  //   dispatch(getSegments());
  // }, []);
  // useEffect(() => {
  //   CAMPAIGNS.forEach((item) => {
  //     console.log("delete");
  //     client.delete(`/api/v1/campaigns/${item}`);
  //   });
  // }, []);
  useEffect(()=>{
    function data_change(data) {
      let data1 = data.split('T')[1]
      data = data.split('T')[0]
      let str = data.slice(8,10) + "/"+ data.slice(5,7) + "/" + data.slice(0,4) + " " + data1.slice(0,8)
      return str
    }
    client.get("/api/v1/utils/maintenance-time").then((res)=> 
      res.data ? 
      enqueueSnackbar(`Запланированы технические работы с ${data_change(res?.data?.start_date)}
      по ${data_change(res?.data?.end_date)}`, { variant: "warning" }) : "");
  }, []);

  useEffect(() => {
    if (campaigns) {
      dispatch(initCampaigns(campaigns));
      dispatch(changeFilteredCampaigns());
    }
  }, [campaigns]);

  const handleChangeSortValue = (value) => {
    dispatch(changeFilterValue({ name: "sortedType", value: value.type }));
    dispatch(changeFilteredCampaigns());
  };

  const CampaignsList = filteredCampaigns.filter(
    (items) => (!isArchive ? items.status !== "DELETED" : items.status === "DELETED")
  );
  
  return (
    <div className="Company">
      <Typography variant="h1" color="dark-gray">
        {isArchive ? "Архивные кампании" : "Кампании"}
      </Typography>
      <div className="filter">
        <div>
          { !isArchive && <SortCompany /> }
          {/* <Button variant="rate" onClick={toggleVisibleModalSettings}>
          <SettingsIcon />
          Настройка
        </Button>

        {visibleModalSettings && (
          <ModalSettings
            onClose={toggleVisibleModalSettings}
            isOpen={visibleModalSettings}
          />
        )} */}
          {!isArchive && CampaignsList.length > 0 &&
            <div style={(window.outerWidth > 1000) ? {
              position: "absolute",
              top: 255,
            } : (window.outerWidth < 768) ? {position: "absolute",
                top: 275} : {position: "absolute",
                top: 210}}>
              <SortPopup
                sortedBy = { true }
                title="Сортировать по"
                items={[
                  { name: "дате создания", type: "created_at" },
                  { name: "бюджету(общий)", type: "full_budget" },
                  { name: "бюджету(дневной)", type: "daily_budget" },
                  { name:"кликам", type:"click" }
                ]}
                onChange={handleChangeSortValue}
              />
            </div>
          }
        </div>
        { !isArchive && 
            <Link to="/add-company">
              <Button variant="gradient">Добавить кампанию</Button>
            </Link>
        }
      </div>
      {/* <Analytics /> */}
      {CampaignsList.length > 0 ? (
        CampaignsList.map((items) => <CompanyBubble key={items.id} {...items} />)
      ) : (
        isArchive && (
          <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "100%"}}>
            <svg style={{opacity: ".7", width: "120px", height: "120px"}} xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#42454c" viewBox="0 0 256 256"><path d="M241.72,113a11.88,11.88,0,0,0-9.73-5H212V88a12,12,0,0,0-12-12H130.66a4,4,0,0,1-2.4-.8L100.53,54.4a12.05,12.05,0,0,0-7.2-2.4H40A12,12,0,0,0,28,64V208h0a4,4,0,0,0,4,4H211.09a4,4,0,0,0,3.79-2.74l28.49-85.47A11.86,11.86,0,0,0,241.72,113ZM40,60H93.33a4,4,0,0,1,2.4.8l27.73,20.8a12.07,12.07,0,0,0,7.2,2.4H200a4,4,0,0,1,4,4v20H69.76a12,12,0,0,0-11.38,8.21L36,183.35V64A4,4,0,0,1,40,60Zm195.78,61.26L208.2,204H37.55L66,118.74A4,4,0,0,1,69.76,116H232a4,4,0,0,1,3.79,5.26Z"></path></svg>
            <p style={{fontSize: "20px", textAlign: "center", width: "100%", opacity: ".7"}}>В архиве нет кампаний.</p>
          </div>
        )
      )}
    </div>
  );
}

const CompanyMemo = memo(Company);
export default enhance(CompanyMemo);
