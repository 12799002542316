import { combineReducers } from "redux";

import { usersReducer } from "./reducers/reducer";
import { createNewCompanyReducer } from "./createNewCompany/reducer";
import { createNewSegmentReducer } from "./segments/reducer";
import { getPreferencesReducer } from "./preferences/reducer";
import user from "./user/reducer";

import { createBannersReducer } from "./createBanners/reducer";
import { campaignsInfoReducer } from "./campaignsInfo/reducer";
import { countersReducer } from "./counters/reducer";
import { notificationsReducer } from "./notifications/reducer";

const rootReducer = combineReducers({
  company: usersReducer,
  createNewCompany: createNewCompanyReducer,
  segments: createNewSegmentReducer,
  preferences: getPreferencesReducer,
  user,
  createBanners: createBannersReducer,
  campaignsInfo: campaignsInfoReducer,
  counters: countersReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
