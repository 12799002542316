export const filterFio = (keyword, arr) => {
  const results = arr.filter((user) => {
    const name = user.full_name.split(" ")[0];
    const surname = user.full_name.split(" ")[1];
    if (keyword.includes(" ")) {
      return user.full_name.toLowerCase().startsWith(keyword.toLowerCase());
    }
    if (name.toLowerCase().startsWith(keyword.toLowerCase())) {
      return true;
    } else if (surname.toLowerCase().startsWith(keyword.toLowerCase())) {
      return true;
    }
    return false;
  });
  return results;
};
export const filterEmail = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.email.toLowerCase().startsWith(keyword.toLowerCase());
  });
  return results;
};
export const filterRole = (keyword, arr) => {
  const results = arr.filter((user) => {
    return String(user.role).toLowerCase().startsWith(keyword.toLowerCase());
  });
  return results;
};

export const INIT_FILTER_VALUE = {
  name: "",
  email: "",
  role: "",
};
