export const filterDate = (oDate, arr) => {
    const tempDate = {
      date_end: oDate.date_end || new Date(3000, 10, 10),
      date_start: oDate.date_start || new Date(3, 10, 10),
    };
    const result = arr.filter((data) => {
      if (!oDate.date_start && !oDate.date_end) return true;
  
      const date = new Date(data.created_at);
      const isInside = tempDate.date_start <= date && tempDate.date_end >= date;
      if (isInside) return true;
  
      return false;
    });
    return result;
  };
  
  export const filterEmploye = (keyword, arr) => {
    const results = arr.filter((user) => {
      const name = user.employee.split(" ")[0];
      const surname = user.employee.split(" ")[1];
      if (keyword.includes(" ")) {
        return user.employee.toLowerCase().startsWith(keyword.toLowerCase());
      }
      if (name.toLowerCase().startsWith(keyword.toLowerCase())) {
        return true;
      } else if (surname.toLowerCase().startsWith(keyword.toLowerCase())) {
        return true;
      }
      return false;
    });
    return results;
  };
  
  export const INIT_FILTER_VALUE = {
    date: {
      date_end: "",
      date_start: "",
    },
    full_name: "",
  };
  