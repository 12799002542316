import { useState } from "react";
import DataTable from "../Table/Datatable";
import { format as dateFormat } from "date-fns";
import { useEffect } from "react";
import { API_HOST } from "../../../configs/main";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";
import "./Moderation.scss";
import { INIT_FILTER_VALUE, filterDate, filterEmploye } from "./filters";
import { Modal, Box, Typography } from "@material-ui/core";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { AdsForManagers } from "../../../components/addCompany/stages/Banners/newBanner/Ads";

const styles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: 20,
  p: 4,
};

const decisionStyles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: 20,
  p: 4,
};

const campaignStyles = {
  maxHeight: "600px",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: 20,
  p: 4,
};


const CampaignData = ({data}) => {
  const { enqueueSnackbar } = useSnackbar();
  const listCampaigns = []
  listCampaigns.push(data)

  const columns = [
    {
      Header: "ID РК",
      accessor: "id",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Название",
      accessor: "name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Почта клиента",
      accessor: "campaign_creator_email",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Дата создания",
      accessor: "created_at",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Тип РК",
      accessor: "type",
      maxWidth: 400,
      minWidth: 60,
      width: 117,
    },
    {
      Header: "Дневной бюджет",
      accessor: "daily_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Общий бюджет",
      accessor: "full_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Ставка",
      accessor: "bet",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Период с-по",
      accessor: "range",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сумма спис",
      accessor: "charge",
      maxWidth: 400,
      minWidth: 50,
      width: 150,
    },
    {
      Header: "Статус",
      accessor: "status",
      maxWidth: 400,
      minWidth: 50,
      width: 200,
    },
  ];

  const STATUS_TYPE = {
    ACTIVE: "На модерации",
    INACTIVE: "Неактивна",
    WORK: "Работает",
    MODERATION: "На модерации",
    ERROR: "Ошибка",
  };
  
  const TARGET_TYPE = {
    TRAFFIC: "Трафик",
    REACH: "Охват",
  };

  function getTableData() {
    return listCampaigns.map((el) => {
      return {
        id: (
          <div
            className="td"
            style={{ display: "flex", flexDirection: "row", borderRadius: 5 }}
          >
            {el.id.slice(0, 8)}
            <ContentCopy
              style={{ marginLeft: 5, cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(el.id);
                enqueueSnackbar("ID кампании скопирован", {
                  variant: "success",
                  autoHideDuration: 1000,
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
              }}
              className="copyIcon"
              fontSize="small"
            />
          </div>
        ),
        name: <div className="td">{el.name ? el.name : "-"}</div>,
        site: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.site ? el.site : "-"}</span>
            {el.site && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.site);
                  enqueueSnackbar("Сайт кампании скопирован", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        campaign_creator_email: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.campaign_creator_email ? el.campaign_creator_email : "-"}</span>
            {el.campaign_creator_email && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.campaign_creator_email);
                  enqueueSnackbar("Почта клиента скопирована", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        created_at: (
          <div className="td">
            {dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}
          </div>
        ),
        type: <div className="td">{TARGET_TYPE[el.target_type]}</div>,
        daily_budget: <div className="td">{el.daily_budget}</div>,
        full_budget: <div className="td">{el.full_budget}</div>,
        bet: <div className="td">{el.price_per_action}</div>,
        range: (
          <div className="td">
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_start)),
              "dd/MM/yyyy"
            )}
            -
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_end)),
              "dd/MM/yyyy"
            )}
          </div>
        ),
        charge: <div className="td">{el.charges_sum}</div>,
        status: (
          <div className="td">
            {STATUS_TYPE[el.status]}
          </div>
        ),
      };
    });
  }

  return (
    <DataTable data={getTableData()} columns={columns} />
  )
}


const OpenModal = ({ openModal, setOpenModal, type, campaignData, upd, setUpd }) => {
  let modalContent;

  switch (type) {
    case "decision":
      modalContent = (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={decisionStyles}>
            <h3 style={{ fontSize: 18 }}>
              Решение по рекламной компании - {campaignData.name}
            </h3>
            <p style={{ fontSize: 14, marginTop: 10, fontWeight: 400 }}>
              На рассмотрение была отправлена рекламная кампания. Примите решение по объявлениям.
            </p>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              { Object.keys(campaignData).length !== 0 && <AdsForManagers campaignId={campaignData.id} isErid />}
            </div>
          </Box>
        </Modal>
      );
      break;
    
    case "open_creative":
      modalContent = (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Объявления кампании
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
              { Object.keys(campaignData).length !== 0 && <AdsForManagers campaignId={campaignData.id} />}
            </div>
          </Box>
        </Modal>
      )
      break;
    
      case "open_campaign":
        modalContent = (
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={campaignStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Данные РК
              </Typography>
              <Box
                style={{ gap: 20, marginBottom: 30, marginTop: 10 }}
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                {Object.keys(campaignData).length !== 0 && <CampaignData data={campaignData}/>}
              </Box>
            </Box>
          </Modal>
        )
        break;

    default:
      modalContent = null;
  }

  return modalContent;
};

function Moderation() {
  const [filterArr, setFilter] = useState([]);
  const [arrCampaigns, setCampaigns] = useState([]);
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [campaignData, setCampaignData] = useState({});
  const [upd, setUpd] = useState(false);
  
  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/on-moderation`, {
        method: "GET",
        headers: myHeaders,
      });
      if (res.ok) {
        const data = await res.json();
        setCampaigns(data);
        setFilter(data);
      }
    }
    fetchData();
  }, [upd]);

  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "date":
            if (value === "") return acc;
            return filterDate(value, acc);
          case "full_name":
            if (value === "") return acc;
            return filterEmploye(value, acc);
          default:
            return acc;
        }
      },
      [...arrCampaigns]
    );
    setFilter(result);
  }, [filtersValue, arrCampaigns]);

  const fetchCampaign = async (campaign_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaign_id}`, {
      method: "GET",
      headers: myHeaders,
    });
    if (res.ok) {
      return await res.json();
    }
  }

  const columns = [
    {
      Header: "Название РК",
      accessor: "campaign_name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Почта клиента",
      accessor: "email",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата создания РК",
      accessor: "created_date",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Открыть объявления",
      accessor: "open_creative",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Открыть РК",
      accessor: "open_campaign",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Принять решение по РК",
      accessor: "decision",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };

  function getTableData() {
    return filterArr.map((el) => {
      return {
        campaign_name: <div className="td">{el.name}</div>,
        email: <div className="td">{el.campaign_creator_email}</div>,
        site: <div className="td">{el.site}</div>,
        created_date: <div className="td">{dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}</div>,
        open_creative: (
          <button
            onClick={async () => {
              const data = await fetchCampaign(el.id)
              setCampaignData(data)
              setOpenModal(true)
              setModalType("open_creative")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть
          </button>
        ),
        open_campaign: (
          <button
            onClick={async () => {
              const data = await fetchCampaign(el.id)
              setCampaignData(data)
              setOpenModal(true)
              setModalType("open_campaign")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть
          </button>
        ),
        decision: (
          <button
            onClick={async () => {
              const data = await fetchCampaign(el.id)
              setCampaignData(data)
              setOpenModal(true)
              setModalType("decision")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        )
      };
    });
  } 
  return (
    <div className="logs" style={{ width: "100%", height: "97%" }}>
      <div className="filters">
        <div className="searchDate">
          <DateFromTo withoutStartDate={true} handleChange={handleDateChange} />
        </div>
      </div>
      <div style={{ overflowX: "auto", width: "100%", overflowY: "auto", height: "94%" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>
      <OpenModal openModal={openModal} setOpenModal={setOpenModal} type={modalType} campaignData={campaignData} upd={upd} setUpd={setUpd}/>
    </div>
  );
}

export default Moderation;
