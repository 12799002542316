import { format as dateFormat } from "date-fns";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import  useUser from  "../../data/useUser";
import { Button } from "../../ui";
import { ReplenishmentForm, ReplenishmentTable } from "../../widgets";
import { DatePicker } from "../../widgets";
import "./Replenishment.scss";
import { userSelectors } from "../../redux/user/selectors";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_HOST } from "../../configs/main"

const FORMIK_INITIAL_VALUES = {
  // selected_campaigns: [],
  media_plan: {
    date_start: null,
    date_end: null,
  },
};

function Replenishment() {
  const [visibleForm, setVisibleForm] = useState(false);
  const [formikValues, setFormikValues] = useState(FORMIK_INITIAL_VALUES);
  const { userRequisites } = useSelector(userSelectors.all_object);
  const navigate = useNavigate();
  const isFullDataForPayment = useMemo(() => {
    if (!userRequisites) return false;
    return Object.values(userRequisites).every((field) => !!field);
  }, [userRequisites]);

 // todo: Remove
  //const { replenishment, isLoading } = usePaymentsHistory();
  const {
    user: {
      user_balance: {transactions},
    },
  } = useUser();

  const isBetweenDate = (
    date,
    start,
    finish
  ) => {
    start = start?.toLocaleDateString('en-CA')
    finish = finish?.toLocaleDateString('en-CA')
    const temp = typeof date === "string" ? new Date(date) : date;
    return temp >= new Date(start) && temp <= new Date(finish);
  };

  function getFilteredReplenishment() {
    // console.log(formikValues,transactions);

    if (
      !formikValues.media_plan.date_start &&
      !formikValues.media_plan.date_end
    ) {
      return transactions;
    }

    const {
      media_plan: { date_start, date_end },
    } = formikValues;

    const filteredByDate = transactions.filter((item) =>
      isBetweenDate(item?.created_at.split('T')[0], date_start, date_end)
    );
   
    return filteredByDate
  }

  const filteredReplenishment = getFilteredReplenishment().filter((el) => el.status !== "overdue" && el.status !== 'custom');

  const download_file = async (id) => {
    const myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    fetch(`${API_HOST}/api/v1/payment/check/${id}/download`, {
					method: 'GET',
					headers: myHeaders,
				}).then(async response => {
      //console.log(response);
      if (response.ok){
        const link = document.createElement('a');
        let filename = response.headers?.get("content-disposition").split('filename*=')[1];
        let filename2 = decodeURIComponent(filename.replace("utf-8''", ''));
        filename = filename2;
        const blob = await response.blob();
        if (filename === null) filename = "Check.pdf";
        link.download = filename;//'Check.bin';
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);}})
  }


  function getTableData() {
    // if (isLoading) {
    //   return filteredReplenishment;
    // }
    return filteredReplenishment.map((repl) => {
      return {
        Date: dateFormat(Date.parse(repl.created_at), "dd/MM/yyyy"),
        Operations: `Авансовый платеж по счету №${repl.number}`, // todo
        AmountExcludingVAT: repl.money ? intToStringMoney(repl.money) : '-',
        SumIncludingVAT: repl.money_vat ? intToStringMoney(repl.money_vat) : '-', // todo
        Documents: (
          <button
              style={{ color: "#3F73F9"}}
              onClick={()=>download_file(repl.id)}>
              Скачать
            </button> 
        ), // todo
        Status: repl.status === "succeeded" ? "Оплачен" : repl.status === "pending" ? "Ожидается" : "Отменён", // todo: In TZ three types of status, but here only two states of paid flag.
      };
    });
  }

  const handlePayment = async () => {
    //if (userInfo?.is_entity) 
    if (!isFullDataForPayment) return navigate("/balance");
  };
  const tableData = getTableData();

  return (
    <div className="Replenishment">
      {!visibleForm && (
        <Button variant="primary" className="refill" onClick={handlePayment}>
          Пополнить
        </Button>
      )}

      {visibleForm && (
        <ReplenishmentForm handleCancel={() => setVisibleForm(false)} />
      )}

      <Formik
        initialValues={FORMIK_INITIAL_VALUES}
        onSubmit={(value) => {
          console.log("export");
          console.log(value);
        }}
        innerRef={(formikAction) => {
          if (!formikAction) {
            return;
          }
          const { values } = formikAction;
          if (
            values.media_plan.date_start ===
              formikValues.media_plan.date_start &&
            values.media_plan.date_end === formikValues.media_plan.date_end
          ) {
            return;
          }
          setFormikValues(formikAction.values);
        }}
      >
        <Form className="container">
          {/* <StatisticsSort /> */}
          <DatePicker />
        </Form>
      </Formik>
      <div className="line"></div>
      <ReplenishmentTable data={tableData}/>
    </div>
  );
}

function intToStringMoney(n) {
  const formatted = n
    .toString()
    .split("")
    .reverse()
    .join("")
    .replace(/(\d{3})/g, "$1 ")
    .split("")
    .reverse()
    .join("")
    .replace(/^ /, "");
  return `${formatted} ₽`;
}

// function removeTime(date) {
//   return new Date(date.getFullYear(), date.getMonth(), date.getDate());
// }

export default Replenishment;
