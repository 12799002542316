import React, { useState, useEffect } from 'react';
import './SearchSet.scss';
import { Switch, FormControlLabel } from '@mui/material';
import useRegions from '../../data/useRegions';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

function deleteFromSelected(node, selected, setSelected) {
    const array = selected.filter((value) => value !== node);
    setSelected([...array]);
}

export default function Search({ edit = false, open, value, onChange }) {
    const [selected, setSelected] = useState(value);
    useEffect(() => onChange(selected), [selected]);
    const { regions } = useRegions();
    const [values, setValue] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    if (!open) return null;

    const onChanges = (event) => {
        setValue(event.target.value);
        setDropdownOpen(true);
    };

    const onSearch = (searchTerm) => {
        setValue("");
        setDropdownOpen(false);
        if (!selected.includes(searchTerm.id)) {
            setSelected([...selected, searchTerm.id]);
        }
    };

    function getOnChange(checked, node) {
        if (checked) {
            setSelected([...selected, node]);
        } else {
            setSelected(selected.filter((value) => value !== node));
        }
    }

    function RenderTreeWithSwitch(node) {
        const region = regions.find((item) => item.id === node);
        return (
            <div key={node}>
                <FormControlLabel
                    control={(
                        <Switch
                            color="success"
                            checked={selected.includes(node)}
                            disabled={edit}
                            onChange={(event) => getOnChange(event.target.checked, node)}
                        />
                    )}
                    label={region?.name || 'Неизвестный регион'}
                />
                {!edit && (
                    <button type="button" onClick={() => deleteFromSelected(node, selected, setSelected)}>
                        <CloseIcon />
                    </button>
                )}
            </div>
        );
    }

    return (
        <div>
            <div className="App">
                <h2>Поиск регионов</h2>
                <div className="search-container">
                    <div className="search-inner">
                        <input
                            placeholder="Введите регион"
                            disabled={edit}
                            type="text"
                            value={values}
                            onChange={onChanges}
                            onBlur={() => setTimeout(() => setDropdownOpen(false), 200)}
                        />
                    </div>
                    {dropdownOpen && (
                        <div className="dropdown">
                            {regions &&
                                regions
                                    .filter((item) =>
                                        item.name.toLowerCase().includes(values.toLowerCase().trim())
                                    )
                                    .slice(0, 10)
                                    .map((item) => (
                                        <button
                                            type="button"
                                            onClick={() => onSearch(item)}
                                            className="dropdown-row"
                                            key={item.id}
                                        >
                                            {item.name}
                                        </button>
                                    ))}
                        </div>
                    )}
                </div>
            </div>
            {selected.sort().map((item) => RenderTreeWithSwitch(item))}
        </div>
    );
}
