import { useEffect, useRef, useState } from "react";
import TimePicker from "@mui/lab/TimePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { Dropdown } from "../Dropdown";
import "./rangeDaysPicker.scss";
import OneDay from "./OneDay";
import { useThrottle } from "./hooks";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { enqueueSnackbar } from "../../redux/notifications/actions";
import { useDispatch } from "react-redux";

const AVAILABLE_HOURS = [
  { id: 1, optionValue: "6-22", optionLabel: "Днем" },
  { id: 2, optionValue: "23-5", optionLabel: "Ночью" },
];
const AVAILABLE_DAYS = [
  { id: 1, optionValue: "1-5", optionLabel: "Рабочие" },
  { id: 2, optionValue: "6-0", optionLabel: "Выходные" },
];
const FPS = 60;

const createTwoDigitNum = (num) => (num > 9 ? num : `0${num}`);
const renderTimeGrid = (data, handleClick) => {
  const { rangeDates, clickedDates } = data;
  return [...rangeDates].map(([key, value], index) => (
    <OneDay
      key={index}
      arrHours={value}
      dayDate={key}
      handleClick={handleClick}
      activeDays={clickedDates.days?.active ?? []}
    />
  ));
};
const renderClockTimeRow = (data, handleClick) => {
  const { rangeDates, clickedDates } = data;
  const arr = [...rangeDates][0][1];
  return (
    <div className="one_day hour_list" key="hour_list">
      <div className="date_day"></div>
      {arr
        .map(({ date },i) => {
          const className = clickedDates.hours?.active.includes(date.getHours())
            ? "clock_time active"
            : "clock_time";
          return (
            <div
            key={i}
              className={className}
              onClick={(event) => handleClick(event, null, date)}
            >
              {createTwoDigitNum(date.getHours())}
            </div>
          );
        })}
    </div>
  );
};
const renderSelectZone = (coordinates, gridRef, handleSelect) => {
  const { start, finish } = coordinates;
  if (start && finish && gridRef.current) {
    const collection = [...gridRef.current.children];
    const result = [];
    collection.slice(0, collection.length - 1).forEach((row) => {
      const collectionRow = [...row.children];
      const firstEl = collectionRow[0];

      if (
        Math.min(start.y, finish.y) >
        firstEl.offsetTop + 2 * firstEl.offsetHeight
      )
        return;
      if (
        Math.max(start.y, finish.y) <
        firstEl.offsetTop - 2 * firstEl.offsetHeight
      )
        return;

      collectionRow.forEach((cell) => {
        const beetweenX =
          cell.offsetLeft + cell.offsetWidth > Math.min(start.x, finish.x) &&
          cell.offsetLeft < Math.max(finish.x, start.x);
        const beetweenY =
          cell.offsetTop > Math.min(start.y, finish.y) &&
          cell.offsetTop < Math.max(finish.y, start.y);
        if (beetweenX && beetweenY) {
          const date = cell.dataset.date;
          cell.classList.add("in_select_zone");
          result.push(date);
          return;
        }
      });
    });
    handleSelect(result.filter(Boolean));
    return (
      <div
        className="select_zone"
        key="select_zone"
        style={{
          top: Math.min(start.y, finish.y),
          left: Math.min(start.x, finish.x),
          width: Math.abs(start.x - finish.x),
          height: Math.abs(start.y - finish.y),
        }}
      ></div>
    );
  }
  return null;
};

let isMouseDown = false;

const RangeDaysPicker = ({ timeData, disable }) => {
  // eslint-disable-next-line no-unused-vars
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const timer = useRef({});
  const [coordinates, setCoordinates] = useState({});
  const [toTime, setToTime] = useState(timeData.toTime ?? "");
  const [fromTime, setFromTime] = useState(timeData.fromTime ?? "");
  useEffect(() => {
    setToTime(timeData.toTime)
    setFromTime(timeData.fromTime)
  }, [timeData.fromTime, timeData.toTime]);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.addEventListener("mousedown", handleMouseDown);
      gridRef.current.addEventListener("mouseup", handleMouseUp);
      gridRef.current.addEventListener("mousemove", throttledMouseMove);
    }
    return () => {
      if (gridRef.current) {
        gridRef.current.removeEventListener("mousedown", handleMouseDown);
        gridRef.current.removeEventListener("mouseup", handleMouseUp);
        gridRef.current.removeEventListener("mousemove", throttledMouseMove);
      }
    };
  }, []);
  const handleMouseDown = (e) => {
    let value = 0;
    timer.current = {
      id: setInterval(() => (timer.current.value += 100), 100),
      value: value,
    };
    const offsets = gridRef.current.getBoundingClientRect();
    setCoordinates({
      start: {
        x: e.pageX - offsets.x,
        y: e.pageY - offsets.y,
      },
      finish: {
        x: e.pageX - offsets.x,
        y: e.pageY - offsets.y,
      },
    });
    isMouseDown = true;
  };
  const handleMouseUp = () => {
    if (timer.current.id) {
      clearInterval(timer.current.id);
      timer.current = {};
      setCoordinates({});
      isMouseDown = false;
      timeData = timeData.saveSelectedZone();
      setCounter((prev) => prev + 1);
      document
        .querySelectorAll(".in_select_zone")
        .forEach((item) => item.classList.remove("in_select_zone"));
    }
  };
  const handleMouseMove = (event) => {
    if (timer.current && timer.current.value > 99) {
      if (isMouseDown) {
        const offsets = gridRef.current.getBoundingClientRect();
        setCoordinates((prev) => ({
          ...prev,
          finish: {
            x: event.pageX - offsets.x,
            y: event.pageY - offsets.y,
          },
        }));
      }
    }
  };

  const handleChange = (event) => {
    try {
      const { target } = event;
      timeData = timeData.updateData(target.name, target.value);
      setCounter((prev) => prev + 1);
    } catch (err) {
      //alert(err.message);
      dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: "error",
          },
        })
      );
    }
  };
  const handlePickerChange = (key, value) => {
    try {
      timeData = timeData.updateData(key, value);
      setCounter((prev) => prev + 1);
      if (key === "toTime") {
        setToTime(value);
      } else {
        setFromTime(value)
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            variant: "error",
          },
        }));
      //alert(err.message);
    }
  };

  const handleClick = (event, dayDate, hourDate) => {
    if (!disable) {
      event.stopPropagation();
      timeData = timeData.changeStatusDay(dayDate, hourDate);
      setCounter((prev) => prev + 1);
    }
  };

  const today = new Date()

  const throttledMouseMove = useThrottle(handleMouseMove, 1000 / FPS);
  const handleSelect = useThrottle((arr) => {
    timeData = timeData.updateSelectedRange(arr);
    setCounter((prev) => prev + 1);
  }, 1000 / FPS);
  const handleDeleteRange = (index) => {
    timeData = timeData.deleteSelectedRange(index);
    setCounter((prev) => prev + 1);
  };

  return (
    <div>
      <h4 style={{paddingBottom: "20px"}}>Задайте даты активности рекламной кампании</h4>
      <div style={{paddingBottom: "20px"}} className="controls">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled={disable}
            label="Период С"
            value={timeData.fromDate ?? today}
            name="fromDate"
            mask="__/__/____"
            inputFormat="dd/MM/yyyy"
            format="dd-MM-yyyy"
            onChange={(value) => handlePickerChange("fromDate", value)}
            views={["day", "month", "year"]}
            renderInput={(params) => (
              <TextField className="controls_item dateFrom" {...params} />
            )}
          />
          <DatePicker
            label="Период По"
            value={timeData.toDate ?? new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)}
            name="toDate"
            mask="__/__/____"
            inputFormat="dd/MM/yyyy"
            format="dd-MM-yyyy"
            onChange={(value) => handlePickerChange("toDate", value)}
            views={["day", "month", "year"]}
            renderInput={(params) => (
              <TextField className="controls_item dateTo" {...params} />
            )}
          />
        </LocalizationProvider>
      </div>
      <h4>Задайте время активности рекламной кампании в почасовой сетке</h4>
      <div style={{paddingBottom: "30px"}}>
        <div className="list_selected_ranges">
          {timeData.listSelectedRanges?.length
            ? timeData.listSelectedRanges.map(({ title }, index) => (
              <div className="selected_range" key={title}>
                <span>{title}</span>
                <IconButton onClick={() => handleDeleteRange(index)}>
                  <CloseIcon />
                </IconButton>
              </div>
            ))
            : null}
        </div>
        <div className="days-grid" ref={gridRef}>
          <>
            {timeData.rangeDates?.size && [
              renderTimeGrid(timeData, handleClick),
              renderClockTimeRow(timeData, handleClick),
              !disable && isMouseDown && renderSelectZone(coordinates, gridRef, handleSelect),
            ]}
          </>
        </div>
      </div>
      <h4 style={{paddingBottom: "20px"}}>Выберите промежуток времени в сетке почасовой активности рекламной кампании</h4>
      <div className="controls">
        <div className="controls_item">
          <Dropdown
            disabled={disable}
            label="Время"
            options={AVAILABLE_HOURS}
            name="hours"
            value={timeData.hours ?? []}
            withCheckboxes="true"
            onChange={handleChange}
          />
        </div>
        <div className="controls_item">
          <Dropdown
            disabled={disable}
            label="Дни"
            options={AVAILABLE_DAYS}
            name="days"
            value={timeData.days ?? []}
            withCheckboxes="true"
            onChange={handleChange}
            className="controls_item"
          />
        </div>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            disabled={disable}
            label="Время с"
            minutesStep={60}
            value={fromTime}
            name="fromTime"
            onChange={(value) => handlePickerChange("fromTime", value)}
            ampm={false}
            views={["hours"]}
            renderInput={(params) => (
              <TextField
                className="controls_item timeFrom"
                {...params}
                error={false} 
                inputProps={{ ...params.inputProps, readOnly: true, disabled:true }} 
              />
            )}
          />
          <TimePicker
            disabled={disable}
            label="Время по"
            minutesStep={60}
            value={toTime}
            name="toTime"
            onChange={(value) => handlePickerChange("toTime", value)}
            ampm={false}
            views={["hours"]}
            renderInput={(params) => (
              <TextField
                className="controls_item timeTo"
                {...params}
                error={false}
                inputProps={{ ...params.inputProps, readOnly: true, disabled:true }} 
              />
            )
            }
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default RangeDaysPicker;
