import {ACTIONS} from "./actionTypes";
import {CONFIG_FORMATS} from "../../components/addCompany/stages/Banners/configs";

const initialState = {
  formats: CONFIG_FORMATS,
  activeFormat: null,
  advertisement: {},
  previewSize: null,
  userBanners: [],
  error: '',
  bannerError: '',
  isBannerLoading: false,
}

export const createBannersReducer = (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case ACTIONS.SET_ADVERTISEMENT:
      return {
        ...state,
        advertisement: {...state.advertisement, ...payload}
      }
    case ACTIONS.CHANGE_ACTIVE_FORMAT:
      if (state.activeFormat && state.activeFormat.id === payload) {
        return {
          ...initialState
        }
      }
      return {
        ...initialState,
        activeFormat: state.formats.find(format => format.id === payload),
      };
    case ACTIONS.CHANGE_PREVIEW: {
      return {
        ...state,
        advertisement: {...state.advertisement, preview: payload}
      };
    }
    case ACTIONS.CHANGE_PREVIEW2: {
      return {
        ...state,
        advertisement: {...state.advertisement, preview2: payload}
      };
    }
    case ACTIONS.CHANGE_PREVIEW3: {
      return {
        ...state,
        advertisement: {...state.advertisement, preview3: payload}
      };
    }
    case ACTIONS.CHANGE_PREVIEW_SIZE:
      return {
        ...state,
        previewSize: payload
      }
    case ACTIONS.SET_SAVE_BANNER:
      return {
        ...state,
        userBanners: [...state.userBanners, payload]
      }
    case ACTIONS.SET_ERROR_SAVE_BANNER:
      return {
        ...state,
        bannerError: payload
      }
    case ACTIONS.DELETE_BANNER_FROM_CLIENT:
      return {
        ...state,
        userBanners: state.userBanners.filter(item => item.id !== payload)
      }
    case ACTIONS.SET_USER_BANNERS:
      return {
        ...state,
        userBanners: payload
      }
    case ACTIONS.SET_IS_LOADING_BANNER:
      return {
        ...state,
        isBannerLoading: payload
      }
    case ACTIONS.DELETE_PREVIEW: {
      return {
        ...state,
        advertisement: {...state.advertisement, preview: null},
        previewSize: null
      }
    }
    case ACTIONS.DELETE_PREVIEW2: {
      return {
        ...state,
        advertisement: {...state.advertisement, preview2: null},
      }
    }
    case ACTIONS.DELETE_PREVIEW3: {
      return {
        ...state,
        advertisement: {...state.advertisement, preview3: null},
      }
    }
    default:
      return state
  }
};
