class DateHelper {
  formatDate(date) {
    const day = date.getDate() > 9 ? String(date.getDate()) : '0' + date.getDate()
    const month = date.getMonth() > 9 ? String(date.getMonth()) : '0' + date.getMonth()
    const year = String(date.getFullYear())
    return {day, month, year}
  }

  createSlashDate(date) {
    if (date instanceof Object) {
      return `${date.month}/${date.day}/${date.year}`
    }
    if (date instanceof Date) {
      const objDate = this.formatDate(date)
      return `${objDate.month}/${objDate.day}/${objDate.year}`
    }
    return ''
  }

  createDate(value) {
    if (value instanceof Date) return value
    if (value instanceof Object) return new Date(value.year, value.month, value.day)
    return new Date(value)
  }

  plusDays(data, days) {
    return new Date(data.getTime() + 1000 * 60 * 60 * 24 * days)
  }

  minusDays(data, days) {
    return new Date(data.getTime() - 1000 * 60 * 60 * 24 * days)
  }

  getMondayDate(data) {
    if (data instanceof Date) {
      const currentDay = data.getDay()
      return this.minusDays(data, currentDay - 1)
    }
    if (data instanceof Object) {
      const date = new Date(data.year, data.month, data.day)
      const currentDay = date.getDay()
      return this.minusDays(date, currentDay - 1)
    }
    const date = new Date(data)
    if (date) {
      const currentDay = date.getDay()
      return this.minusDays(date, currentDay - 1)
    }
    return null
  }

  resetDate(date) {
    const year = date.getFullYear(),
      month = date.getMonth(),
      day = date.getDate()
    return new Date(year, month, day, 0, 0, 0, 0)
  }

  getWeekMonth() {
    const data = new Date()
    const currentDay = data.getDay()
    const previousMonday = this.minusDays(data, currentDay - 1)
    const currentMonth = data.getMonth()
    let nextDate = this.plusDays(previousMonday, 7)
    const result = [{name: this.convertToType(previousMonday), value: previousMonday}]
    while (nextDate.getMonth() === currentMonth) {
      result.push({name: this.convertToType(nextDate), value: nextDate})
      nextDate = this.plusDays(nextDate, 7)
    }
    return result
  }

  transformDate(date) {
    const strDate = this.createDate(date)
    return this.convertToType(strDate)
  }
}

export default new DateHelper()


