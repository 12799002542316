import { useNavigate, useSearchParams } from "react-router-dom"
import { client } from "../../utils/network"
import LoadingBox from "../../ui/LoadingBox/LoadingBox";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";

function SignUpEmailChange () {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();
    client.post("/api/v1/auth/email/change/confirm", {
        token: searchParams.get('confirmation_token')
    })
    .then(() => {
        enqueueSnackbar(`Почта успешно изменена`, { variant: "success", autoHideDuration: 1000 });
        navigate("/sign-in?success=true");
    })
    .catch(() => navigate("/sign-in"))
    return (
        <LoadingBox>
            <CircularProgress />
        </LoadingBox>
    )
}

export default SignUpEmailChange;