import "./AdminLayout.scss";
import cx from "classnames";
import { Outlet, Navigate } from "react-router-dom";
import Menu from "../Menu";
import { Loading } from "../../../../ui";
import useAdminUser from "../../../../data/useAdminUser";

function AdminLayout({ className }) {
  const { isLoading, loggedOut, role, token } = useAdminUser();

  if (!token) return <Navigate to="/admin/sign-in" />;

  if (isLoading) return <Loading />;

  if (!isLoading && loggedOut) return <Navigate to="/admin/sign-in" />;

  if (!["MANAGER", "ADMIN", "SUPERUSER"].some((el) => el === role)) return <Navigate to="/admin/sign-in" />;

  return (
    <div className={cx("AdminMainLayout", className)} style={{ display: "flex" }}>
      <div style={{ width: "200px", position: "fixed", height: "100vh", overflowY: "auto" }}>
        <Menu />
      </div>
      <div className="AdminMainLayout-content" style={{ marginLeft: "180px", width: "calc(100% - 200px)" }}>
        <Outlet />
      </div>
    </div>
  );
}

export default AdminLayout;
