import { Tooltip } from "@mui/material";

const TooltipedButton = ({
  title,
  placement = "bottom",
  renderButton,
  arrow = false,
  isShowTitle = true,
}) => {
  return (
    <Tooltip
      title={isShowTitle ? title : ""}
      placement={placement}
      arrow={arrow}
    >
      <span>{renderButton()}</span>
    </Tooltip>
  );
};

export default TooltipedButton;
