export const filterId = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.id?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};
export const filterName = (keyword, arr) => {
  const results = arr.filter((campaign) => {
    return campaign.name?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};
export const filterSite = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.site?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};
export const filterEmail = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.campaign_creator_email?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};
export const filterDate = (oDate, arr) => {
  const tempDate = {
    date_end: oDate.date_end || new Date(3000, 10, 10),
    date_start: oDate.date_start || new Date(3, 10, 10),
  };
  const result = arr.filter((user) => {
    if (!oDate.date_start && !oDate.date_end) return true;

    const date = new Date(user?.created_at);
    const isInside = tempDate.date_start <= date && tempDate.date_end >= date;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterType = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.target_type === keyword;
  });
  return results;
};
export const filterDay = (range, arr) => {
  const tempRange = {
    range_end: range.end || 10000000,
    range_start: range.start || 0,
  };
  const result = arr.filter((user) => {
    if (!range.start && !range.end) return true;
    const isInside =
      tempRange.range_start <= user.daily_budget &&
      tempRange.range_end >= user.daily_budget;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterFull = (range, arr) => {
  const tempRange = {
    range_end: range.end || 10000000,
    range_start: range.start || 0,
  };
  const result = arr.filter((user) => {
    if (!range.start && !range.end) return true;
    const isInside =
      tempRange.range_start <= user.full_budget &&
      tempRange.range_end >= user.full_budget;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterBet = (range, arr) => {
  const tempRange = {
    range_end: range.end || 10000000,
    range_start: range.start || 0,
  };
  const result = arr.filter((user) => {
    if (!range.start && !range.end) return true;
    const isInside =
      tempRange.range_start <= user.bet && tempRange.range_end >= user.bet;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterRange = (oDate, arr) => {
  const tempDate = {
    date_end: oDate.date_end || new Date(3000, 10, 10),
    date_start: oDate.date_start || new Date(3, 10, 10),
  };
  const result = arr.filter((user) => {
    if (!oDate.date_start && !oDate.date_end) return true;

    const date = new Date(user?.created_at);
    const isInside = tempDate.date_start <= date && tempDate.date_end >= date;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterDecrease = (range, arr) => {
  const tempRange = {
    range_end: range.end || 10000000,
    range_start: range.start || 0,
  };
  const result = arr.filter((user) => {
    if (!range.start && !range.end) return true;
    const isInside =
      tempRange.range_start <= user.decrease &&
      tempRange.range_end >= user.decrease;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterStatus = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.status.toLowerCase() === keyword.toLowerCase();
  });
  return results;
};
export const sortCampaings = (type, arr) => {
  switch (type) {
    case "created_at":
      return [...arr].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    case "created_at asc":
      return [...arr].sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    case "daily_budget":
      return [...arr].sort((a, b) => b.daily_budget - a.daily_budget);
    case "daily_budget asc":
      return [...arr].sort((a, b) => a.daily_budget - b.daily_budget);
    case "full_budget":
      return [...arr].sort((a, b) => b.full_budget - a.full_budget);
    case "full_budget asc":
      return [...arr].sort((a, b) => a.full_budget - b.full_budget);
    default:
      return arr;
  }
};

export const INIT_FILTER_VALUE = {
  created_at: {
    date_end: "",
    date_start: "",
  },
  range: {
    date_end: "",
    date_start: "",
  },
  full_budget: {
    start: "",
    end: "",
  },
  daily_budget: {
    start: "",
    end: "",
  },
  decrease: {
    start: "",
    end: "",
  },
  type: "",
  status: "",
  site: "",
  campaign_creator_email: "",
  id: "",
  name: "",
  bet: "",
  sort: "",
};
