import "./SignIn.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { client } from "../../utils/network";
import useUser from "../../data/useUser";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { StylesProvider } from "@material-ui/core/styles";

import { Button, Checkbox, Typography } from "../../ui";

import { ReactComponent as LogoWhiteIcon } from "../../assets/images/logo-white.svg";
import * as Yup from "yup";
import FormWithText from "../../widgets/FormWithText/FormWithText";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/user/actions";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import EmailModal from "../../widgets/EmailModal/EmailModal";


const saveDataInLocalStorage = (isSave) => {
  return localStorage.setItem(
    "programmatic_save_password",
    isSave ? "true" : "false"
  );
};

const setButtonsDisabled = (disabled) => {
  for (let button of document.getElementsByClassName('Button')) {
    button.disabled = disabled;
  }
}

function SignIn() {
  const [searchParams] = useSearchParams();
  const success = searchParams.get('success');
  const err = searchParams.get('err');
  const email = searchParams.get('email');
  const [open, setOpen] = useState(false)
  const navigate = useNavigate();
  const { mutate: userMutate } = useUser();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    setErrors,
    handleBlur,
  } = useFormik({
    validationSchema: Yup.object().shape({
      username: Yup.string().trim()
        .matches(/^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i, "Введите корректный Email")
        .required("Пожалуйста, введите ваш Email"),
      password: Yup.string().required("Это обязательное поле"),
    }),
    initialValues: {
      username: "",
      password: "",
      isSavePassword: true,
    },
    onSubmit: async (values) => {
      setButtonsDisabled(true);
      try {
        const params = new URLSearchParams();
        params.append("username", values.username.trim());
        params.append("password", values.password);
        const { data } = await client.post("/api/v1/auth/access-token", params);
        saveDataInLocalStorage(values.isSavePassword);
        localStorage.setItem("token", data.access_token);
        const user = await userMutate();
        dispatch(setUserData(user));
        navigate("/");
      } catch (error) {
        if (error.message === '{"detail":"Некорректный никнейм или пароль"}') {
          setErrors({ ...errors, password: "Неверный Email или пароль" });
        } else {
          setErrors({ ...errors, response: error.message });
        }
      }
      setButtonsDisabled(false);
    },
  });
  const handleInput = (e) => {
    handleChange(e);
    setErrors({});
  };
  const handleChangeCheckbox = () => {
    setFieldValue("isSavePassword", !values.isSavePassword);
    setErrors({});
  };

  useEffect(()=>{
    if(success) {
      enqueueSnackbar("Регистрация успешно подтверждена", { variant: "success", 
        anchorOrigin:{ vertical: "top", horizontal: "right" }
      });
    }
  },[])
  useEffect(()=>{
    if(err) setOpen(true)
  },[])

  return (
    <div className="SignIn">
      <div className="images">
        <Link to="/">
          <LogoWhiteIcon className="logo" />
        </Link>

        <div className="greetings">
          <Typography className="greetings-title" variant="h1" color="white">
            Приветствуем Вас!
          </Typography>
          <Typography variant="h1" color="white">
            Крупнейшая programmatic-экосистема в Рунете
          </Typography>
        </div>
      </div>
      <form className="wrapp" onSubmit={handleSubmit}>
        <div className="form">
          <Typography className="form-title" variant="h1" color="dark-gray">
            Войти
          </Typography>
          <FormWithText>
            <div className="validate_input">
              <input
                id="username"
                name="username"
                className={errors.username && touched.username ? "email-field err" : "email-field"}
                type="text"
                placeholder="Email"
                value={values.username.trim() || ""}
                onChange={handleInput}
                autoComplete="off"
                onBlur={handleBlur}
              />
              {touched.username && errors.username ? (
                  <div className="error_text">{errors.username}</div>
                ) : null}
            </div>
            <StylesProvider injectFirst>
            <div className="validate_input">
              <Input
                  disableUnderline={true}
                  id="password"
                  name="password"
                  className={errors.password && touched.password ? "password-field err" : "password-field"}
                  autoComplete="off"
                  type={showPassword ? "text" : "password"}
                  placeholder="Пароль"
                  value={values.password}
                  onChange={handleInput}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              {touched.password && errors.password ? (
                  <div className="error_text">{errors.password}</div>
                ) : null}
              </div>
            </StylesProvider>
            <div className="save-password">
              <div
                className="remember unselectable"
                onClick={handleChangeCheckbox}
              >
                <Checkbox
                  onChange={handleChangeCheckbox}
                  value={values.isSavePassword}
                />
                <Typography variant="body1" color="dark-gray">
                  запомнить пароль
                </Typography>
              </div>
              <Link to="/password-recovery">
                <div className="forgot">Забыли пароль?</div>
              </Link>
            </div>
          </FormWithText>

          <div className="btn-actions">
            <Button type="submit" variant="primary" className="primary">
              Войти
            </Button>
            <Link to="/sign-up">
              <Button variant="secondary">Зарегистрироваться</Button>
            </Link>
          </div>
        </div>
      </form>
      <EmailModal open={open} handleClose={()=> setOpen(false)} err={err} email={email} />
    </div>
  );
}

export default SignIn;
