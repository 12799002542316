import { CircularProgress, Dialog, IconButton, Stack } from "@mui/material";
import { Typography } from "../../ui";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { countersSelector } from "../../redux/counters/selectors";
import LoadingBox from "../../ui/LoadingBox/LoadingBox";
import CreateCounterForm from "./CreateCounterForm";

const CounterDialog = ({ setActive, open }) => {
  const handleClose = () => setActive(false);
  const { isLoading } = useSelector(countersSelector.all_object);
  // if (<CreateCounterForm /> === null) return null;
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      {isLoading && (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      )}
      <Stack padding={(window.outerWidth > 1000) ? "40px" : "10px"} overflow="hidden">
        <Stack
          direction="row"
          justifyContent="space-between"
          marginBottom={(window.outerWidth > 1000) ? "40px" : "10px"}
          alignItems="center"
        >
          <Typography variant="h2">Добавление счетчика</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <CreateCounterForm setOpen={setActive} />
        {/* {{ ...component, props: { ...component.props, setActive } }} */}
      </Stack>
    </Dialog>
  );
};

export default CounterDialog;
