import { ACTIONS } from "./actionTypes";
import {
  filterCompanyOnDate,
  filterWithCustomSign,
  MAIN_INDICATORS,
  sortCampaigns,
  filterCampaignsOnStatus,
} from "./helper";

const initFilter = {
  search: "",
  campaigns: [],
  media_plan: {
    date_end: "",
    date_start: "",
  },
  budget: "",
  budgetSort: ">",
  rate: "",
  rateSort: ">",
  sortedType: "created_at",
  filterStatus: [],
  settingShow: MAIN_INDICATORS,
};

const initialState = {
  campaigns: [],
  filteredCampaigns: [],
  filterValue: initFilter,
};

export const campaignsInfoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.INIT_CAMPAIGNS:
      return {
        ...state,
        filterValue: initFilter,
        campaigns: payload,
        filteredCampaigns: payload,
      };
    case ACTIONS.CHANGE_FILTER_VALUE:
      return {
        ...state,
        filterValue: { ...state.filterValue, [payload.name]: payload.value },
      };
    case ACTIONS.CHANGE_FILTERED_CAMPAIGNS: {
      const suitableCampaigns = Object.entries(state.filterValue).reduce(
        (acc, [key, value]) => {
          switch (key) {
            case "search":
              return acc.filter((company) =>
                company.basic_config.name
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            case "budget":
              if (value === "") return acc;
              return acc.filter((company) =>
                filterWithCustomSign(
                  company.campaign_stat?.budget?.full_budget,
                  +value,
                  state.filterValue.budgetSort
                )
              );
            case "rate":
              if (value === "") return acc;
              return acc.filter((company) =>
                filterWithCustomSign(
                  company.campaign_stat?.budget?.avg,
                  +value,
                  state.filterValue.rateSort
                )
              );
            case "campaigns":
              if (!value.length) return acc;
              return acc.filter((company) =>
                value.includes(company.basic_config.id)
              );
            case "sortedType":
              return sortCampaigns(acc, value);
            case "media_plan":
              return filterCompanyOnDate(acc, value);
            case "filterStatus":
              return filterCampaignsOnStatus(acc, value);
            default:
              return acc;
          }
        },
        [...state.campaigns]
      );
      return { ...state, filteredCampaigns: suitableCampaigns };
    }
    case ACTIONS.CHANGE_SETTINGS_SHOW:
      return {
        ...state,
        filterValue: {
          ...state.filterValue,
          settingShow: state.filterValue.settingShow.map((item) => ({
            ...item,
            value: payload[item.name] ?? false,
          })),
        },
      };
    default:
      return state;
  }
};
