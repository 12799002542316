import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./BankAudits.scss";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

import {
  getUserInfo,
  getUserRequisites,
  setUserRequisites,
} from "../../redux/user/actions";
import { Button, Typography } from "../../ui";
import { userSelectors } from "../../redux/user/selectors";
import { useSnackbar } from "notistack";
import { API_HOST } from "../../configs/main";

const CreateBankAuditSchema = Yup.object().shape({
  full_name:Yup.string().max(300).required(),
  short_name:Yup.string().max(300).required(),
  ur_address:Yup.string().max(300).required(),
  mail_address:Yup.string().max(300).required(),
  inn_or_kpp: Yup.string().matches(/^(\d{10}|\d{12})\/(\d{9})?$/)
    .test(
      'inn_or_kpp',
      '1',
      function (value) {
        const orgnLength = this.parent.orgn ? this.parent.orgn.length : 0;
        const innLength = value ? value.split('/')[0].length : 0;

        return (innLength === 12 && orgnLength === 15 
               || innLength === 10 && orgnLength === 13);
      }
    ).required(),

  orgn: Yup.string().matches(/^(\d{13}|\d{15})$/)
    .test(
      'orgn',
      '1',
      function (value) {
        const innLength = this.parent.inn_or_kpp ? this.parent.inn_or_kpp.split('/')[0].length : 0;
        const orgnLength = value ? value.length : 0;

        return (innLength === 10 && orgnLength === 13
                || innLength === 12 && orgnLength === 15);
      }
    ).required(),
  director:Yup.string().max(300).required(),
  bank_name:Yup.string().max(300).required(),
  bank_bik:Yup.string().matches(/\d{9}/).length(9).required(),
  correspondent_account:Yup.string().matches(/\d{20}/).length(20).required(),
  payment_account:Yup.string().matches(/\d{20}/).length(20).required(),
  okvad:Yup.string().max(9).matches(/^[0-9.]*$/).required(),
})

const BankAudits = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    full_name,
    short_name,
    ur_address,
    mail_address,
    inn_or_kpp,
    orgn,
    director,
    bank_name,
    bank_bik,
    correspondent_account,
    payment_account,
    okvad,
    email,
    phone_number,
    is_entity,
    user,
  } = useSelector(userSelectors.all_assign_properties);
  useEffect(() => {
    dispatch(getUserRequisites());
    dispatch(getUserInfo());
  }, []);

  const { values, handleChange, errors, handleSubmit } = useFormik({
    initialValues: {
      full_name,
      short_name,
      ur_address,
      mail_address,
      inn_or_kpp,
      orgn,
      director,
      bank_name,
      bank_bik,
      correspondent_account,
      payment_account,
      okvad,
      email,
      phone_number,
    },
    enableReinitialize: true,
    onSubmit:(values) => {
      if (full_name) {
        enqueueSnackbar("Данные нельзя редактировать. Свяжитесь с поддержкой", { variant: "error" });
      }
      else if (window.confirm("Убедитесь, что все поля заполнены верно. Вы больше не сможете редактировать банковские реквизиты")) {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem("token"));
        fetch(`${API_HOST}/api/v1/me/requisites`,{
          method:'PUT',
          headers:myHeaders,
          body:JSON.stringify({
            ...values,
            inn_or_kpp: values.inn_or_kpp ? values.inn_or_kpp : 0,
            orgn: values.orgn ? values.orgn : 0,
            bank_bik: values.bank_bik ? values.bank_bik : 0,
            correspondent_account: values.correspondent_account
              ? values.correspondent_account
              : 0,
            payment_account: values.payment_account ? values.payment_account : 0,
          })
        }).then((res) => {
          if(res.ok){
            dispatch(setUserRequisites(res.data))
            enqueueSnackbar("Информация сохранена", { variant: "success" });
          }else{
            enqueueSnackbar('Ошибка', { variant: "error" })
          }
        })
      // dispatch(
      //     updateUserRequisites({
      //       ...values,
      //       inn_or_kpp: values.inn_or_kpp ? values.inn_or_kpp : 0,
      //       orgn: values.orgn ? values.orgn : 0,
      //       bank_bik: values.bank_bik ? values.bank_bik : 0,
      //       correspondent_account: values.correspondent_account
      //         ? values.correspondent_account
      //         : 0,
      //       payment_account: values.payment_account ? values.payment_account : 0,
      //     })
      //   );
      //   enqueueSnackbar("Информация сохранена", { variant: "success" });
        if ("/balance".endsWith(document.referrer.slice(-8))) {
          window.location.href = '../../balance';
        }
      }
      // dispatch(
      //   updateUserInfo({
      //     email: values.email,
      //     profile: {
      //       phone_number,
      //     },
      //   })
      // );
    },
    validationSchema: CreateBankAuditSchema,
  });

  return (
    (user && !is_entity && is_entity !== undefined && (
      <div className="BankAudits">
        <form onSubmit={handleSubmit}>
          <Typography variant="h1" color="dark-gray">
            Ваши Банковские реквизиты Физ лица
          </Typography>
        </form>
      </div>
    )) ||
    (user && (
      <div className="BankAudits">
        <form onSubmit={handleSubmit}>
          <Typography variant="h1" color="dark-gray">
            Ваши Банковские реквизиты
          </Typography>
          <div className="rows">
            <div className="row">
              <Typography variant="h2" color="dark-gray">
                Общие данные
              </Typography>
              <div className="items">
                <FormControl className="input-data">
                  <TextField
                    name="full_name"
                    type="text"
                    label="Полное Наименование"
                    onChange={handleChange}
                    error={errors?.full_name}
                    helperText={values.full_name?.length>300 ? "Значение должно быть короче 300 символов" : 
                    /[^a-zA-Z0-9а-яА-Я\s"\\/'.,_-]/.test(values.full_name) ? "Встречены недопустимые символы" : <br/>}
                    value={values.full_name}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="short_name"
                    label="Сокращенное наименование"
                    onChange={handleChange}
                    value={values.short_name}
                    error={errors?.short_name}
                    helperText={values.short_name?.length>300 ? "Значение должно быть короче 300 символов" : 
                    /[^a-zA-Z0-9а-яА-Я\s"\\/'.,_-]/.test(values.short_name) ? "Встречены недопустимые символы" : <br/>}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="ur_address"
                    label="Юридический адрес"
                    onChange={handleChange}
                    value={values.ur_address}
                    error={errors?.ur_address}
                    helperText={values.ur_address?.length>300 ? "Значение должно быть короче 300 символов" : 
                    /[^a-zA-Z0-9а-яА-Я\s"\\/'.,_-]/.test(values.ur_address) ? "Встречены недопустимые символы" : <br/>}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="mail_address"
                    label="Почтовый адрес"
                    onChange={handleChange}
                    value={values.mail_address}
                    error={errors?.mail_address}
                    helperText={values.mail_address?.length>300 ? "Значение должно быть короче 300 символов" : 
                    /[^a-zA-Z0-9а-яА-Я\s"\\/'.,_-]/.test(values.mail_address) ? "Встречены недопустимые символы" : <br/>}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
              </div>
            </div>

            <div className="row">
              <Typography variant="h2" color="dark-gray">
                Банковские реквизиты
              </Typography>
              <div className="items">
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="inn_or_kpp"
                    label="ИНН / КПП"
                    error={errors.inn_or_kpp}
                    helperText={errors.inn_or_kpp === "1" ? "Неверная комбинация ИНН и ОГРН." : errors.inn_or_kpp ? "Значение ИНН и КПП через слэш" : <br/>}
                    onChange={handleChange}
                    value={values.inn_or_kpp}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="orgn"
                    label="ОГРН"
                    onChange={handleChange}
                    value={values.orgn}
                    error={errors?.orgn}
                    helperText={errors.orgn === "1" ? "Неверная комбинация ОГРН и ИНН." : errors?.orgn ? "Значение должно быть ОГРН" : <br/>}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="director"
                    label="Директор"
                    onChange={handleChange}
                    error={errors?.director}
                    helperText={values.director?.length>300 ? "Значение должно быть короче 300 символов" : 
                    /[^a-zA-Z0-9а-яА-Я\s'.-]/.test(values.director) ? "Встречены недопустимые символы" : <br/>}
                    value={values.director}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="bank_name"
                    label="Наименование банка"
                    onChange={handleChange}
                    value={values.bank_name}
                    error={errors?.bank_name}
                    helperText={values.bank_name?.length>300 ? "Значение должно быть короче 300 символов" : 
                    /[^a-zA-Z0-9а-яА-Я\s"\\/'.,_-]/.test(values.bank_name) ? "Встречены недопустимые символы" : <br/>}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="bank_bik"
                    label="БИК"
                    onChange={handleChange}
                    value={values.bank_bik}
                    error={errors?.bank_bik}
                    helperText={errors?.bank_bik ? "Значение должно быть БИК " : 
                    (values.bank_bik!=='' && values.bank_bik?.length>9) ? "Значение должно быть ровно 9 символов" : <br />}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="correspondent_account"
                    label="Корреспондентский счет"
                    onChange={handleChange}
                    value={values.correspondent_account}
                    error={errors?.correspondent_account}
                    helperText={errors?.correspondent_account ? 
                    "Значение должно быть корреспондентский счет" : (values.correspondent_account!=='' && values.correspondent_account?.length>20) ? 
                    "Значение должно быть ровно 20 символов" : <br />}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="payment_account"
                    label="Расчетный счет"
                    onChange={handleChange}
                    value={values.payment_account}
                    error={errors?.payment_account}
                    helperText={errors?.payment_account ? 
                    "Значение должно быть расчетный счет"  : (values.payment_account!=='' && values.payment_account?.length>20) ? 
                    "Значение должно быть ровно 20 символов" : <br />}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
                <FormControl className="input-data">
                  <TextField
                    type="text"
                    name="okvad"
                    label="ОКВЭД"
                    onChange={handleChange}
                    value={values.okvad}
                    error={errors?.okvad}
                    helperText={values.okvad?.length>9 ? "Значение должно быть короче 9 символов" 
                                : errors?.okvad ? "Значение должно быть ОКВЭД" : <br />}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <div className="actions">
            <Button type="submit" variant="primary">
              Сохранить
            </Button>
          </div>
        </form>
      </div>
    ))
  );
};

export default BankAudits;
