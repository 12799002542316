import { Modal, Typography, MenuItem } from "@material-ui/core";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { API_HOST } from "../../../configs/main";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// const FORMIK_INITIAL_VALUES = {
//   status: "",
// };

const CreateSegmentSchema = Yup.object().shape({
  status: Yup.string().required(),
});

function EditStatusModal({ modal, handleCloseModal, campaing,upd }) {
  const [confirm, setConfirm] = useState(false);
  const formik = useFormik({
    initialValues: {
      status: "",
    },
    validationSchema: CreateSegmentSchema,
    onSubmit: async (values) => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/campaigns/${campaing?.id}/change_status`, {
        method: "put",
        headers: myHeaders,
        body: JSON.stringify({
          broadcast_status: values.status,
        }),
      });
      if (res.ok) {
        formik.setValues({
          status: "",
        });
        handleCloseModal();
        setConfirm(false);
        upd()
      }
    },
  });

  useEffect(() => {
    campaing &&
      formik.setValues({
        status: campaing?.status,
      });
  }, [campaing]);

  return (
    <Modal
      disablePortal={true}
      open={modal}
      onClose={() => {
        setConfirm(false);
        handleCloseModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Изменить статус кампании
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{
            marginBottom: 20,
            marginTop: 20,
            color: "#42454C",
            opacity: "70%",
          }}
        >
          Кампания: {campaing?.name}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">Статус</InputLabel>
              <Select
                value={formik.values?.status}
                onChange={(e) => formik.setFieldValue("status", e.target.value)}
                style={{ height: 56 }}
                id="demo-select-small-label"
                label="Статус"
              >
                <MenuItem value={"DELIVERING"}>Работает</MenuItem>
                <MenuItem value={"PENDING"}>На модерации</MenuItem>
                {/* <MenuItem value={"NOT_DELIVERING"}>Не работает</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
          {!confirm && (
            <div onClick={() => setConfirm(true)} className="editBtn">
              <span>Изменить</span>
            </div>
          )}
          {confirm && (
            <div className="btnGroup">
              <div
                className="resetBtn"
                onClick={() => {
                  handleCloseModal();
                  formik.setValues({ status: "" });
                }}
              >
                <span>Отменить</span>
              </div>
              <button className="confirm_Btn" type="submit">
                <span>Подтвердить</span>
              </button>
            </div>
          )}
        </form>
      </Box>
    </Modal>
  );
}

export default EditStatusModal;
