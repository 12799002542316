import {createStore, applyMiddleware} from 'redux';
import logger from 'redux-logger'
import reduxThunk from 'redux-thunk'
import rootReducer from './rootReducers'
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [reduxThunk]

if(process.env.NODE_ENV === 'development'){
  middlewares.push(logger)
}

const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(...middlewares),
  // other store enhancers if any
));

export default store
