import "./rangeDaysPicker.scss";

export const transformDate = (date) => {
  if (!date) return "";
  const options = { weekday: "short" };
  return date.toLocaleDateString("ru", options);
};
const styles = {
  "00": "",
  "01": "",
  10: "active_weekend",
  11: "active_work",
};
const OneDay = ({ arrHours, dayDate, handleClick, activeDays }) => {
  const dayNumber = dayDate.getDay();
  const type = dayNumber > 0 && dayNumber < 6 ? "work" : "weekend";
  const isActive = activeDays.includes(dayNumber);
  const dayClassName = isActive
    ? `date_day active ${type}`
    : `date_day ${type}`;
  const additionalCls = dayNumber === 1 ? "monday" : "";
  return (
    <div className={`one_day ${additionalCls}`}>
      <div className={dayClassName} onClick={(e) => handleClick(e, dayDate)}>
        {transformDate(dayDate)}
      </div>
      {arrHours
        .map(({ date, active }, index) => {
          const numType = type === "work" ? 1 : 0;
          const activeAndType = [active, numType]
            .map((item) => String(Number(item)))
            .join("");
          const selectClasses = `hour ${styles[activeAndType]}`;
          return (
            <div
              key={index}
              className={selectClasses}
              onClick={(e) => handleClick(e, dayDate, date)}
              data-date={date}
              data-type={type}
              data-hour={date.getHours()}
            ></div>
          );
        })}
    </div>
  );
};

export default OneDay;
