import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import useStatistics from  "../../data/useStatistics";
import { Typography } from "../../ui";
import {
  Card, CardDashedGraph
} from "../../widgets";
import { MetricsStatistics } from "../../widgets/MetricsStatistics";
import { StatisticsSort } from "../../widgets/Sort";
import "./Statistics.scss";
import { useLocation } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

import {API_HOST} from "../../configs/main";


const AffinityBarChart = ({ counter_id, date_from, date_to }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_HOST}/api/v1/ya-metrika/segments?counter_id=${counter_id}&date_from=${new Date(date_from).toISOString().slice(0, 10)}&date_to=${new Date(date_to).toISOString().slice(0, 10)}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        const data = await response.json();
        setData(processData(data));
      } catch (error) {
        console.error("Error fetching data:", error);
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    if (counter_id && date_from && date_to) {
      fetchData();
    } else {
      setData(null);
    }
  }, [counter_id, date_from, date_to]);

  const processData = (rawData) => {
    if (!rawData || Object.keys(rawData).length === 0) return [];
    
    const sorted = Object.entries(rawData)
      .map(([name, values]) => ({ 
        name, 
        users: values.users, 
        affinity: values.affinity,
        originalAffinity: values.affinity
      }))
      .sort((a, b) => b.affinity - a.affinity);

    const top5 = sorted.slice(0, 5);
    const bottom5 = sorted.slice(-5).reverse();

    const maxTopValue = Math.max(...top5.map(item => item.affinity));
    
    const scaledBottom5 = bottom5.map(item => ({
      ...item,
      affinity: -item.affinity * (maxTopValue / Math.abs(item.affinity)) * 0.8
    }));

    return [...top5, ...scaledBottom5];
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ 
          backgroundColor: 'rgba(0, 0, 0, 0.8)', 
          padding: '10px', 
          borderRadius: '5px', 
          color: 'white',
          fontSize: '14px'
        }}>
          <p><strong>{payload[0].payload.name}</strong></p>
          <p>Пользователи: {payload[0].payload.users}</p>
          <p>Афинити индекс: {Math.abs(payload[0].payload.originalAffinity || payload[0].payload.affinity)}%</p>
        </div>
      );
    }
    return null;
  };

  if (!counter_id) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '500px',
        color: '#666',
        fontSize: '16px'
      }}>
        Выберите счётчик
      </div>
    );
  }

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '500px',
        color: '#666',
        fontSize: '16px'
      }}>
        Загрузка данных...
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '500px',
        color: '#666',
        fontSize: '18px'
      }}>
        Нет данных для отображения
      </div>
    );
  }

  const maxAffinity = Math.max(...data.map(item => Math.abs(item.affinity)));
  const padding = maxAffinity * 0.1;

  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart 
        layout="vertical" 
        data={data} 
        margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
        barCategoryGap={10}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
        <XAxis 
          type="number" 
          domain={[-(maxAffinity + padding), maxAffinity + padding]}
          tick={false}
          axisLine={false}
        />
        <YAxis 
          dataKey="name" 
          type="category" 
          width={200} 
          tick={{ fontSize: 14 }}
          padding={{ top: 10, bottom: 10 }}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip content={<CustomTooltip />} />
        <ReferenceLine x={0} stroke="#999" />
        <Bar 
          dataKey="affinity" 
          fill="#3f73f9" 
          name="Афинити индекс"
          radius={[0, 4, 4, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};



const FORMIK_INITIAL_VALUES = {
  selected_campaigns: [],
  selected_goals: [],
  metric_counter: null,
  media_plan: {
    date_start: null,
    date_end: null,
  },
}

const FORMIK_INITIAL_VALUES_DATA = (camp, metric) => {
  return {
    selected_campaigns: [camp],
    selected_goals: [],
    metric_counter: metric,
    media_plan: {
      date_start: null,
      date_end: null,
    },
  }
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};






function Statistics() {
  const location = useLocation();
  const [err, setErr] = useState(false)
  const [modal, setModal] = useState(false)
  let campaign_id = location?.state?.campaign_id;
  let counter_id = location?.state?.counter_id;
  const [formikValues, setFormikValues] = useState(FORMIK_INITIAL_VALUES);
  let type = "day" 
  if ((formikValues.media_plan.date_end-formikValues.media_plan.date_start)/(1000 * 60 * 60 * 24) < 1) type = "hour"
  // if ((formikValues.media_plan.date_end-formikValues.media_plan.date_start)/(1000 * 60 * 60 * 24) > 31) type="week"
  
  let {data, error} = useStatistics(formikValues.metric_counter,formikValues.media_plan.date_start,
      formikValues.media_plan.date_end,formikValues.selected_campaigns,formikValues.selected_goals,type);
  useEffect(()=>{
    if(error && String(error).includes('Error: {"detail":"date_from не может быть раньше текущей даты более чем на 12 месяцев"}')){
      setErr(true)
    }else{
      setErr(false)
    }
  },[error])
  useEffect(()=>{
    if (err) {
      setModal(true)
  }
  },[err])
  if (!data?.metrics) {
    data = {
      "metrics": ["shows", "reach", "clicks", "leads", "spent", "ctr", "cpc", "ecpm", "cr", "cpa"],
      "time_intervals": [],
      "data": [],
      "totals": [0, 0, 0, 0, 0, 0, 0, 0, null, null]
    }
  }
  
  // console.log("updated", formikValues)
  return (
    <>
    <Formik
      initialValues={(campaign_id) ? FORMIK_INITIAL_VALUES_DATA(campaign_id, counter_id) : FORMIK_INITIAL_VALUES}
      onSubmit={(value) => {
        console.log('export') || console.log(value)
      }}
      innerRef={formikAction => {
        if (!formikAction) {
          return;
        }
        const { values } = formikAction;
        if (
          values.selected_campaigns.length === formikValues.selected_campaigns.length
          && values.selected_campaigns.every(
            (value, index) => value === formikValues.selected_campaigns[index]
          )
          && values.media_plan.date_start === formikValues.media_plan.date_start
          && values.media_plan.date_end === formikValues.media_plan.date_end
          && values.metric_counter === formikValues.metric_counter
          && values.selected_goals.length === formikValues.selected_goals.length
          && values.selected_goals.every(
            (value, index) => value === formikValues.selected_goals[index]
          )
        ) {
          return;
        }
        setFormikValues(formikAction.values);
      }}
    >
      <Form>
        <div className="Statistics">
          <Typography variant="h1" color="dark-gray">
            Статистика
          </Typography>
          <StatisticsSort />
          <div style={{ marginTop: "10px", marginBottom: "10px" }} className="line"></div>
          <div style={{ marginBottom: "20px"}} className="container">
          <MetricsStatistics data={data?.totals} />
            {/* <InterestsStatistics data={InterestData} />
            <Devices data={fiterdata2} />
            <Geography data={fiterdata2} /> */}
          </div>
          <div style={{ marginBottom: "20px", display: "flex", gap: "20px", width: "100%"}}>
            <div className="CardWhite" style={{ width: "50%" }}>
              <Typography variant="h2" color="dark-gray">
                Интересы
              </Typography>
              <AffinityBarChart counter_id={formikValues.metric_counter} date_from={formikValues.media_plan.date_start} date_to={formikValues.media_plan.date_end} />
            </div>
          </div>
          <Card>
            <CardDashedGraph title type={type} chartData={data} budget={data.totals} />
          </Card>


          {/* <div style={{ marginTop: "50px"s, display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "100%"}}>
            <svg style={{opacity: ".7", width: "120px", height: "120px"}} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#42454c" viewBox="0 0 256 256"><path d="M160,42A86.11,86.11,0,0,0,82.43,90.88,62,62,0,1,0,72,214h88a86,86,0,0,0,0-172Zm0,160H72a50,50,0,0,1,0-100,50.67,50.67,0,0,1,5.91.35A85.61,85.61,0,0,0,74,128a6,6,0,0,0,12,0,74,74,0,1,1,74,74Zm-6-74V88a6,6,0,0,1,12,0v40a6,6,0,0,1-12,0Zm16,36a10,10,0,1,1-10-10A10,10,0,0,1,170,164Z"></path></svg>
            <p style={{fontSize: "20px", textAlign: "center", width: "100%", opacity: ".7"}}>Статистика временно недоступна.</p>
          </div> */}


        </div>
      </Form>
    </Formik>
    <Modal open={modal} onClose={() => setModal(false)}>
      <Box sx={style}>
        <h4 style={{width:"100%", textAlign:'center'}}>Cтатистика за более чем 12 месяца не доступна</h4>
      <Close onClick={() => setModal(false)} style={{position:'absolute', top:10,right:10, cursor:'pointer'}} />
      </Box>
    </Modal>
    </>
  );
}

export default Statistics;