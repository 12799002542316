import dateHelper from "../../utils/dateHelper";

export const createWeekDate = () => {
  const result = [];
  const today = new Date();
  let startWeek = dateHelper.getMondayDate(new Date(2022, 0, 4));
  const currentYear = today.getFullYear();
  while (startWeek.getFullYear() === currentYear) {
    result.push(startWeek.toLocaleDateString("ru-RU"));
    startWeek = dateHelper.plusDays(startWeek, 7);
  }
  return result;
};

export const createAllYearData = () => {
  return createWeekDate().map((week) => {
    const sign = [1, -1][Math.floor(Math.random() * 2)];
    return {
      date: week,
      base:{
        clicks: 2400 + sign * Math.floor(Math.random() * 2400),
        shows: 600 + sign * Math.floor(Math.random() * 600),
        ctr: 10 + sign * Math.floor(Math.random() * 10),
        spent: 5 + sign * Math.floor(Math.random() * 5),
        cpc: 20 + sign * Math.floor(Math.random() * 20),
        cpm: 1500 + sign * Math.floor(Math.random() * 1500),
        cpa: 1500 + sign * Math.floor(Math.random() * 1500),
      }
    };
  });
};

export const setFilterData = (date, ALL_YEAR_DATA) => {
  if (date.length === 0) return ALL_YEAR_DATA;
  const result = ALL_YEAR_DATA?.filter((item) => {
    const start = date[0] ?? new Date(3, 0, 3);
    const end = date[1] ?? new Date(3000, 0, 3);
    const [day, month, year] = item.date.split(".");

    const itemDate = new Date([month, day, year].join("."));
    return itemDate >= start && itemDate <= end;
  });
  return result;
};

export const prepareIndicators = (legend) =>
  legend.reduce((acc, cur) => {
    acc[cur.key] = cur.active;
    return acc;
  }, {});
