import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import useCampaigns from "../../data/useCampaigns";
import  useUser from  "../../data/useUser";
import { BalanceHistoryTable } from "../../widgets";
import { DatePicker } from "../../widgets";
import { Select } from "../../ui";
import "./BalanceHistory.scss";

const FORMIK_INITIAL_VALUES = {
  selected_campaigns: [],
  media_plan: {
    date_start: null,
    date_end: null,
  },
};

const isBetweenDate = (
  date,
  start,
  finish
) => {
  start = start?.toLocaleDateString('en-CA')
  finish = finish?.toLocaleDateString('en-CA')
  const temp = typeof date === "string" ? new Date(date) : date;
  return temp >= new Date(start) && temp <= new Date(finish);
};


function BalanceHistory() {
  const [formikValues, setFormikValues] = useState(FORMIK_INITIAL_VALUES);
  const { campaigns }= useCampaigns()
  // const { history, isLoading } = usePaymentsHistory();
  const {
    user: {
      user_balance: {transaction_groups},
    },
  } = useUser();

  let campaignsOptions = [];
  let arr = []
  if (campaigns) {
    campaigns?.map((campaign) => 
      arr.push({
      label: campaign?.basic_config?.name,
      value: campaign?.basic_config?.campaign_id,
      selected: formikValues?.selected_campaigns?.some(
        (item) => item === campaign?.basic_config?.campaign_id)
      })
    )
  } else arr.push({
    label: "Нет кампаний",
    value: "0",
    selected: false
  })
  campaignsOptions = arr

  const filteredData = useMemo(() => {
    //if (isNeedToReturnFullValue(formikValues, isLoading)) return history;

    const {
      media_plan: { date_start, date_end },
      selected_campaigns,
    } = formikValues;

    const filteredByDate = transaction_groups.filter((item) =>
      isBetweenDate(item?.created_at.split('T')[0], date_start, date_end)
    );
    if (!selected_campaigns.length) return filteredByDate;
    const filteredByCampaigns = filteredByDate.filter((item) =>
          selected_campaigns.indexOf(item.campaign_id)!==-1
    )
    //console.log(filteredByCampaigns,selected_campaigns)
    return filteredByCampaigns;
    // const filteredByCampaigns = filteredByDate.filter(item => )
  }, [formikValues]);
  return (
    <div className="BalanceHistory">
      <Formik
        initialValues={FORMIK_INITIAL_VALUES}
        onSubmit={(value) => {
          console.log("export") || console.log(value);
        }}
        innerRef={(formikAction) => {
          if (!formikAction) {
            return;
          }
          const { values } = formikAction;
          if (
            values.media_plan.date_start ===
              formikValues.media_plan.date_start &&
            values.media_plan.date_end === formikValues.media_plan.date_end
          ) {
            return;
          }
          values.selected_campaigns = Object.values(formikValues.selected_campaigns)
          setFormikValues(formikAction.values);
        }}
      >
        <Form className="container">
          <DatePicker />
          <Select
            placeholder={"Кампании"}
            options={campaignsOptions}
            onChange={selectedOptions => {
              setFormikValues(prevState => ({ ...prevState, 
                "selected_campaigns": selectedOptions?.map(({ value }) => value)})
              );
            }} 
          />
        </Form>
      </Formik>
      <div className="line"></div>
      <BalanceHistoryTable data={filteredData} />
    </div>
  );
}

export default BalanceHistory;
