export const filterDate = (oDate, arr) => {
  const tempDate = {
    date_end: oDate.date_end || new Date(3000, 10, 10),
    date_start: oDate.date_start || new Date(3, 10, 10),
  };
  const result = arr.filter((pay) => {
    if (!oDate.date_start && !oDate.date_end) return true;

    const date = new Date(pay?.updated_at);
    const isInside = tempDate.date_start <= date && tempDate.date_end >= date;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterTypePayments = (keyword, arr) => {
  const results = arr.filter((payment) => {
    return String(payment.type).toLowerCase().startsWith(keyword.toLowerCase());
  });
  return results;
};
export const filterSum = (range, arr) => {
  const tempRange = {
    range_end: range.end || 10000000,
    range_start: range.start || 0,
  };
  const result = arr.filter((pay) => {
    if (!range.start && !range.end) return true;
    const isInside =
      +tempRange.range_start <= pay.amount &&
      +tempRange.range_end >= pay.amount;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterNumber = (keyword, arr) => {
  const results = arr.filter((payment) => {
    return String(payment.number)
      .toLowerCase()
      .startsWith(keyword.toLowerCase());
  });
  return results;
};
export const filterStatus = (keyword, arr) => {
  const results = arr.filter((payment) => {
    if (keyword === "succeeded") {
      return payment.status === keyword || payment.type === "Ручное пополнение";
    }
    return payment.status === keyword;
  });
  return results;
};
export const filterType = (keyword, arr) => {
  switch (keyword) {
    case "deposit":
      return arr.filter(
        (paymnet) =>
          paymnet.type === "Ручное пополнение" || paymnet.status === "succeeded"
      );
    case "charge":
      return arr.filter((paymnet) => paymnet.type === "Ручное списание");
    case "overdue":
      return arr.filter(
        (paymnet) =>
          paymnet.status !== "succeeded" && paymnet.type !== "Ручное пополнение"
      );
    default:
      return arr;
  }
};
export const filterIdClient = (keyword, arr) => {
  const results = arr.filter((payment) => {
    return String(payment.user_id)
      .toLowerCase()
      .startsWith(keyword.toLowerCase());
  });
  return results;
};
export const sortPay = (type, arr) => {
  switch (type) {
    case "updated_at":
      return [...arr].sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
    case "updated_at asc":
      return [...arr].sort(
        (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
      );
    case "created_at":
      return [...arr].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    case "created_at asc":
      return [...arr].sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    default:
      return arr;
  }
};

export const INIT_FILTER_VALUE = {
  date: {
    date_end: "",
    date_start: "",
  },
  typePayments: "",
  sum: {
    start: "",
    end: "",
  },
  number: "",
  status: "",
  idClient: "",
  type: "",
  sort: "created_at",
};
