import { Box, Modal, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FORMIK_INITIAL_VALUES = {
  status: "",
};

const CreateSegmentSchema = Yup.object().shape({
  status: Yup.string().required(),
});

function EditStatusModal({
  editStatus,
  handleClose,
  confirm,
  setConfirm,
  transactoin,
  handleEditStatus,
}) {
  const formik = useFormik({
    initialValues: FORMIK_INITIAL_VALUES,
    validationSchema: CreateSegmentSchema,
    onSubmit: () => {
      formik.setValues(FORMIK_INITIAL_VALUES);
    },
  });
  return (
    <Modal
      open={editStatus}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Подтвердить оплату счета
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{
            marginBottom: 20,
            marginTop: 20,
            color: "#42454C",
            opacity: "70%",
          }}
        >
          Клиент: {transactoin?.user_id}
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{ marginBottom: 5, marginTop: 20 }}
        >
          Изменить статус счета на Оплачен
        </Typography>
        {!confirm && (
          <div onClick={() => setConfirm(true)} className="editButton">
            <span>Изменить</span>
          </div>
        )}
        {confirm && (
          <div className="buttonGroup">
            <div
              className="resetButton"
              onClick={() => {
                handleClose();
                formik.setFieldValue("status", "");
              }}
            >
              <span>Отменить</span>
            </div>
            <div
              className="confirmButton"
              onClick={() => {
                handleEditStatus(transactoin.id);
              }}
            >
              <span>Подтвердить</span>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default EditStatusModal;
