export const SEG_ACTIONS = {
  CREATE_SEGMENT_REQUEST: "CREATE_SEGMENT_REQUEST",
  CREATE_SEGMENT_SUCCESS: "CREATE_SEGMENT_SUCCESS",
  CREATE_SEGMENT_FAILURE: "CREATE_SEGMENT_FAILURE",
  GET_SEGMENTS_REQUEST: "GET_SEGMENTS_REQUEST",
  GET_SEGMENTS_SUCCESS: "GET_SEGMENTS_SUCCESS",
  GET_SEGMENTS_FAILURE: "GET_SEGMENTS_FAILURE",
  UPDATE_SEGMENT_REQUEST: "UPDATE_SEGMENT_REQUEST",
  UPDATE_SEGMENT_SUCCESS: "UPDATE_SEGMENT_SUCCESS",
  UPDATE_SEGMENT_FAILURE: "UPDATE_SEGMENT_FAILURE",
  CREATE_LIST_REQUEST: "CREATE_LIST_REQUEST",
  CREATE_LIST_SUCCESS: "CREATE_LIST_SUCCESS",
  CREATE_LIST_FAILURE: "CREATE_LIST_FAILURE",
  SET_EDIT_SEGMENT: "SET_EDIT_SEGMENT",
  RESET_EDIT_SEGMENT: "RESET_EDIT_SEGMENT",
};
  