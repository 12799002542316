import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { client } from "../../utils/network";

import "./ContactDetails.scss";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useSnackbar } from "notistack";
import ReactFlagsSelect from "react-flags-select";
import country_phone from "../../modules/SignUp/CountryPhoneList";
import { isValidPhoneNumber, findPhoneNumbersInText } from "libphonenumber-js";

import { updateUserInfo, getUserInfo } from "../../redux/user/actions";
import { inputValueHolder } from "../../utils/helpers";
import { Button, Typography } from "../../ui";
import { textHandlerChange, emailHandlerChange, phoneHandlerChange } from "../../utils/validators";
import * as Yup from "yup";
import { ContentCopy, Edit } from "@mui/icons-material";

const countryNameRecord = [
  "RU", "AL", "DZ", "AS", "AD", "AO", "AI", "AQ", "AG", "AR", "AM", "AW", "AU", 
  "AT", "AZ", "BS", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BO",
  "BA", "BW", "BV", "BR", "IO", "BN", "BG", "BF", "BI", "KH", "CM", "CA", "CV",
  "KY", "CF", "TD", "CL", "CN", "CX", "CC", "CO", "KM", "CG", "CK", "CR", "HR",
  "CU", "CY", "CZ", "DK", "DJ", "DM", "DO", "TP", "EC", "EG", "SV", "GQ", "ER",
  "EE", "ET", "FK", "FO", "FJ", "FI", "FR", "GF", "PF", "TF", "GA", "GM", "GE",
  "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GU", "GT", "GG", "GN", "GW", "GY",
  "HT", "HM", "VA", "HN", "HK", "HU", "IS", "IN", "ID", "IR", "IQ", "IE", "IM",
  "IL", "IT", "CI", "JM", "JP", "JE", "JO", "KZ", "KE", "KI", "KW", "KG", "LA",
  "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO", "MK", "MG", "MW", "MY",
  "MV", "ML", "MT", "MH", "MQ", "MR", "MU", "YT", "MX", "FM", "MD", "MC", "MN",
  "ME", "MS", "MA", "MZ", "MM", "NA", "NR", "NP", "NL", "NC", "NZ", "NI", "NE",
  "NG", "NU", "NF", "KP", "GB", "MP", "NO", "OM", "PK", "PW", "PS", "PA", "PG",
  "PY", "PE", "PH", "PN", "PL", "PT", "PR", "QA", "RE", "RO", "RW", "SH", "AF",
  "KN", "LC", "PM", "VC", "WS", "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SG",
  "SK", "SI", "SB", "SO", "ZA", "GS", "KR", "SS", "ES", "LK", "SD", "SR", "SJ",
  "SZ", "SE", "CH", "SY", "TJ", "TZ", "TH", "CD", "TL", "TG", "TK", "TO", "TT",
  "TN", "TR", "TM", "TC", "TV", "UG", "UA", "AE", "US", "UM", "UY", "UZ", "VU",
  "VE", "VN", "VG", "VI", "WF", "EH", "YE", "ZM", "ZW"
];

const ContactDetails = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [country_code, setCountry] = useState("+7");
  const [selected, setSelected] = useState("RU");
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  useEffect(() => {
    dispatch(getUserInfo());
    if (!country_code) setCountry("+7");
    if (!selected) setSelected("RU");
  }, []);

  useEffect(() => {
    country_phone.data.map((item) =>
      item.code === selected ? setCountry(item.dial_code) : "RU"
    );
  }, [selected]);

  const { firstName, surname, email, phoneNumber, agency, userName, id } =
    useSelector((state) => ({
      firstName: state?.user?.userInfo?.profile?.first_name,
      surname: state?.user?.userInfo?.profile?.surname,
      email: state?.user?.userInfo?.email,
      phoneNumber: state?.user?.userInfo?.profile?.phone_number,
      agency: state?.user?.userInfo?.agency,
      userName: state?.user?.userInfo?.username,
      id: state?.user?.userInfo?.id,
    }));

  const [new_email, setEmail] = useState("");

  useEffect(() => {
    if (phoneNumber) {
      let arr = findPhoneNumbersInText(phoneNumber);
      setSelected(arr[0]?.number?.country ?? "RU");
    }
  }, [phoneNumber]);

  const phoneNumberWithoutCode = () => {
    return String(formik.values.phoneNumber.replace(new RegExp("^\\+?" + country_code.replace("+", "")), ""))
  }

  const formik = useFormik({
    initialValues: {
      email,
      isEntity: true,
      userName,
      agency,
      firstName,
      surname,
      phoneNumber,
      id,
    },
    onSubmit: (values) => {
      if (phoneNumberWithoutCode() && !isValidPhoneNumber(phoneNumberWithoutCode(), selected) || !phoneNumberWithoutCode()) {
        enqueueSnackbar("Поля заполнены неверно", { variant: "error" });
        return
      }
      formik.setFieldValue('phoneNumber', phoneNumberWithoutCode());
      dispatch(
        updateUserInfo({
          username: values.userName,
          agency: values.agency,
          email: values.email,
          is_entity: true,
          profile: {
            first_name: values.firstName,
            surname: values.surname,
            phone_number: country_code + phoneNumberWithoutCode(),
          },
        })
      );
      enqueueSnackbar("Данные сохранены", { variant: "success" });
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required("Это обязательное поле")
        .matches(
          /^[a-zA-Zа-яА-Я\s-]+$/,
          "Допустимы символы русского или латинского алфaвита, пробелы и дефисы"
        ),
      surname: Yup.string()
        .required("Это обязательное поле")
        .matches(
          /^[a-zA-Zа-яА-Я\s-]+$/,
          "Допустимы символы русского или латинского алфaвита, пробелы и дефисы"
        ),
      email: Yup.string()
        .trim()
        .required("Это обязательное поле")
        .matches(
          /^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i,
          "Введен некорректный Email"
        ),
      phoneNumber: Yup.string().required("Это обязательное поле"),
    }),
  });

  const positionText = {
    position: "absolute",
    top: "100%",
  };
  const shortId = () => {
    return id?.slice(0, 8);
  };

  const handleChangeEmail = async () => {
    if (
      new_email !== "" &&
      /^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/i.test(new_email)
    ) {
      try {
        await client
          .post("/api/v1/auth/email/change", {
            new_email: new_email,
          })
          .then((res) => {
            if (res.status === 200) {
              setEmail("");
              setIsOpen(!isOpen);
              setIsConfirm(true);
            }
          });
      } catch (error) {
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
          autoHideDuration: 1000,
        });
      }
    } else if (new_email === "")
      enqueueSnackbar(`Это обязательное поле`, {
        variant: "error",
        autoHideDuration: 1000,
      });
    else
      enqueueSnackbar(`Введен некорректный Email`, {
        variant: "error",
        autoHideDuration: 1000,
      });
  };
  return (
    <div className="ContactDetails">
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h1" color="dark-gray">
          Общая информация
        </Typography>
        <div className="rows">
          <div className="row">
            <Typography variant="h2" color="dark-gray">
              Пользователь
            </Typography>
            <div className="items">
              <FormControl className="input-data">
                <TextField
                  name="firstName"
                  type="text"
                  label="Имя"
                  onChange={(e) => textHandlerChange(formik, e)}
                  value={inputValueHolder(formik, "firstName", firstName)}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </FormControl>
              <FormControl className="input-data">
                <TextField
                  name="surname"
                  type="text"
                  label="Фамилия"
                  onChange={(e) => textHandlerChange(formik, e)}
                  value={inputValueHolder(formik, "surname", surname)}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </FormControl>
              <FormControl
                style={{ display: "flex", flexDirection: "row", width: 127 }}
                className="input-data"
              >
                <TextField
                  style={{ width: 127, border: "none" }}
                  name="userName"
                  type="text"
                  label="ID пользователя"
                  onChange={(e) => {
                    textHandlerChange(formik, e);
                  }}
                  value={shortId()}
                  InputLabelProps={{ shrink: true }}
                  required
                  disabled
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                />
                <ContentCopy
                  onClick={() => {
                    navigator.clipboard.writeText(
                      inputValueHolder(formik, "id", id)
                    );
                    enqueueSnackbar("ID пользователя скопирован", {
                      variant: "success",
                      autoHideDuration: 1000,
                    });
                  }}
                  className="copyIcon"
                  fontSize="small"
                />
              </FormControl>
            </div>
          </div>

          <div className="row">
            <Typography variant="h2" color="dark-gray">
              Контактная информация
            </Typography>
            <div className="items">
              <FormControl className="input-data">
                <div className="validate_input">
                  <div style={{ display: "flex" }}>
                    <ReactFlagsSelect
                      className={
                        formik.touched.phoneNumber &&
                        !isValidPhoneNumber(phoneNumberWithoutCode(), selected)
                          ? "flags-select err"
                          : "flags-select"
                      }
                      selected={selected}
                      selectedSize={20}
                      selectButtonClassName="menu-flags-button"
                      onSelect={(code) => {
                        setSelected(code);
                        formik.setFieldValue("selected", code);
                      }}
                      countries={countryNameRecord}
                      showSelectedLabel={false}
                      showOptionLabel={false}
                      defaultCountry="RU"
                      placeholder="Код"
                    />
                    <div
                      className={
                        formik.touched.phoneNumber &&
                        !isValidPhoneNumber(phoneNumberWithoutCode(), selected)
                          ? "phone_element err"
                          : "phone_element"
                      }
                    >
                      {country_code}
                    </div>
                    <TextField
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      name="phoneNumber"
                      type="text"
                      // label="Контактный телефон"
                      onBlur={formik.handleBlur}
                      className={
                        (formik.errors.phoneNumber &&
                          formik.touched.phoneNumber) ||
                        (formik.touched.phoneNumber &&
                          !isValidPhoneNumber(
                            phoneNumberWithoutCode(),
                            selected
                          ))
                          ? "phone-field err"
                          : "phone-field"
                      }
                      value={
                        inputValueHolder(formik, "phoneNumber", phoneNumber) &&
                        inputValueHolder(formik, "phoneNumber", phoneNumber).startsWith(country_code)
                          ? inputValueHolder(formik, "phoneNumber", phoneNumber).substring(country_code.length)
                          : inputValueHolder(formik, "phoneNumber", phoneNumber)
                      }
                      onChange={(e) => {
                        phoneHandlerChange(formik, e);
                      }}
                      error={formik.errors.phoneNumber}
                      FormHelperTextProps={{ style: positionText }}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </div>
                  {formik.touched.phoneNumber &&
                  !isValidPhoneNumber(phoneNumberWithoutCode(), selected)
                  ? (
                    <div className="error_text">Введите корректный номер</div>
                  ) : null}
                </div>
              </FormControl>
              <FormControl className="input-data">
                <TextField
                  name="email"
                  type="text"
                  label="Основной e-mail"
                  onChange={(e) => emailHandlerChange(formik, e)}
                  value={inputValueHolder(formik, "email", email)}
                  error={formik.errors.email}
                  helperText={formik.errors.email}
                  FormHelperTextProps={{ style: positionText }}
                  InputLabelProps={{ shrink: true }}
                  required
                  disabled={disabled}
                />
                <Edit
                  onClick={() => setIsOpen(!isOpen)}
                  className="copyIcon"
                  fontSize="small"
                />
              </FormControl>
            </div>
          </div>
          <Dialog open={isOpen}>
            <DialogTitle sx={{ width: "500px", fontWeight: 700 }}>
              Изменить основной Email
            </DialogTitle>
            <DialogContent sx={{ height: "180px" }}>
              <Typography variant="h2" color="dark-gray">
                Текущий основной Email
              </Typography>
              <h3 style={{ padding: "10px" }}>{email}</h3>
              <br />
              <Typography variant="h2" color="dark-gray">
                Новый основной Email
              </Typography>
              <TextField
                name="new_email"
                type="text"
                // label="Основной e-mail"
                onChange={(e) => setEmail(e.target.value)}
                value={new_email}
                error={
                  new_email !== "" &&
                  !/^([\w.-]+)@([\w-]+\.)+([\w]{2,})$/.test(new_email)
                }
                placeholder="Введите новый Email"
                FormHelperTextProps={{ style: positionText }}
                InputLabelProps={{ shrink: true }}
                required
              />
            </DialogContent>
            <DialogActions className="button-action">
              <Button style={{ width: "250px" }} onClick={handleChangeEmail}>
                Изменить
              </Button>
              <Button
                style={{ width: "250px" }}
                onClick={() => setIsOpen(!isOpen)}
              >
                Отмена
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={isConfirm}>
            <DialogTitle sx={{ fontWeight: 700 }}>
              На новый основной Email отправлено письмо
            </DialogTitle>
            <DialogContent sx={{ height: "80px" }}>
              <Typography variant="h2" color="dark-gray">
                Для того, чтобы изменить основной Email, перейдите по ссылке в
                полученном письме
              </Typography>
            </DialogContent>
            <DialogActions className="button-action">
              <Button
                style={{ width: "250px" }}
                onClick={() => setIsConfirm(false)}
              >
                Ок
              </Button>
            </DialogActions>
          </Dialog>
          {/* <div className="row">
						<Typography variant="h2" color="dark-gray">
							Об агенстве
						</Typography>
						<div className="items">
							<FormControl className="input-data">
								<TextField 
									name="agency"
									type="text" 
									label="Мое агентство" 
									onChange={(e) => textHandlerChange(formik, e)}
									value={inputValueHolder(formik, 'agency', agency)}
									InputLabelProps={{ shrink: true }}
									required
								/>
							</FormControl>
						</div>
					</div> */}
        </div>

        <div className="actions">
          <Button type="submit" variant="primary">
            Сохранить
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ContactDetails;
